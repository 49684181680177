// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filebutton {
  border-radius: 5px !important;
  min-width: 150px !important;
  max-width: 180px !important;
}

.addicon {
  display: inline-flex;
}
`, "",{"version":3,"sources":["webpack://./src/app/clientreferral/clientreferral.component.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,2BAA2B;EAC3B,2BAA2B;AAC7B;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".filebutton {\n  border-radius: 5px !important;\n  min-width: 150px !important;\n  max-width: 180px !important;\n}\n\n.addicon {\n  display: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
