// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-cell {
  overflow: visible;
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-focus {
  z-index: 1;
}
.ag-root.ag-layout-auto-height,
.ag-body-viewport.ag-layout-auto-height,
.ag-body-viewport-wrapper.ag-layout-auto-height {
  overflow: visible;
}
.ag-row.ag-row-no-focus.ag-row-level-0.ag-row-position-absolute {
  z-index: 0;
}
.ag-root-wrapper {
  overflow: visible;
}
`, "",{"version":3,"sources":["webpack://./src/app/users/users.component.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;AACnB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,UAAU;AACZ;AACA;;;EAGE,iBAAiB;AACnB;AACA;EACE,UAAU;AACZ;AACA;EACE,iBAAiB;AACnB","sourcesContent":[".ag-cell {\n  overflow: visible;\n}\n\n.ag-row {\n  z-index: 0;\n}\n\n.ag-row.ag-row-focus {\n  z-index: 1;\n}\n.ag-root.ag-layout-auto-height,\n.ag-body-viewport.ag-layout-auto-height,\n.ag-body-viewport-wrapper.ag-layout-auto-height {\n  overflow: visible;\n}\n.ag-row.ag-row-no-focus.ag-row-level-0.ag-row-position-absolute {\n  z-index: 0;\n}\n.ag-root-wrapper {\n  overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
