import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    VERSION,
    ViewChild,
} from '@angular/core';
import {
    FormBuilder,
    FormControl,
    FormGroup,
    FormGroupDirective,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import {
    MatDialog,
    MatDialogRef,
    MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, Observer, Subject, take } from 'rxjs';
import { DialogComponent } from 'src/app/common/dialog.component';
import { CustomValidators } from 'src/app/helpers/customvalidators';
import {
    VendorModel,
    CompanyInformationModel,
} from '../../models/assessment.model';
import { AssessmentService } from '../../services/assessment.service';

@Component({
    selector: 'app-assessment-company-info',
    templateUrl: './assessment.companyinfoform.component.html',
    styles: [
        `
            [nz-form] {
                max-width: 600px;
            }

            button {
                margin-left: 8px;
            }
        `,
    ],
})
export class AssessmentCompanyFormComponent implements OnInit {
    @ViewChild(FormGroupDirective, { static: true }) validateForm: FormGroup;
    @Input() parentForm!: FormGroup;
    @Input() title: string = '';
    @Input() model: VendorModel;
    @Input() files: any;
    @Output() assessmentOutput = new EventEmitter<any>();
    mode: string = '';
    formatter = (value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    parser = (value) => value.replace(/\$\s?|(,*)/g, '');

    mask = [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
    ];
    formData = new FormData();
    private modalClose: Subject<any> = new Subject();
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DialogComponent>,
        public dialog: MatDialog,
        private service: AssessmentService
    ) {
        this.service
            .getNextEvent()
            .pipe(take(1))
            .subscribe(() => {
                this.next();
            });
    }
    ngOnInit(): void {
        this.title = this.data.title;
        this.model = this.data.model;
        this.mode = this.data.mode;
        this.validateForm = new FormGroup({
            companyName: new FormControl('', Validators.required),
            contactName: new FormControl('', Validators.required),
            primaryPhone: new FormControl(
                '',
                Validators.compose([
                    Validators.required,
                    CustomValidators.patternValidator(/[- +()0-9]{14,15}/, {
                        hasNumNumber: true,
                    }),
                ])
            ),
            secondaryPhone: new FormControl(''),
            email: new FormControl(
                null,
                Validators.compose([Validators.email, Validators.required])
            ),
            hourlyRate: new FormControl(''),
        });
        this.patchFormValues();
    }

    patchFormValues() {
        this.validateForm
            .get('companyName')
            .setValue(this.model.companyInformationModel.companyName);
        this.validateForm
            .get('contactName')
            .setValue(this.model.companyInformationModel.contactName);
        this.validateForm
            .get('primaryPhone')
            .setValue(this.model.companyInformationModel.primaryPhone);
        this.validateForm
            .get('secondaryPhone')
            .setValue(this.model.companyInformationModel.secondaryPhone);
        this.validateForm
            .get('email')
            .setValue(this.model.companyInformationModel.email);
        this.validateForm
            .get('hourlyRate')
            .setValue(this.model.companyInformationModel.hourlyRate);
        this.validateForm.markAllAsTouched();
    }
    submitForm(): void {
        console.log('submit', this.validateForm.value);
        this.next();
    }

    resetForm(e: MouseEvent): void {
        e.preventDefault();
        this.validateForm.reset();
        for (const key in this.validateForm.controls) {
            if (this.validateForm.controls.hasOwnProperty(key)) {
                this.validateForm.controls[key].markAsPristine();
                this.validateForm.controls[key].updateValueAndValidity();
            }
        }
    }

    validateConfirmPassword(): void {
        setTimeout(() => this.validateForm.updateValueAndValidity(), 1000);
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    userNameAsyncValidator = (control: FormControl) =>
        new Observable((observer: Observer<ValidationErrors | null>) => {
            setTimeout(() => {
                if (control.value === 'JasonWood') {
                    // you have to return `{error: true}` to mark it as an error event
                    observer.next({ error: true, duplicated: true });
                } else {
                    observer.next(null);
                }
                observer.complete();
            }, 1000);
        });

    confirmValidator = (control: FormControl): { [s: string]: boolean } => {
        if (!control.value) {
            return { error: true, required: true };
        } else if (
            control.value !== this.validateForm.controls.password.value
        ) {
            return { confirm: true, error: true };
        }
        return {};
    };

    next() {
        this.model.companyInformationModel = this.validateForm.value;
    }
}
