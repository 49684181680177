import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Guid } from 'guid-typescript';
import { of } from 'rxjs';
import { environment } from 'src/environments/environment';

const AUTH_API = environment.apiUrl;

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLogin = false;

  private roleAs: string;
  private user: string;
  private jwt: string;
  private refresh: string;
  private staffId: string;
  private staffNumber: string;
  constructor(private http: HttpClient) {}

  login(
    role: string,
    user: string,
    jwt: string,
    refreshToken: string,
    staffId: string,
    staffNumber: string
  ) {
    this.isLogin = true;
    this.roleAs = role?.toUpperCase();
    this.user = user;
    this.jwt = jwt;
    this.refresh = refreshToken;
    this.staffId = staffId;
    this.staffNumber = staffNumber;
    sessionStorage.setItem('STATE', 'true');
    sessionStorage.setItem('ROLE', this.roleAs);
    sessionStorage.setItem('USER', this.user);
    sessionStorage.setItem('JWT', this.jwt);
    sessionStorage.setItem('RefreshToken', this.refresh);
    sessionStorage.setItem('StaffId', this.staffId);
    sessionStorage.setItem('StaffNumber', this.staffNumber);
    return of({ success: this.isLogin, role: this.roleAs });
  }

  logout() {
    this.isLogin = false;
    this.roleAs = '';
    sessionStorage.setItem('STATE', 'false');
    sessionStorage.setItem('ROLE', '');
    sessionStorage.setItem('USER', '');
    sessionStorage.setItem('JWT', '');
    sessionStorage.setItem('RefreshToken', '');
    sessionStorage.setItem('StaffId', '');
    sessionStorage.setItem('StaffNumber', '');

    return of({ success: this.isLogin, role: '' });
  }

  isLoggedIn() {
    const loggedIn = sessionStorage.getItem('STATE');
    if (loggedIn == 'true') this.isLogin = true;
    else this.isLogin = false;
    return this.isLogin;
  }

  getRole() {
    this.roleAs = sessionStorage.getItem('ROLE') || '';
    return this.roleAs;
  }

  getUser() {
    this.user = sessionStorage.getItem('USER') || '';
    return this.user;
  }

  getJwt() {
    this.jwt = sessionStorage.getItem('JWT') || '';
    return this.jwt;
  }

  getStaffId() {
    this.jwt = sessionStorage.getItem('StaffId') || '';
    return this.jwt;
  }

  getStaffNumber() {
    this.jwt = sessionStorage.getItem('StaffNumber') || '';
    return this.jwt;
  }
  getRefreshToken() {
    this.refresh = sessionStorage.getItem('RefreshToken') || '';
    return this.refresh;
  }

  setToken(token: string) {
    sessionStorage.setItem('JWT', token);
  }

  setRefreshToken(token: string) {
    sessionStorage.setItem('RefreshToken', token);
  }

  refreshToken() {
    return this.http.post(AUTH_API + '/token/refresh', {}, httpOptions);
  }
}
