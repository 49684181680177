import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    EventEmitter,
    Inject,
    OnInit,
    ViewChild,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router, NavigationStart } from '@angular/router';
import { filter } from 'rxjs';
import { AlertService } from 'src/app/alerts/alert.service';
import { Assessment, ReportsModel } from 'src/app/reportsScreen/models/reports.model';
import { ReportService } from '../../services/report.service';
import { Guid } from 'guid-typescript';
import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import { PDFViewerComponent } from '../report.preview/pdf.viewer.component';
import { Location } from '@angular/common';
import { ReportsTemplateModel } from '../../model/report.template.model';
import { ClientsModel } from 'src/app/client/models/client.model';

interface State {
    report_id: any;
    reportTemplateId: any;
    templateTitle: any;
    mode: any;
    clientModel: any;
}
@Component({
    selector: 'app-report',
    templateUrl: './report.component.html',
    styleUrls: ['./report.component.css'],
})
export class ReportComponent implements OnInit {
    @ViewChild(ComponentPortal, { static: true }) componentPortal: ComponentPortal<any>;
    public title: string = '';
    public reportId: string;
    public reportTemplateId: string = '';
    public mode: string = '';
    public model: ReportsTemplateModel = new ReportsTemplateModel();
    public assessment: Assessment;
    public files: any;
    public reportsModels: ReportsModel[];
    public clientModel: ClientsModel;
    ref: ComponentRef<any>;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private reportService: ReportService,
        private alertService: AlertService,
        private cdr: ChangeDetectorRef,
        private location: Location,
        private router: Router
    ) {
        const state = location.getState() as State;
        this.reportId = state?.report_id ?? null;
        this.reportTemplateId = state?.reportTemplateId ?? '';
        this.title = state?.templateTitle;
        this.model.templateId = this.reportId;
        this.model.templateName = this.title;
        this.mode = state?.mode;
        this.clientModel = state?.clientModel;

        if (this.reportTemplateId.length > 0) {
            this.reportService.getClientReportById(this.reportTemplateId).subscribe((report) => {
                console.log('Report By Id', report);
            });
        }
        if (this.mode == 'Add') {
            this.reportService.addTemplate(this.model).subscribe((report) => {
                console.log('Template By Id', report);
            });
        }
    }

    ngOnInit(): void {
        this.componentPortal = new ComponentPortal(PDFViewerComponent);
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 1000);
    }

    portalAttached(_ref: CdkPortalOutletAttachedRef) {
        this.ref = _ref as ComponentRef<any>;
        this.ref.instance.model = this.model;
        this.ref.instance.clientId = this.clientModel?.clientId;
        this.ref.instance.clientDataModel = this.clientModel || null;
        this.ref.instance.mode = this.mode;
        setTimeout(() => {
            this.cdr.detectChanges();
        }, 500);
    }
}
