import { Guid } from 'guid-typescript';

export class VendorModel {
  applicationNumber?: string = '';
  applicationStatus?: ApplicationStatus;
  dateCreated?: string = '';
  companyInformationModel?: CompanyInformationModel;
  locationInformationModels?: LocationInformationModel[];
  jobInformationModel?: JobInformationModel;
  errorMessage?: ErrorMessage;
  mode?: string;
  isNew: Number = 1;
  viewedBy?: string;
  dateViewed?: string;
}

export class CompanyInformationModel {
  companyInformationId?: Guid;
  companyName?: string = '';
  contactName?: string = '';
  primaryPhone?: string = '';
  secondaryPhone?: string = '';
  email?: string = '';
  hourlyRate?: string = '';
}

export class LocationInformationModel {
  locationInformationId?: Guid;
  address1?: string = '';
  address2?: string = '';
  city?: string = '';
  state?: string = '';
  zip?: string = '';
}

export class JobInformationModel {
  jobInformationId?: Guid;
  jobTitle?: string = '';
  jobDescription?: string = '';
}

export class ErrorMessage {
  message?: string = '';
}

export enum ApplicationStatus {
  Pending,
  Approved,
  Declined,
}

export const ApplicationStatusLabel = new Map<number, string>([
  [ApplicationStatus.Pending, 'Pending'],
  [ApplicationStatus.Approved, 'Approved'],
  [ApplicationStatus.Declined, 'Declined'],
]);
