// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fade.ng-hide {
    opacity: 0;
}

.fade.ng-hide-remove,
.fade.ng-hide-add {
    display: block !important;
}

.fade.ng-hide-remove {
    transition: all linear 1000ms;
}

.fade.ng-hide-add {
    transition: all linear 500ms;
}
`, "",{"version":3,"sources":["webpack://./src/app/reports/components/reports.grid/report.grid.component.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;AAChC","sourcesContent":[".fade.ng-hide {\n    opacity: 0;\n}\n\n.fade.ng-hide-remove,\n.fade.ng-hide-add {\n    display: block !important;\n}\n\n.fade.ng-hide-remove {\n    transition: all linear 1000ms;\n}\n\n.fade.ng-hide-add {\n    transition: all linear 500ms;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
