// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      h3 {
        margin: 0 0 10px;
      }

      pre {
        background-color: #f5f5f5;
        padding: 15px;
      }
    `, "",{"version":3,"sources":["webpack://./src/app/calendar/calendar.component.ts"],"names":[],"mappings":";MACM;QACE,gBAAgB;MAClB;;MAEA;QACE,yBAAyB;QACzB,aAAa;MACf","sourcesContent":["\n      h3 {\n        margin: 0 0 10px;\n      }\n\n      pre {\n        background-color: #f5f5f5;\n        padding: 15px;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
