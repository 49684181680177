import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AccountService } from '../login/service/account.service';
import { AuthService } from '../login/service/auth.service';
import { AlertService } from '../alerts/alert.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private accountService: AccountService,
        private authService: AuthService,
        private alertService: AlertService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.authService.getJwt();
        request = request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + token,
            },
        });

        return next.handle(request).pipe(
            catchError((err) => {
                console.log('ErrorInterceptor', err);
                if (
                    [400, 401, 403].includes(err.status) ||
                    ['Unknown Error'].includes(err.statusText) ||
                    this.authService.getJwt() == ''
                ) {
                    this.alertService.clear();
                    if (err?.status == 401) {
                        this.alertService.info('Your token expired. Please login again');
                        // auto logout if 401 or 403 response returned from api
                        this.accountService.logout();
                    } else {
                        this.alertService.error(err?.error || err?.message);
                    }
                } else {
                    const error = err?.error || err?.message || err?.statusText;
                    this.alertService.error(error);
                    return throwError(() => error);
                }
            })
        );
    }
}
