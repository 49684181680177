// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      [nz-form] {
        max-width: 600px;
      }

      button {
        margin-left: 8px;
      }
      :host
        ::ng-deep
        .ant-collapse
        > .ant-collapse-item
        > .ant-collapse-header {
        color: white !important;
      }
    `, "",{"version":3,"sources":["webpack://./src/app/assessment/modal/modalforms/assessment.reviewsubmitform.component.ts"],"names":[],"mappings":";MACM;QACE,gBAAgB;MAClB;;MAEA;QACE,gBAAgB;MAClB;MACA;;;;;QAKE,uBAAuB;MACzB","sourcesContent":["\n      [nz-form] {\n        max-width: 600px;\n      }\n\n      button {\n        margin-left: 8px;\n      }\n      :host\n        ::ng-deep\n        .ant-collapse\n        > .ant-collapse-item\n        > .ant-collapse-header {\n        color: white !important;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
