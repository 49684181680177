import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AgRendererComponent } from 'ag-grid-angular';

@Component({
    template: `<a
        style="color: blue; text-decoration:underline"
        (click)="navigate(params.inRouterLink, params.data['clientId'])"
        >{{ params.value }}</a
    >`,
})
export class RouterLinkRendererComponent implements AgRendererComponent {
    params: any;

    constructor(private ngZone: NgZone, private router: Router) {}

    agInit(params: any): void {
        this.params = params;
    }

    refresh(params: any): boolean {
        return false;
    }

    // This was needed to make the link work correctly
    navigate(link, queryParams?: string) {
        this.router.navigate([link], { queryParams: { client_id: queryParams } });
    }
}
