import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { GridApi, GridOptions, ColDef } from 'ag-grid-community';
import { ComponentPortal } from 'ngx-toastr';
import { Observable, tap } from 'rxjs';
import { ImageForm } from 'src/app/clientreferral/models/imageform.model';
import { BtnCellArchiveRenderer } from 'src/app/common/buttoncell.archive.renderer';
import { FileAttachmentService } from 'src/app/common/fileattachment.service';
import { AlertService } from 'src/app/alerts/alert.service';
import { ReportModalComponent } from 'src/app/reportsScreen/modal.base/reportmodal.base.component';
import { ReportsModel, CaseTypes } from 'src/app/reportsScreen/models/reports.model';
import { ReportService } from '../../services/report.service';
import { ButtonPropsModel, DialogComponent } from '@syncfusion/ej2-angular-popups';
import { Guid } from 'guid-typescript';
import { ReportsTemplateModel } from '../../model/report.template.model';

@Component({
    selector: 'app-reportgrid',
    templateUrl: './report.grid.component.html',
    styleUrls: ['./report.grid.component.css'],
})
export class ReportGridComponent implements OnInit {
    @Input() clientId: any;
    private gridApi!: GridApi<any>;
    @ViewChild('myGrid') grid!: AgGridAngular;
    public frmReports: FormGroup;
    formData = new FormData();
    loading = false;
    submitted = false;
    startDate = new Date(1990, 0, 1);
    public ReportsData: ReportsModel[];
    public ReportsModels: ReportsModel[];
    public reportTemplates: CaseTypes[];
    public gridColumnApi;
    public gridOptions: GridOptions;
    public columnDefs: ColDef[];
    public defaultColDef: ColDef;
    public autoGroupColumnDef: ColDef;
    public groupDefaultExpanded;
    public rowSelection;
    private reportCount: number = 0;
    public noRows: boolean = false;
    portal: ComponentPortal<any>;
    public queryParams: any;
    reportUrl: boolean = false;
    public Reports: string = 'Report Templates';
    public;
    public overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading...Please wait</span>';
    @ViewChild(DialogComponent) defaultDialog: DialogComponent;
    public dialogCloseIcon: Boolean = true;
    public dialogWidth: string = '35vw';
    public animationSettings: Object = { effect: 'Zoom' };
    public isModal: Boolean = true;
    public target: string = '.control-section';
    public showCloseIcon: Boolean = false;
    public visible: Boolean = false;
    public first: Boolean = false;
    public second: Boolean = false;
    public base: Boolean = false;
    validateForm: FormGroup;
    ReportTemplates: ReportsTemplateModel[];

    constructor(
        public ReportsService: ReportService,
        private attachmentService: FileAttachmentService,
        private fb: FormBuilder,
        private alertService: AlertService,
        private router: Router,
        public dialog: MatDialog,
        public route: ActivatedRoute
    ) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (val.url == '/reporting') {
                    this.reportUrl = true;
                } else {
                    this.reportUrl = false;
                }
            }
        });
        window.addEventListener('resize', this.resizeListener);
    }
    ngOnInit(): void {
        this.loading = true;
        this.route.queryParams.subscribe((params) => {
            this.queryParams = params['client_id'] ?? '';
            if (this.queryParams || this.clientId) {
                this.Reports = 'Reports for client';
            }
        });
        this.ReportsService.getAllTemplates().subscribe((res) => {
            this.ReportTemplates = res['body'];
        });
    }

    ngOnDestroy() {
        this.gridOptions?.api?.destroy();
        this.gridApi?.destroy();
        window.removeEventListener('resize', this.resizeListener);
    }
    resizeListener = () => {
        if (!this.gridApi) return;
        setTimeout(() => {
            if (window.innerWidth <= 480) {
                this.grid.api.setColumnDefs(this.mobileColumns());
                this.grid.api.redrawRows();
                this.grid.api.sizeColumnsToFit();
                this.autoSizeAll(false);
            } else {
                this.grid.api.setColumnDefs(this.fullColumns());
                this.grid.api.redrawRows();
                this.grid.api.sizeColumnsToFit();
                this.autoSizeAll(false);
            }
        }, 200);
    };

    mobileColumns() {
        return [
            {
                headerName: '',
                field: 'action',
                flex: 1,
                width: 30,
                cellRenderer: BtnCellArchiveRenderer,
                cellRendererParams: {
                    clicked: (data: ReportsTemplateModel, mode: string) => {
                        if (mode == 'Edit') {
                            let model = this.ReportTemplates.find((x) => x.templateId == data.templateId);
                            document.getElementById('sidenav_button').click();
                            //document.getElementById('speed-dial-wrapper').className = 'hide';
                            setTimeout(() => {
                                this.router.navigate(['/report'], {
                                    state: {
                                        report_id: null,
                                        reportTemplateId: model.templateId,
                                        templateTitle: model.templateName,
                                        model: model,
                                        mode: 'Update',
                                    },
                                });
                            }, 500);
                        } else {
                            this.DeleteReportTemplate(data);
                        }
                    },
                },
            },
            {
                headerName: 'Template Name',
                field: 'templateName',
                sortable: true,
                flex: 1,
            },
            {
                headerName: 'Template Status',
                field: 'templateStatus',
                sortable: true,
                flex: 1,
            },
            {
                headerName: 'Date Modified',
                field: 'createdDate',
                sortable: true,
                flex: 1,
            },
        ];
    }
    DeleteReportTemplate(data: ReportsTemplateModel) {
        this.ReportsService.deleteTemplateDocumentById(data.templateId).subscribe((ret) => {
            this.alertService.success(`Report ${data.templateName} has been deleted successfully`);
            this.setGridData();
        });
    }
    fullColumns() {
        return [
            {
                headerName: 'Actions',
                field: 'action',
                width: 30,
                flex: 1,
                cellRenderer: BtnCellArchiveRenderer,
                cellRendererParams: {
                    clicked: (data: ReportsTemplateModel, mode: string) => {
                        if (mode == 'Edit') {
                            let model = this.ReportTemplates.find((x) => x.templateId == data.templateId);
                            document.getElementById('sidenav_button').click();
                            //document.getElementById('speed-dial-wrapper').className = 'hide';
                            setTimeout(() => {
                                this.router.navigate(['/report'], {
                                    state: {
                                        report_id: null,
                                        reportTemplateId: model.templateId,
                                        templateTitle: model.templateName,
                                        model: model,
                                        mode: 'Update',
                                    },
                                });
                            }, 500);
                        } else {
                            this.DeleteReportTemplate(data);
                        }
                    },
                },
            },
            {
                headerName: 'Template Name',
                field: 'templateName',
                sortable: true,
                flex: 1,
            },
            {
                headerName: 'Template Status',
                field: 'templateStatus',
                sortable: true,
                flex: 1,
            },
            {
                headerName: 'Date Modified',
                field: 'createdDate',
                sortable: true,
                flex: 1,
            },
        ];
    }

    onSelectionChanged() {
        var selectedRows = this.gridApi.getSelectedRows();
        document.querySelector('#selectedRows').innerHTML =
            selectedRows.length === 1 ? selectedRows[0].clientInfoModel.clientName : '';
    }

    addReportsModal() {
        this.openDialog(new ReportsTemplateModel(), 'Create New Report Template', 'Add');
    }

    openDialog(model: ReportsTemplateModel, title: string, formMode: string): void {
        // let files = [];
        // if (model?.templateHtml) {
        //     files.push(model?.templateHtml);
        // }
        let dialogRef = this.dialog.open(ReportModalComponent, {
            maxWidth: '96vw',
            width: '85vw',
            hasBackdrop: true,
            backdropClass: 'bdrop',
            disableClose: true,
            panelClass: 'overflow-dialog',
            autoFocus: false,
            data: {
                title: title,
                model: model,
                mode: formMode,
                reportsModels: this.ReportTemplates,
                caseTypes: this.reportTemplates,
            },
        });
        dialogRef?.componentInstance?.onModalClose().subscribe((result) => {
            this.loading = true;
            this.router.navigate(['/report'], {
                state: {
                    report_id: result.form['reportId'],
                    reportTemplateId: result.form['reportTemplate'] || null,
                    templateTitle: result.form['templateTitle'] || null,
                    mode: 'Add',
                },
            });
        });
    }

    onUpdateReports(result) {
        /* form code */
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        this.loading = true;
        this.ReportsService.updateReport(result.form)
            .pipe()
            .subscribe((data) => {
                this.ReportsData = data['reportModels'];
                let reportId = data['reportId'];
                this.ReportsModels = data['reportModels'];
                this.ReportsData = data['reportModels'].map((item) => ({
                    clientName: item.clientInfoModel.clientName,
                    clientNumber: item.clientInfoModel.clientNumber,
                    clientDOB: item.clientInfoModel.clientDOB,
                    reportStatus: item.reportStatus,
                    reportNumber: item.reportNumber,
                    dateSubmitted: item.dateSubmitted,
                    modifiedDateSubmitted: item.modifiedDateSubmitted,
                    reportName: item.reportName,
                }));
                if (this.queryParams) {
                    this.ReportsData = this.ReportsData.filter(
                        (x) => x.clientInfoModel.clientId == this.queryParams
                    );
                }
                this.gridApi.setRowData(this.ReportsData.slice(0, 10));
                this.loading = false;
                this.attachmentService
                    .uploadAttachment(result?.files, reportId)
                    .pipe()
                    .subscribe((data) => {
                        console.log(`Has Upload Completed: ${data}`);
                    }),
                    (error) => {
                        this.alertService.error(error);
                    };
            }),
            (error) => {
                this.alertService.error(error);
                this.loading = false;
            };
    }

    onAddReports(result) {
        /* form code */
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();
        this.loading = true;
        this.ReportsService.addReport(result.form)
            .pipe()
            .subscribe((data) => {
                if (data) {
                    let reportId = data['reportId'];
                    this.ReportsModels = data['reportModels'];
                    this.ReportsData = data['reportModels'].map((item) => ({
                        clientName: item.clientInfoModel.clientName,
                        clientNumber: item.clientInfoModel.clientNumber,
                        clientDOB: item.clientInfoModel.clientDOB,
                        reportStatus: item.reportStatus,
                        reportNumber: item.reportNumber,
                        dateSubmitted: item.dateSubmitted,
                        modifiedDateSubmitted: item.modifiedDateSubmitted,
                        reportName: item.reportName,
                    }));
                    if (this.queryParams) {
                        this.ReportsData = this.ReportsData.filter(
                            (x) => x.clientInfoModel.clientId == this.queryParams
                        );
                    }
                    this.gridApi.setRowData(this.ReportsData.slice(0, 10));
                    this.loading = false;
                    this.attachmentService
                        .uploadAttachment(result?.files, reportId)
                        .pipe()
                        .subscribe((data) => {
                            console.log(`Has Upload Completed: ${data}`);
                        }),
                        (error) => {
                            this.alertService.error(error);
                        };
                }
            }),
            (error) => {
                this.alertService.error(error);
                this.loading = false;
            };
    }

    getReportsAttachment(ReportsModel: ReportsModel): Observable<ImageForm> {
        return this.attachmentService.getAttachment(ReportsModel.reportId).pipe(
            tap((data) => {
                return data;
            })
        );
    }

    onFilterTextBoxChanged() {
        this.gridApi.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
    }

    onPrintQuickFilterTexts() {
        this.gridApi.forEachNode(function (rowNode, index) {
            console.log('Row ' + index + ' quick filter text is ' + rowNode.quickFilterAggregateText);
        });
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if (params.api.destroyCalled) {
            return;
        }
        this.gridOptions = <GridOptions>{
            context: { parentComponent: this },
        };
        this.gridApi.setDefaultColDef({
            flex: 1,
            minWidth: 50,
            sortable: true,
            filter: true,
            suppressMovable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
        });
        this.gridApi.setAutoGroupColumnDef({
            minWidth: 50,
        });
        this.groupDefaultExpanded = 1;
        this.rowSelection = 'single';

        if (this.clientId && this.clientId?.length > 10) {
            this.setClientIdGrid(params);
        } else if (this.clientId != 'Add New') {
            this.setNormalGrid(params);
        } else {
            this.gridApi.hideOverlay();
        }
    }

    autoSizeAll(skipHeader: boolean) {
        const allColumnIds: string[] = [];
        this.gridColumnApi?.getColumns()?.forEach((column) => {
            allColumnIds.push(column.getId());
        });

        if (allColumnIds.length > 0) {
            this.gridColumnApi?.autoSizeColumns(allColumnIds, skipHeader);
        }
    }
    sortGrid(event, field, sortDir) {
        const columnState = {
            // https://www.ag-grid.com/javascript-grid-column-state/#column-state-interface
            state: [
                {
                    colId: field,
                    sort: sortDir,
                },
            ],
        };
        event.columnApi.applyColumnState(columnState);
    }

    setGridData() {
        this.ReportsService.getAllTemplates().subscribe((data) => {
            if (data['body']?.length > 0) {
                this.noRows = false;
                this.ReportTemplates = data['body'];
                this.reportCount = this.ReportTemplates.length;
                this.ReportTemplates = data['body'].map((item) => ({
                    templateId: item.templateId,
                    templateName: item.templateName,
                    templateStatus: item.templateStatus,
                    createdDate: item.createdDate,
                    modifiedDate: item.modifiedDate,
                }));
                this.gridApi.setRowData(this.ReportTemplates.slice(0, 10));
                this.gridApi.hideOverlay();
            } else {
                this.gridApi.setRowData([]);
            }
        });
    }

    setClientIdGrid(params: any) {
        this.ReportsService.getAllReportsbyClientId(this.clientId).subscribe((data) => {
            if (params.api.destroyCalled) {
                return;
            }
            if (data?.length > 0) {
                this.noRows = false;

                this.ReportsModels = data;
                this.ReportsData = data.map((item) => ({
                    clientName:
                        item.clientInfoModel.clientFirstName + ' ' + item.clientInfoModel.clientLastName,
                    clientNumber: item.clientInfoModel.clientNumber,
                    clientDOB: item.clientInfoModel.clientDOB,
                    reportStatus: item.reportStatus,
                    reportNumber: item.reportNumber,
                    dateSubmitted: item.dateSubmitted,
                    modifiedDateSubmitted: item.modifiedDateSubmitted,
                    reportName: item.reportName,
                    clientId: item.clientInfoModel.clientId,
                }));

                this.Reports = 'Reports for ' + this.ReportsData[0]['clientName'];
                this.gridApi.setRowData(this.ReportsData.slice(0, 10));
                params.api!.setRowData(this.ReportsData.slice(0, 10));
                if (window.innerWidth <= 480) {
                    this.gridApi.setColumnDefs(this.mobileColumns());
                    params.columnApi.applyColumnState({
                        state: [
                            { colId: 'action', pinned: 'left' },
                            {
                                colId: 'modifiedDateSubmitted',
                                sort: 'desc',
                                sortIndex: 0,
                            },
                            {
                                colId: 'dateSubmitted',
                                sort: 'desc',
                                sortIndex: 1,
                            },
                        ],
                    });
                    if (this.gridApi) {
                        this.gridApi.redrawRows();
                        this.gridApi.sizeColumnsToFit();
                    }
                } else {
                    this.gridApi.setColumnDefs(this.fullColumns());
                    params.columnApi.applyColumnState({
                        state: [
                            { colId: 'action', pinned: 'left' },
                            {
                                colId: 'modifiedDateSubmitted',
                                sort: 'desc',
                                sortIndex: 0,
                            },
                            {
                                colId: 'dateSubmitted',
                                sort: 'desc',
                                sortIndex: 1,
                            },
                        ],
                    });
                    if (this.gridApi) {
                        this.gridApi.redrawRows();
                        this.gridApi.sizeColumnsToFit();
                        this.loading = false;
                    }
                }
                this.gridApi.hideOverlay();
            } else {
                this.gridApi.setRowData([]);
            }
        });
    }

    setNormalGrid(params: any) {
        this.ReportsService.getAllTemplates().subscribe((data) => {
            if (params.api.destroyCalled) {
                return;
            }
            if (data['body']?.length > 0) {
                this.noRows = false;
                this.ReportTemplates = data['body'];
                this.reportCount = this.ReportTemplates.length;
                this.ReportTemplates = data['body'].map((item) => ({
                    templateId: item.templateId,
                    templateName: item.templateName,
                    templateStatus: item.templateStatus,
                    createdDate: item.createdDate,
                    modifiedDate: item.modifiedDate,
                }));
                this.gridApi.setRowData(this.ReportTemplates.slice(0, 10));
                params.api!.setRowData(this.ReportTemplates.slice(0, 10));
                if (window.innerWidth <= 480) {
                    this.gridApi.setColumnDefs(this.mobileColumns());
                    params.columnApi.applyColumnState({
                        state: [
                            { colId: 'action', pinned: 'left' },
                            {
                                colId: 'modifiedDateSubmitted',
                                sort: 'desc',
                                sortIndex: 0,
                            },
                            {
                                colId: 'dateSubmitted',
                                sort: 'desc',
                                sortIndex: 1,
                            },
                        ],
                    });
                    if (this.gridApi) {
                        this.gridApi.redrawRows();
                        this.gridApi.sizeColumnsToFit();
                    }
                } else {
                    this.gridApi.setColumnDefs(this.fullColumns());
                    params.columnApi.applyColumnState({
                        state: [
                            { colId: 'action', pinned: 'left' },
                            {
                                colId: 'modifiedDateSubmitted',
                                sort: 'desc',
                                sortIndex: 0,
                            },
                            {
                                colId: 'dateSubmitted',
                                sort: 'desc',
                                sortIndex: 1,
                            },
                        ],
                    });
                    if (this.gridApi) {
                        this.gridApi.redrawRows();
                        this.gridApi.sizeColumnsToFit();
                        this.loading = false;
                    }
                }
                this.gridApi.hideOverlay();
            } else {
                this.gridApi.setRowData([]);
            }
        });
    }
}
