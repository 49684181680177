import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Route, Router } from '@angular/router';

import { ColDef, GridApi, GridOptions } from 'ag-grid-community';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable, tap } from 'rxjs';
import { AlertService } from '../alerts/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import { Assessment, CaseTypes, ClientInformationModel, ReportsModel } from './models/reports.model';
import { ReportModalComponent } from './modal.base/reportmodal.base.component';
import { ImageForm } from '../clientreferral/models/imageform.model';
import { AgGridAngular } from 'ag-grid-angular';
import { BtnCellArchiveRenderer } from '../common/buttoncell.archive.renderer';
import { FileAttachmentService } from '../common/fileattachment.service';
import { ReportService } from '../reports/services/report.service';

@Component({
    selector: 'app-reports',
    templateUrl: './reports.component.html',
    styleUrls: ['./reports.component.css'],
})
export class ReportsComponent implements OnInit {
    @Input() clientId: any;
    private gridApi!: GridApi<any>;
    @ViewChild('myGrid') grid!: AgGridAngular;
    public frmReports: FormGroup;
    formData = new FormData();
    loading = false;
    submitted = false;
    startDate = new Date(1990, 0, 1);
    public ReportsData: ReportsModel[];
    public ReportsModels: ReportsModel[];
    public CaseTypes: CaseTypes[];
    public gridColumnApi;
    public gridOptions: GridOptions;
    public columnDefs: ColDef[];
    public defaultColDef: ColDef;
    public autoGroupColumnDef: ColDef;
    public groupDefaultExpanded;
    public rowSelection;
    public noRows: boolean = false;
    portal: ComponentPortal<any>;
    public queryParams: any;
    reportUrl: boolean = false;
    public Reports: string = 'Reports';
    public overlayLoadingTemplate = '<span class="ag-overlay-loading-center">Loading...Please wait</span>';
    constructor(
        public ReportsService: ReportService,
        private attachmentService: FileAttachmentService,
        private fb: FormBuilder,
        private alertService: AlertService,
        private router: Router,
        public dialog: MatDialog,
        public route: ActivatedRoute
    ) {
        router.events.subscribe((val) => {
            if (val instanceof NavigationEnd) {
                if (val.url == '/reports') {
                    this.reportUrl = true;
                } else {
                    this.reportUrl = false;
                }
            }
        });
        window.addEventListener('resize', this.resizeListener);
    }
    ngOnInit(): void {
        this.loading = true;
        this.route.queryParams.subscribe((params) => {
            this.queryParams = params['client_id'] ?? '';
            if (this.queryParams || this.clientId) {
                this.Reports = 'Reports for client';
            }
        });
        this.ReportsService.getCaseTypes().subscribe((types) => {
            this.CaseTypes = types['body'];
        });
    }

    ngOnDestroy() {
        this.gridOptions?.api?.destroy();
        this.gridApi?.destroy();
        window.removeEventListener('resize', this.resizeListener);
    }
    resizeListener = () => {
        if (!this.gridApi) return;
        setTimeout(() => {
            if (window.innerWidth <= 480) {
                this.grid.api.setColumnDefs(this.mobileColumns());
                this.grid.api.redrawRows();
                this.grid.api.sizeColumnsToFit();
                this.autoSizeAll(false);
            } else {
                this.grid.api.setColumnDefs(this.fullColumns());
                this.grid.api.redrawRows();
                this.grid.api.sizeColumnsToFit();
                this.autoSizeAll(false);
            }
        }, 200);
    };

    mobileColumns() {
        return [
            {
                headerName: '',
                field: 'action',
                flex: 1,
                cellRenderer: BtnCellArchiveRenderer,
                cellRendererParams: {
                    clicked: (data: ReportsModel, mode: string) => {
                        if (mode == 'Edit') {
                            let model = this.ReportsModels.find((x) => x.reportNumber == data.reportNumber);
                            this.attachmentService.getAttachment(model.reportId).subscribe((ret) => {
                                if (ret.fileName != '') {
                                    data.clientInfoModel.profileImage = ret;
                                }
                                this.openDialog(data, 'Update ', 'Update');
                            });
                        } else {
                            this.onArchiveReport(data);
                        }
                    },
                },
            },
            {
                headerName: 'Report Name',
                field: 'reportName',
                sortable: true,
                flex: 1,
            },
            {
                headerName: 'Report Status',
                field: 'reportStatus',
                sortable: true,
                flex: 1,
            },
            {
                headerName: 'Date Modified',
                field: 'dateSubmitted',
                sortable: true,
                flex: 1,
            },
        ];
    }
    onArchiveReport(data: ReportsModel) {
        this.alertService.success('Archival ability coming soon.');
    }
    fullColumns() {
        return [
            {
                headerName: 'Action',
                field: 'action',
                flex: 1,
                cellRenderer: BtnCellArchiveRenderer,
                cellRendererParams: {
                    clicked: (data: ReportsModel, mode: string) => {
                        if (mode == 'Edit') {
                            let model = this.ReportsModels.find((x) => x.reportNumber == data.reportNumber);
                            this.attachmentService.getAttachment(model.reportId).subscribe((ret) => {
                                if (ret.fileName) {
                                    model.clientInfoModel.profileImage = ret;
                                }
                                this.openDialog(model, 'Update Situational Assessment Report', 'Update');
                            });
                        } else {
                            this.onArchiveReport(data);
                        }
                    },
                },
            },
            {
                headerName: 'Report Name',
                field: 'reportName',
                sortable: true,
                flex: 1,
            },
            {
                headerName: 'Report Status',
                field: 'reportStatus',
                sortable: true,
                flex: 1,
            },
            {
                headerName: 'Date Modified',
                field: 'dateSubmitted',
                sortable: true,
                flex: 1,
            },
        ];
    }

    onSelectionChanged() {
        var selectedRows = this.gridApi.getSelectedRows();
        document.querySelector('#selectedRows').innerHTML =
            selectedRows.length === 1 ? selectedRows[0].clientInfoModel.clientName : '';
    }

    addReportsModal() {
        this.router.navigate(['/report']);
        // let model = new ReportsModel();
        // model.clientInfoModel = new ClientInformationModel();
        // model.clientInfoModel.assessment = new Assessment();
        // this.openDialog(model, 'Create Situational Assessment Report', 'Add');
    }

    openDialog(model: ReportsModel, title: string, formMode: string): void {
        let files = [];
        if (model?.clientInfoModel?.profileImage) {
            files.push(model?.clientInfoModel.profileImage);
        }
        this.frmReports = this.createReportsForm();
        let dialogRef = this.dialog.open(ReportModalComponent, {
            maxWidth: '96vw',
            width: '85vw',
            hasBackdrop: true,
            backdropClass: 'bdrop',
            disableClose: true,
            panelClass: 'overflow-dialog',
            autoFocus: false,
            data: {
                title: title,
                model: model,
                files: files,
                mode: formMode,
                form: this.frmReports,
                caseTypes: this.CaseTypes,
            },
        });
        dialogRef?.componentInstance?.onModalClose().subscribe((result) => {
            this.loading = true;
            this.ReportsService.getAllReports().subscribe((data) => {
                this.ReportsModels = data['body'];
                this.ReportsData = data['body'].map((item) => ({
                    clientName: item.clientInfoModel.clientFirstName,
                    clientNumber: item.clientInfoModel.clientNumber,
                    clientDOB: item.clientInfoModel.clientDOB,
                    reportStatus: item.reportStatus,
                    reportNumber: item.reportNumber,
                    dateSubmitted: item.dateSubmitted,
                    modifiedDateSubmitted: item.modifiedDateSubmitted,
                    reportName: item.reportName,
                }));
                if (this.queryParams) {
                    this.ReportsData = this.ReportsData.filter(
                        (x) => x.clientInfoModel.clientId == this.queryParams
                    );
                }
                this.gridApi.setRowData(this.ReportsData.slice(0, 10));
                this.autoSizeAll(false);
                this.loading = false;
            }),
                (error) => {
                    this.alertService.error(error);
                    this.loading = false;
                };
        });
    }

    createReportsForm(): FormGroup<any> {
        return this.fb.group({
            clientName: ['', Validators.required],
            clientNumber: ['', Validators.required],
            clientDOB: ['', Validators.required],
            caseType: ['', Validators.required],
            dateSubmitted: [''],
            reportStatus: [''],
            referralNotes: [''],
        });
    }

    onUpdateReports(result) {
        /* form code */
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        this.loading = true;
        this.ReportsService.updateReport(result.form)
            .pipe()
            .subscribe((data) => {
                this.ReportsData = data['reportModels'];
                let reportId = data['reportId'];
                this.ReportsModels = data['reportModels'];
                this.ReportsData = data['reportModels'].map((item) => ({
                    clientName: item.clientInfoModel.clientName,
                    clientNumber: item.clientInfoModel.clientNumber,
                    clientDOB: item.clientInfoModel.clientDOB,
                    reportStatus: item.reportStatus,
                    reportNumber: item.reportNumber,
                    dateSubmitted: item.dateSubmitted,
                    modifiedDateSubmitted: item.modifiedDateSubmitted,
                    reportName: item.reportName,
                }));
                if (this.queryParams) {
                    this.ReportsData = this.ReportsData.filter(
                        (x) => x.clientInfoModel.clientId == this.queryParams
                    );
                }
                this.gridApi.setRowData(this.ReportsData.slice(0, 10));
                this.loading = false;
                this.attachmentService
                    .uploadAttachment(result?.files, reportId)
                    .pipe()
                    .subscribe((data) => {
                        console.log(`Has Upload Completed: ${data}`);
                    }),
                    (error) => {
                        this.alertService.error(error);
                    };
            }),
            (error) => {
                this.alertService.error(error);
                this.loading = false;
            };
    }

    onAddReports(result) {
        /* form code */
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();
        this.loading = true;
        this.ReportsService.addReport(result.form)
            .pipe()
            .subscribe((data) => {
                if (data) {
                    let reportId = data['reportId'];
                    this.ReportsModels = data['reportModels'];
                    this.ReportsData = data['reportModels'].map((item) => ({
                        clientName: item.clientInfoModel.clientName,
                        clientNumber: item.clientInfoModel.clientNumber,
                        clientDOB: item.clientInfoModel.clientDOB,
                        reportStatus: item.reportStatus,
                        reportNumber: item.reportNumber,
                        dateSubmitted: item.dateSubmitted,
                        modifiedDateSubmitted: item.modifiedDateSubmitted,
                        reportName: item.reportName,
                    }));
                    if (this.queryParams) {
                        this.ReportsData = this.ReportsData.filter(
                            (x) => x.clientInfoModel.clientId == this.queryParams
                        );
                    }
                    this.gridApi.setRowData(this.ReportsData.slice(0, 10));
                    this.loading = false;
                    this.attachmentService
                        .uploadAttachment(result?.files, reportId)
                        .pipe()
                        .subscribe((data) => {
                            console.log(`Has Upload Completed: ${data}`);
                        }),
                        (error) => {
                            this.alertService.error(error);
                        };
                }
            }),
            (error) => {
                this.alertService.error(error);
                this.loading = false;
            };
    }

    getReportsAttachment(ReportsModel: ReportsModel): Observable<ImageForm> {
        return this.attachmentService.getAttachment(ReportsModel.reportId).pipe(
            tap((data) => {
                return data;
            })
        );
    }

    onFilterTextBoxChanged() {
        this.gridApi.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
    }

    onPrintQuickFilterTexts() {
        this.gridApi.forEachNode(function (rowNode, index) {
            console.log('Row ' + index + ' quick filter text is ' + rowNode.quickFilterAggregateText);
        });
    }

    onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        if (params.api.destroyCalled) {
            return;
        }
        this.gridOptions = <GridOptions>{
            context: { parentComponent: this },
        };
        this.gridApi.setDefaultColDef({
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
            suppressMovable: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            wrapText: true,
        });
        this.gridApi.setAutoGroupColumnDef({
            minWidth: 200,
        });
        this.groupDefaultExpanded = 1;
        this.rowSelection = 'single';

        if (this.clientId && this.clientId?.length > 10) {
            this.setClientIdGrid(params);
        } else if (this.clientId != 'Add New') {
            this.setNormalGrid(params);
        } else {
            this.gridApi.hideOverlay();
        }
    }

    autoSizeAll(skipHeader: boolean) {
        const allColumnIds: string[] = [];
        this.gridColumnApi?.getColumns()?.forEach((column) => {
            allColumnIds.push(column.getId());
        });

        if (allColumnIds.length > 0) {
            this.gridColumnApi?.autoSizeColumns(allColumnIds, skipHeader);
        }
    }
    sortGrid(event, field, sortDir) {
        const columnState = {
            // https://www.ag-grid.com/javascript-grid-column-state/#column-state-interface
            state: [
                {
                    colId: field,
                    sort: sortDir,
                },
            ],
        };
        event.columnApi.applyColumnState(columnState);
    }

    setClientIdGrid(params: any) {
        this.ReportsService.getAllReportsbyClientId(this.clientId).subscribe((data) => {
            if (params.api.destroyCalled) {
                return;
            }
            if (data?.length > 0) {
                this.noRows = false;

                this.ReportsModels = data;
                this.ReportsData = data.map((item) => ({
                    clientName:
                        item.clientInfoModel.clientFirstName + ' ' + item.clientInfoModel.clientLastName,
                    clientNumber: item.clientInfoModel.clientNumber,
                    clientDOB: item.clientInfoModel.clientDOB,
                    reportStatus: item.reportStatus,
                    reportNumber: item.reportNumber,
                    dateSubmitted: item.dateSubmitted,
                    modifiedDateSubmitted: item.modifiedDateSubmitted,
                    reportName: item.reportName,
                    clientId: item.clientInfoModel.clientId,
                }));

                this.Reports = 'Reports for ' + this.ReportsData[0]['clientName'];
                this.gridApi.setRowData(this.ReportsData.slice(0, 10));
                params.api!.setRowData(this.ReportsData.slice(0, 10));
                if (window.innerWidth <= 480) {
                    this.gridApi.setColumnDefs(this.mobileColumns());
                    params.columnApi.applyColumnState({
                        state: [
                            { colId: 'action', pinned: 'left' },
                            {
                                colId: 'modifiedDateSubmitted',
                                sort: 'desc',
                                sortIndex: 0,
                            },
                            {
                                colId: 'dateSubmitted',
                                sort: 'desc',
                                sortIndex: 1,
                            },
                        ],
                    });
                    if (this.gridApi) {
                        this.gridApi.redrawRows();
                        this.gridApi.sizeColumnsToFit();
                    }
                } else {
                    this.gridApi.setColumnDefs(this.fullColumns());
                    params.columnApi.applyColumnState({
                        state: [
                            { colId: 'action', pinned: 'left' },
                            {
                                colId: 'modifiedDateSubmitted',
                                sort: 'desc',
                                sortIndex: 0,
                            },
                            {
                                colId: 'dateSubmitted',
                                sort: 'desc',
                                sortIndex: 1,
                            },
                        ],
                    });
                    if (this.gridApi) {
                        this.gridApi.redrawRows();
                        this.gridApi.sizeColumnsToFit();
                        this.loading = false;
                    }
                }
                this.gridApi.hideOverlay();
            } else {
                this.gridApi.setRowData([]);
            }
        });
    }

    setNormalGrid(params: any) {
        this.ReportsService.getAllReports().subscribe((data) => {
            if (params.api.destroyCalled) {
                return;
            }
            if (data['body']?.length > 0) {
                this.noRows = false;
                this.ReportsModels = data['body'];
                this.ReportsData = data['body'].map((item) => ({
                    clientName: item.clientInfoModel.clientFirstName,
                    clientNumber: item.clientInfoModel.clientNumber,
                    clientDOB: item.clientInfoModel.clientDOB,
                    reportStatus: item.reportStatus,
                    reportNumber: item.reportNumber,
                    dateSubmitted: item.dateSubmitted,
                    modifiedDateSubmitted: item.modifiedDateSubmitted,
                    reportName: item.reportName,
                }));
                this.gridApi.setRowData(this.ReportsData.slice(0, 10));
                params.api!.setRowData(this.ReportsData.slice(0, 10));
                if (window.innerWidth <= 480) {
                    this.gridApi.setColumnDefs(this.mobileColumns());
                    params.columnApi.applyColumnState({
                        state: [
                            { colId: 'action', pinned: 'left' },
                            {
                                colId: 'modifiedDateSubmitted',
                                sort: 'desc',
                                sortIndex: 0,
                            },
                            {
                                colId: 'dateSubmitted',
                                sort: 'desc',
                                sortIndex: 1,
                            },
                        ],
                    });
                    if (this.gridApi) {
                        this.gridApi.redrawRows();
                        this.gridApi.sizeColumnsToFit();
                    }
                } else {
                    this.gridApi.setColumnDefs(this.fullColumns());
                    params.columnApi.applyColumnState({
                        state: [
                            { colId: 'action', pinned: 'left' },
                            {
                                colId: 'modifiedDateSubmitted',
                                sort: 'desc',
                                sortIndex: 0,
                            },
                            {
                                colId: 'dateSubmitted',
                                sort: 'desc',
                                sortIndex: 1,
                            },
                        ],
                    });
                    if (this.gridApi) {
                        this.gridApi.redrawRows();
                        this.gridApi.sizeColumnsToFit();
                        this.loading = false;
                    }
                }
                this.gridApi.hideOverlay();
            } else {
                this.gridApi.setRowData([]);
            }
        });
    }
}
