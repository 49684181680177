import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthenticatedResponse } from '../models/authenticatedresponse';
import { AccountService } from './account.service';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { EventData } from 'src/app/common/event';
import { EventBusService } from 'src/app/common/event.bus.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  private env: any;
  constructor(
    private router: Router,
    private accountService: AccountService,
    private http: HttpClient,
    private authService: AuthService,
    private jwtHelper: JwtHelperService
  ) {
    this.env = environment;
  }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = this.authService.getJwt();
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      console.log(this.jwtHelper.decodeToken(token));
      return true;
    }
    const isRefreshSuccess = await this.tryRefreshingTokens(token);
    if (!isRefreshSuccess) {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/account/login'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }
    return isRefreshSuccess;
  }

  private async tryRefreshingTokens(token: string): Promise<boolean> {
    const refreshToken: string = this.authService.getRefreshToken();
    if (!token || !refreshToken) {
      return false;
    }

    const credentials = JSON.stringify({
      accessToken: token,
      refreshToken: refreshToken,
    });
    let isRefreshSuccess: boolean;
    let newEnv = this.env;
    const refreshRes = await new Promise<AuthenticatedResponse>(
      (resolve, reject) => {
        this.http
          .post<AuthenticatedResponse>(
            newEnv['apiUrl'] + '/token/refresh',
            credentials,
            {
              headers: new HttpHeaders({
                'Content-Type': 'application/json',
              }),
            }
          )
          .subscribe({
            next: (res: AuthenticatedResponse) => resolve(res),
            error: (err) => {
              if (
                err.includes('400') ||
                err.includes('401') ||
                err.includes('403')
              ) {
                this.accountService.logout();
              }
              reject;
              isRefreshSuccess = false;
            },
          });
      }
    );

    this.authService.setToken(refreshRes.token);
    this.authService.setRefreshToken(refreshRes.refreshToken);
    isRefreshSuccess = true;
    return isRefreshSuccess;
  }
}
