import { Component } from '@angular/core';
import { UserModel } from './models/user.model';
import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from './services/user.service';
import { AlertService } from '../alerts/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { BtnCellRenderer } from '../common/buttoncell.renderer';
import { UserComponent } from './modal/user.component';
import { GridOptions } from 'ag-grid-community';
@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.css'],
})
export class UsersComponent {
    private gridApi!: GridApi<UserModel>;
    public frmClientreferral: FormGroup;
    formData = new FormData();
    loading = false;
    submitted = false;
    startDate = new Date(1990, 0, 1);
    public gridColumnApi;
    public columnDefs: ColDef[];
    public defaultColDef: ColDef;
    public autoGroupColumnDef: ColDef;
    public groupDefaultExpanded;
    public rowSelection;
    public user: any;
    constructor(
        public userService: UserService,
        private fb: FormBuilder,
        private alertService: AlertService,
        public dialog: MatDialog
    ) {
        this.columnDefs = [
            {
                headerName: 'Action',
                field: 'action',
                width: 150,
                cellRenderer: BtnCellRenderer,
                cellRendererParams: {
                    onClick: this.onUserChange.bind(this),
                },
            },
            { field: 'firstName', sortable: true },
            { field: 'vendorName', sortable: true },
            { field: 'lastName', sortable: true },
            { field: 'email', sortable: true },
            { field: 'role', sortable: true },
            {
                field: 'verifiedAt',
                cellRenderer: (data) => {
                    return data ? new Date(data.value).toLocaleDateString() : '';
                },
                sortable: true,
            },
        ];
        this.defaultColDef = {
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
        };
        this.autoGroupColumnDef = {
            minWidth: 200,
        };
        this.groupDefaultExpanded = 1;
        this.rowSelection = 'single';
    }

    ngOnInit(): void {
        this.loading = true;
    }

    onUserChange(params) {
        this.loading = true;

        this.userService.getUser().subscribe((data) => {
            this.user = data['body'];
            this.gridApi.setRowData(this.user);
            this.loading = false;
        });
    }
    addAssessmentModal() {
        let model = new UserModel();
        this.openDialog(model, 'New User', 'Add');
    }

    openDialog(model: UserModel, title: string, mode: string): void {
        // this.files = [];
        let dialogRef = this.dialog.open(UserComponent, {
            width: '640px',
            disableClose: true,
            panelClass: 'overflow-dialog',
            data: { title: title, model: model, mode: mode },
        });
        dialogRef?.componentInstance?.onModalClose().subscribe((result) => {
            if (result.mode == 'Add') {
                this.onAddUser(result);
            }
        });
    }

    onAddUser(result) {
        /* form code */
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        this.loading = true;
        this.userService
            .addNewUser(result.form)
            .pipe()
            .subscribe((data) => {
                this.userService.getUser().subscribe((data) => {
                    this.user = data['body'];
                    this.gridApi.setRowData(this.user);
                    this.loading = false;
                });
            }),
            (error) => {
                this.alertService.error(error);
                this.loading = false;
            };
    }

    onFilterTextBoxChanged() {
        this.gridApi.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
    }

    onPrintQuickFilterTexts() {
        this.gridApi.forEachNode(function (rowNode, index) {
            console.log('Row ' + index + ' quick filter text is ' + rowNode.quickFilterAggregateText);
        });
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.userService.getUser().subscribe((data) => {
            this.user = data['body'];
            console.log(this.user);
            const sortModel = [{ colId: 'verifiedAt', sort: 'desc' }];
            this.sortGrid(params, 'verifiedAt', 'desc');
            this.gridApi.setRowData(this.user.slice(0, 10));
            params.api!.setRowData(this.user.slice(0, 10));
            this.loading = false;
        });
    }
    // getApplicationStatus(applicationStatus: ApplicationStatus): any {
    //   let retval = ''
    //   ApplicationStatusLabel.forEach((label, value) => {

    //     if(value == applicationStatus){
    //       retval = label;
    //     }
    //   })
    //   return retval;
    // }

    sortGrid(event, field, sortDir) {
        const columnState = {
            // https://www.ag-grid.com/javascript-grid-column-state/#column-state-interface
            state: [
                {
                    colId: field,
                    sort: sortDir,
                },
            ],
        };
        event.columnApi.applyColumnState(columnState);
    }

    gridOptions: GridOptions = <GridOptions>{
        rowHeight: 100,
        floatingFiltersHeight: 100,
        suppressRowTransform: true,
    };
}
