import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MessageService } from 'src/app/common/message.service';
import { environment } from 'src/environments/environment';
import { UserModel } from '../models/user.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private subject = new Subject<any>();
  private changeIndexSubject = new Subject<any>();
  http: HttpClient;
  clientUrl: string = environment.apiUrl + '/Auth';

  constructor(httpClient: HttpClient, private messageService: MessageService) {
    this.http = httpClient;
  }

  goToPage(index: string) {
    this.changeIndexSubject.next(index);
  }

  getIndexSubjectEvent(): Observable<any> {
    return this.changeIndexSubject.asObservable();
  }
  sendNextEvent(event: any) {
    this.subject.next(event);
  }

  getNextEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  public getUser(): Observable<HttpResponse<UserModel[]>> {
    return this.http.get<UserModel[]>(this.clientUrl + '/get-all-users', {
      observe: 'response',
    });
  }

  addNewUser(UserModel: UserModel): Observable<UserModel[]> {
    return this.http.post<UserModel[]>(
      this.clientUrl + '/register',
      UserModel,
      httpOptions
    );
  }
  editUser(UserModel: UserModel): Observable<UserModel[]> {
    return this.http.post<UserModel[]>(
      this.clientUrl + '/update-user',
      UserModel,
      httpOptions
    );
  }
  deleteUser(UserModel: UserModel): Observable<UserModel[]> {
    return this.http.post<UserModel[]>(
      this.clientUrl + '/delete-user',
      UserModel,
      httpOptions
    );
  }
  updateAssessment(UserModel: UserModel): Observable<UserModel[]> {
    return this.http.post<UserModel[]>(this.clientUrl, UserModel, httpOptions);
  }
}
