import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { cardData } from '../model/data';
import { Observable, Subject, of } from 'rxjs';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class TaskService {
    getTasksByStaffId(staffId: string): Observable<any> {
        return of(cardData);
    }
}
