import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StaffModel } from '../../model/staff.model';
import { ClientsModel } from '../../../client/models/client.model';
import { ImageForm } from '../../../clientreferral/models/imageform.model';
import { AlertService } from '../../../alerts/alert.service';
import { StaffService } from '../../services/staff.service';
import {
    GridComponent,
    PageSettingsModel,
    SearchSettingsModel,
    ToolbarItems,
} from '@syncfusion/ej2-angular-grids';
import { ClientService } from 'src/app/client/services/client.services';
import { ClickEventArgs } from '@syncfusion/ej2-navigations';
import { UtilityService } from 'src/app/common/utility';

@Component({
    selector: 'app-staff-admin-grid',
    templateUrl: './staff.admin.grid.component.html',
    styleUrls: ['./staff.admin.grid.component.css'],
})
export class StaffGridComponent implements OnInit, AfterViewInit {
    @ViewChild('childtemplate', { static: true }) public childtemplate?: any;
    @ViewChild('activeTemplate', { static: false }) public activeTemplate?: any;
    @ViewChild('grid', { static: false })
    public grid: GridComponent;
    loading = false;
    submitted = false;
    startDate = new Date(1990, 0, 1);
    public staffGridData: StaffModel[];
    public mappedStaffGridData: any[];
    public staffGridModel: StaffModel;
    public childGrid: any;
    public pageSettings: PageSettingsModel;
    public toolbarOptions?: ToolbarItems[];
    public loadingIndicator?: any;
    staffClientData: any = [];
    public toolbar: string[];
    public sortSettings;
    public searchOptions?: SearchSettingsModel;
    sb: any;
    strings: any;

    constructor(
        @Inject(ViewContainerRef) private viewContainerRef: ViewContainerRef,
        public staffService: StaffService,
        private fb: FormBuilder,
        private alertService: AlertService,
        public dialog: MatDialog,
        public router: Router,
        public clientService: ClientService,
        private utility: UtilityService
    ) {
        this.loadingIndicator = { indicatorType: 'Shimmer' };
        this.toolbarOptions = ['Search'];
        this.pageSettings = { pageSizes: true, pageSize: 10 };
        this.sortSettings = { columns: [{ field: 'createdDate', direction: 'descending' }] };
        this.toolbar = ['PdfExport', 'ExcelExport'];
    }

    async ngOnInit(): Promise<void> {
        // this.loading = true;
        this.setChildGrid();
        this.staffService.getAllStaff().subscribe(async (data) => {
            this.staffGridData = data['body']!;
            this.mappedStaffGridData = data['body']!.map((item) => ({
                staffId: item?.staffId,
                numberOfClients: this.getClientCount(item),
                staffNumber: item?.staffNumber,
                role: item?.role,
                staffName: item?.vendorName || `${item?.firstName} ${item?.lastName}`,
                isActive: this.utility.getBoolean(item?.isActive) == false ? 'Inactive' : 'Active',
                createdDate:
                    item?.modifiedDate != null ? new Date(item?.modifiedDate) : new Date(item?.createdDate),
                clients: item?.clients,
            }));
            await this.getChildGrid();
        });
    }

    ngAfterViewInit() {
        this.childtemplate.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
        this.childtemplate.elementRef.nativeElement.propName = 'template';
        this.activeTemplate.elementRef.nativeElement._viewContainerRef = this.viewContainerRef;
        this.activeTemplate.elementRef.nativeElement.propName = 'template';
    }

    public onLoad() {
        // event delegation concept
        // debouncing concept
        this.grid.element.addEventListener(
            'keyup',
            this.debounce((e) => {
                if (e.target.getAttribute('id').indexOf('_searchbar') !== -1) {
                    console.log((e.target as HTMLInputElement).value);
                    this.grid.search((e.target as HTMLInputElement).value);
                }
            }, 350)
        );
    }
    // debouncing method
    public debounce = (func, delay) => {
        let debounceTimer;
        return function () {
            const context = this;
            const args = arguments;
            clearTimeout(debounceTimer);
            debounceTimer = setTimeout(() => func.apply(context, args), delay);
        };
    };

    public async getChildGrid() {
        this.clientService.getAllClients().subscribe((data) => {
            this.staffClientData = data['body']!.map((item) => ({
                staffId: item?.staffId,
                clientId: item?.clientId,
                clientName: `${item?.clientLastName}, ${item?.clientFirstName}`,
                reportStatus: item?.reportStatus,
                isActive: this.utility.getBoolean(item?.isActive) == false ? 'Inactive' : 'Active',
                createdDate:
                    item.modifiedDate != null ? new Date(item?.modifiedDate) : new Date(item?.createdDate),
            }));
            this.childGrid.dataSource = this.staffClientData;
        });
    }

    setChildGrid() {
        this.childGrid = {
            queryString: 'staffId',
            allowPaging: true,
            pageSettings: { pageSize: 6 },
            load() {
                this.registeredTemplate = {}; // set registertemplate value as empty in load event
            },
            columns: [
                {
                    field: 'clientName',
                    headerText: 'Client Name',
                    textAlign: 'Left',
                    width: 120,
                    template: this.childtemplate,
                },
                {
                    field: 'reportStatus',
                    headerText: 'Report Status',
                    textAlign: 'Center',
                    width: 120,
                },
                {
                    field: 'isActive',
                    headerText: 'Is Active',
                    textAlign: 'Center',
                    width: 120,
                    template: this.activeTemplate,
                },
                {
                    field: 'createdDate',
                    headerText: 'Modified Date',
                    textAlign: 'Right',
                    format: 'M/d/y hh:mm:ss a',
                    width: 150,
                },
            ],
        };
    }

    getClientCount(item: StaffModel): any {
        return item?.clients?.length || 0;
    }
    getClientReferralAttachment(clientsModel: ClientsModel): Observable<ImageForm> {
        return this.staffService.getAvatar(clientsModel.clientId).pipe(
            tap((data) => {
                return data;
            })
        );
    }

    onClick(event, data) {
        this.router.navigate(['/staff'], {
            queryParams: { staff_id: data.staffId },
        });
    }

    onClientClick(event, data) {
        this.router.navigate(['/client'], {
            queryParams: { client_id: data.clientId },
        });
    }
    toolbarClick(args: ClickEventArgs): void {
        if (args.item.id === 'MasterDetailsExport_excelexport') {
            this.grid.excelExport({ hierarchyExportMode: 'All' });
        }
        if (args.item.id === 'MasterDetailsExport_pdfexport') {
            this.grid.pdfExport({ hierarchyExportMode: 'All' });
        }
    }

    getChildClass(data) {
        console.log(data);
        if (data.isActive == 'Active') {
            return 'statustxt e-activecolor';
        } else {
            return 'statustxt e-inactivecolor';
        }
    }
}
