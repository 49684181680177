import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  EventEmitter,
  Inject,
  Injector,
  Input,
  Output,
  StaticProvider,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { NgxFileDropEntry } from 'ngx-file-drop';
@Component({
  selector: 'dialog-data-example-dialog',
  templateUrl: 'dialog.component.modal.html',
  styleUrls: ['./dialog.component.css'],
})
export class DialogComponent {
  @Input() title: string = '';
  @Input() model: any;
  @Input() files: any;
  @Input() form: FormGroup;
  @Output() result = new EventEmitter<string>();
  private results: any;
  public portal: ComponentPortal<any>;
  componentRef: ComponentRef<any>;
  message: string = 'Are you sure?';
  confirmButtonText = 'Yes';
  cancelButtonText = 'Cancel';
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogComponent>,
    public dialog: MatDialog,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.title = this.data.title;
    this.model = this.data.model;
    this.files = this.data.files;
    this.form = this.data.form;
    this.portal = new ComponentPortal(this.data.component);
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onConfirmClick() {
    this.dialogRef.close(this.results);
  }

  public onComponentRendering(ref): void {
    this.componentRef = ref as ComponentRef<any>;
    this.componentRef.instance.model = this.model;
    this.componentRef?.instance?.onModalClose()?.subscribe((results) => {
      this.results = results;
      this.onConfirmClick();
    });
  }

  cancelModal() {
    this.dialogRef.close();
  }

  onAdd($event) {
    this.model = this.form.value;
    // this.output.emit({form: this.model, files: this.componentRef.instance.formData })
    this.onConfirmClick();
  }
}
