import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    PdfViewerComponent,
    createSpinner,
    hideSpinner,
    showSpinner,
} from '@syncfusion/ej2-angular-pdfviewer';
import { Subject, Observable } from 'rxjs';
import { DialogComponent } from 'src/app/common/dialog.component';
import {
    ToolbarService,
    DocumentEditorContainerComponent,
    LayoutType,
    TextFormFieldInfo,
    FormFieldData,
    ImageFormat,
} from '@syncfusion/ej2-angular-documenteditor';
import { TitleBar } from '../../model/titlebar.component';
import { isNullOrUndefined } from '@syncfusion/ej2-base';
import { ReportService } from '../../services/report.service';
import { ReportsTemplateModel } from '../../model/report.template.model';
import * as moment from 'moment';
import { ClientsModel } from 'src/app/client/models/client.model';
import { ClientreferralService } from 'src/app/clientreferral/services/clientreferral.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { format } from 'date-fns';
import { AccountService } from 'src/app/login/service/account.service';
import { AlertService } from 'src/app/alerts/alert.service';
import {
    PdfDocument,
    PdfSection,
    PdfPageSettings,
    PdfPageOrientation,
    SizeF,
    PdfBitmap,
} from '@syncfusion/ej2/pdf-export';

interface State {
    report_id: any;
    reportTemplateId: any;
    templateTitle: any;
    mode: any;
    model: any;
    clientModel: any;
}
@Component({
    selector: 'app-pdfViewer',
    templateUrl: './pdf.viewer.component.html',
    styleUrls: ['./pdf.viewer.component.css'],
})
export class PDFViewerComponent implements OnInit, OnDestroy {
    @ViewChild('documenteditor_default')
    public container: DocumentEditorContainerComponent;
    public reportTitle: string = '';
    public reportTemplateId: string = '';
    public clientId: string = '';
    page: string = 'Dashboard';
    public clientDataModel: ClientsModel;
    public model: any;
    public hostUrl: string = 'https://services.syncfusion.com/angular/production/api/documenteditor/';
    public culture: string = 'en-US';
    titleBar: TitleBar;
    layoutType: LayoutType = 'Pages';
    time: string;
    contentChanged: boolean;
    mode: string;
    reportId: string;
    interval: any;
    public settings = { printDevicePixelRatio: 2 };
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DialogComponent>,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private reportService: ReportService,
        private clientService: ClientreferralService,
        private location: Location,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService
    ) {
        const navbar = document.querySelector('.navbar-bl');
        if (!navbar?.classList.contains('hide-navbar')) {
            console.log('Navbar is not hidden');
            document.getElementById('sidenav_button').click();
            //document.getElementById('speed-dial-wrapper').className = 'hide';
        }
        const state = location.getState() as State;
        this.reportId = state?.report_id;
        this.model = state?.model;
        this.reportTemplateId = state?.reportTemplateId ?? '';
        this.reportTitle = state?.templateTitle;
        this.clientDataModel = state?.clientModel;
        this.clientId = this.clientDataModel?.clientId;
        if (this.clientDataModel) {
            console.log('CLient Data Model found', this.clientDataModel);
            this.clientDataModel.reportName = this.reportTitle;
        }
        this.mode = state?.mode;
        if (this.mode == 'Add Client' || this.mode == 'Edit Client') {
            this.page = 'Client Details';
        } else {
            this.page = 'Report Grid';
        }
        //If the pdfViewer was called from the ClientScreen then capture that here.
        //We need the clientId and clientinformation to save the report to the client table and not the ReportTemplate Table
        //If we are editing a client report then a reportid will be passed in. retrive the report and load it into the viewer
        if (this.mode == 'Edit Client') {
            //If there is no template id then this is an client report update
            this.RetrieveClientReport();
            // this.LoadDocument();
        } else if (this.mode == 'Add Client' && !this.reportTemplateId) {
            console.log('Report Id Passed', this.reportId);
        } else if (this.mode == 'Add Client' || (this.mode == 'Add' && this.reportTemplateId)) {
            //if there is a client id and a template id then this is a new client report
            this.RetrieveTemplateDocument();
        } else if (!this.reportId && this.mode == 'Update') {
            //if there is no report id so this is a template update/edit
            this.RetrieveTemplateDocument();
        }
    }

    ngOnInit(): void {
        this.reportTitle = this.model?.templateName || this.clientDataModel?.reportName;
    }

    ngOnDestroy(): void {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    public updateStatus() {
        document.getElementById('saving').style.display = 'block';
        document.getElementById('saved').style.display = 'none';
        setTimeout(() => {
            document.getElementById('saving').style.display = 'none';
            document.getElementById('saved').style.display = 'block';
        }, 30000); //Auto Saves every 30 seconds
    }

    public getAge(dateString) {
        var today = new Date();
        var birthDate = new Date(dateString);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age.toString();
    }

    public getDob(dateString) {
        let dob = { dobDay: '', dobMonth: '', dobYear: '' };
        if (dateString) {
            const d = new Date(dateString);
            dob['dobDay'] = ('0' + d.getDate()).slice(-2);
            dob['dobMonth'] = ('0' + d.getMonth()).slice(-2);
            dob['dobYear'] = d.getFullYear().toString();
        }
        return dob;
    }

    onCreate(): void {
        this.container.documentEditor.spellChecker.languageID = 1033;
        this.container.documentEditor.spellChecker.removeUnderline = false;
        this.container.documentEditor.spellChecker.allowSpellCheckAndSuggestion = true;
        this.container.documentEditor.spellChecker.enableOptimizedSpellCheck = true;

        this.interval = setInterval(async () => {
            if (this.contentChanged) {
                //You can save the document as below
                document.getElementById('saving').style.display = 'block';
                document.getElementById('saved').style.display = 'none';
                this.time = format(new Date(), 'MM/dd/yyyy hh:mm:ss a').toString();
                await this.SaveDocument();
                this.contentChanged = false;
            }
        }, 45000); //Auto Saves every 45 seconds
    }
    SaveDocument() {
        this.container.documentEditor.saveAsBlob('Docx').then((blob: Blob) => {
            let exportedDocumment: Blob = blob;
            var formData = new FormData();
            formData.append(`${this.model?.templateName}.docx`, exportedDocumment);
            if (this.mode == 'Edit Client' || this.mode == 'Add Client') {
                this.clientService.uploadReferralAttachment(formData, this.reportId).subscribe((res) => {
                    document.getElementById('saving').style.display = 'none';
                    document.getElementById('saved').style.display = 'block';
                });
            } else {
                this.reportService.saveTemplateDocument(formData, this.reportTemplateId).subscribe((res) => {
                    document.getElementById('saving').style.display = 'none';
                    document.getElementById('saved').style.display = 'block';
                });
            }
        });
    }

    RetrieveTemplateDocument() {
        this.accountService.setLoading(true);
        this.reportService.getTemplateDocument(this.reportTemplateId).subscribe(
            (document) => {
                console.log('The template document from the server', document);
                //Load Document Here or Print Document
                this.container.documentEditor.open(document?.fileContentJson);
                if (this.clientDataModel) {
                    this.clientDataModel[
                        'clientFullName'
                    ] = `${this.clientDataModel['clientFirstName']} ${this.clientDataModel['clientMiddleName']} ${this.clientDataModel['clientLastName']}`;
                    this.clientDataModel[
                        'clientAddress'
                    ] = `${this.clientDataModel['streetAddress']} ${this.clientDataModel['streetAddress2']} ${this.clientDataModel['city']} ${this.clientDataModel['state']} ${this.clientDataModel['zip']}`;
                    this.clientDataModel['clientAge'] = this.clientDataModel['clientDOB']
                        ? this.getAge(this.clientDataModel['clientDOB'])
                        : '';

                    let dobDate = this.getDob(this.clientDataModel['clientDOB']);
                    this.clientDataModel['dobDay'] = dobDate['dobDay'];
                    this.clientDataModel['dobMonth'] = dobDate['dobMonth'];
                    this.clientDataModel['dobYear'] = dobDate['dobYear'];

                    this.clientDataModel['summaryName1'] = this.clientDataModel['clientFirstName'];
                    this.clientDataModel['summaryName2'] = this.clientDataModel['clientMiddleName'];
                    this.clientDataModel['summaryName3'] = this.clientDataModel['clientLastName'];

                    this.clientDataModel['summaryAge'] = this.clientDataModel['clientAge'];

                    Object.keys(this.clientDataModel).forEach((key) => {
                        // Set text form field properties
                        let textfieldInfo: TextFormFieldInfo = this.container.documentEditor.getFormFieldInfo(
                            key
                        ) as TextFormFieldInfo;
                        if (textfieldInfo) {
                            console.log('TextForm', textfieldInfo);
                            textfieldInfo.defaultValue = this.clientDataModel[key];
                            this.container.documentEditor.setFormFieldInfo(key, textfieldInfo);
                        }
                    });

                    let formFieldData: FormFieldData[] = this.container.documentEditor.exportFormData();
                    console.log('formFieldData', formFieldData);
                }
                setTimeout(() => {
                    this.accountService.setLoading(false);
                }, 1000);
            },
            (err) => {
                this.alertService.error(
                    'Error caught while loading the template document, Please go back and try again' + err
                );
                this.accountService.setLoading(false);
            }
        );
    }

    RetrieveClientReport() {
        this.accountService.setLoading(true);
        this.clientService.getReferralAttachment(this.reportId).subscribe(
            (document) => {
                console.log('The client document from the server', document);
                //Load Document Here or Print Document
                this.container.documentEditor.open(document?.fileContentJson);
                if (this.clientDataModel) {
                    this.clientDataModel[
                        'clientFullName'
                    ] = `${this.clientDataModel['clientFirstName']} ${this.clientDataModel['clientMiddleName']} ${this.clientDataModel['clientLastName']}`;
                    this.clientDataModel[
                        'clientAddress'
                    ] = `${this.clientDataModel['streetAddress']} ${this.clientDataModel['streetAddress2']} ${this.clientDataModel['city']} ${this.clientDataModel['state']} ${this.clientDataModel['zip']}`;
                    this.clientDataModel['clientAge'] = this.clientDataModel['clientDOB']
                        ? this.getAge(this.clientDataModel['clientDOB'])
                        : '';

                    let dobDate = this.getDob(this.clientDataModel['clientDOB']);
                    this.clientDataModel['dobDay'] = dobDate['dobDay'];
                    this.clientDataModel['dobMonth'] = dobDate['dobMonth'];
                    this.clientDataModel['dobYear'] = dobDate['dobYear'];

                    this.clientDataModel['summaryName1'] = this.clientDataModel['clientFirstName'];
                    this.clientDataModel['summaryName2'] = this.clientDataModel['clientMiddleName'];
                    this.clientDataModel['summaryName3'] = this.clientDataModel['clientLastName'];

                    this.clientDataModel['summaryAge'] = this.clientDataModel['clientAge'];

                    Object.keys(this.clientDataModel).forEach((key) => {
                        // Set text form field properties
                        let textfieldInfo: TextFormFieldInfo = this.container.documentEditor.getFormFieldInfo(
                            key
                        ) as TextFormFieldInfo;
                        if (textfieldInfo) {
                            console.log('TextForm', textfieldInfo);
                            textfieldInfo.defaultValue = this.clientDataModel[key];
                            this.container.documentEditor.setFormFieldInfo(key, textfieldInfo);
                        }
                    });

                    let formFieldData: FormFieldData[] = this.container.documentEditor.exportFormData();
                    console.log('formFieldData', formFieldData);
                }
                setTimeout(() => {
                    this.accountService.setLoading(false);
                }, 1000);
            },
            (err) => {
                this.alertService.error(
                    'Error caught while loading the client document, Please go back and try again' + err
                );
                this.accountService.setLoading(false);
            }
        );
    }

    LoadDocument() {
        throw new Error('Method not implemented.');
    }

    onContentChange(): void {
        this.contentChanged = true;
    }

    onDocumentChange(): void {
        if (!isNullOrUndefined(this.titleBar)) {
            this.titleBar.updateDocumentTitle();
        }
        this.container.documentEditor.focusIn();
    }

    onPrint(): void {
        this.container.documentEditor.print();
    }

    async close() {
        await this.SaveDocument();
        document.getElementById('sidenav_button').click();
        //document.getElementById('speed-dial-wrapper').className = 'show';
        if (this.mode == 'Add Client' || this.mode == 'Edit Client') {
            this.router.navigate(['/client'], { queryParams: { client_id: this.clientId } });
        } else {
            this.router.navigate(['/reporting']);
        }
    }

    async save() {
        document.getElementById('saving').style.display = 'block';
        document.getElementById('saved').style.display = 'none';
        this.time = format(new Date(), 'MM/dd/yyyy hh:mm:ss a').toString();
        await this.SaveDocument();
        this.contentChanged = false;
    }

    exportPDF() {
        let obj = this;
        let pdfdocument: PdfDocument = new PdfDocument();
        let count: number = obj.container.documentEditor.pageCount;
        obj.container.documentEditor.documentEditorSettings.printDevicePixelRatio = 2;
        let title = this.reportTitle;
        let loadedPage = 0;
        for (let i = 1; i <= count; i++) {
            setTimeout(() => {
                let format: ImageFormat = 'image/jpeg' as ImageFormat;
                // Getting pages as image
                let image = obj.container.documentEditor.exportAsImage(i, format);
                image.onload = function () {
                    let imageHeight = parseInt(image.style.height.toString().replace('px', ''));
                    let imageWidth = parseInt(image.style.width.toString().replace('px', ''));
                    let section: PdfSection = pdfdocument.sections.add() as PdfSection;
                    let settings: PdfPageSettings = new PdfPageSettings(0);
                    if (imageWidth > imageHeight) {
                        settings.orientation = PdfPageOrientation.Landscape;
                    }
                    settings.size = new SizeF(imageWidth, imageHeight);
                    (section as PdfSection).setPageSettings(settings);
                    let page = section.pages.add();
                    let graphics = page.graphics;
                    let imageStr = image.src.replace('data:image/jpeg;base64,', '');
                    let pdfImage = new PdfBitmap(imageStr);
                    graphics.drawImage(pdfImage, 0, 0, imageWidth, imageHeight);
                    loadedPage++;
                    if (loadedPage == count) {
                        // Exporting the document as pdf
                        pdfdocument.save((title == '' ? 'Client Report' : title) + '.pdf');
                    }
                };
            }, 500);
        }
    }
}
