import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, NgForm } from '@angular/forms';

@Component({
  selector: 'add-report-row-column-modal',
  templateUrl: './row-column-modal.component.html',
  styleUrls: ['./row-column-modal.component.css'],
})
export class RowColumnModalComponent {
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) data: { message: string },
    public dialogRef: MatDialogRef<RowColumnModalComponent>
  ) {
    this.form = this.fb.group({
      rows: ['', Validators.required],
      columns: ['', Validators.required],
    });
  }

  submit(form: NgForm) {
    if (
      this.form.value &&
      this.form.value.rows > 0 &&
      this.form.value.columns > 0
    ) {
      this.dialogRef.close({
        clicked: 'submit',
        form: form,
      });
    }
  }
}
