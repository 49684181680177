import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { StaffModel } from '../model/staff.model';
import { MessageService } from '../../../../src/app/common/message.service';
import { environment } from '../../../../src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class StaffService {
  http: HttpClient;
  clientUrl: string = environment.apiUrl + '/Staff';

  constructor(httpClient: HttpClient, private messageService: MessageService) {
    this.http = httpClient;
  }

  getAllStaff(): Observable<HttpResponse<StaffModel[]>> {
    return this.http
      .get<StaffModel[]>(this.clientUrl + '/GetAll', {
        observe: 'response',
      })
      .pipe(
        catchError((err) => {
          this.handleError<StaffModel[]>('Updloading Form', err);
          throw 'Error in getClientReferral. Details: ' + err;
        })
      );
  }

  public uploadAvatarAttachment(formData: any, staffId: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
    };
    return this.http
      .post<any>(this.clientUrl + `/Upload/?id=${staffId}`, formData)
      .pipe(
        catchError((err) => {
          this.handleError<StaffModel[]>('Updloading Form', err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  public getAvatar(staffId: any): Observable<any> {
    return this.http
      .post<FormData>(
        this.clientUrl + `/GetReferralAttachment?id=${staffId}`,
        {}
      )
      .pipe(
        catchError((err) => {
          this.handleError<StaffModel[]>('Downloading Form', err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  public AddStaff(StaffModel: StaffModel): Observable<StaffModel[]> {
    return this.http
      .post<StaffModel[]>(this.clientUrl + '/AddStaff', StaffModel, httpOptions)
      .pipe(
        catchError((err) => {
          this.handleError<StaffModel[]>('Add', err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  public updateStaff(StaffModel: StaffModel): Observable<StaffModel[]> {
    return this.http
      .post<StaffModel[]>(
        this.clientUrl + '/UpdateStaff?id=' + StaffModel.staffId,
        StaffModel,
        httpOptions
      )
      .pipe(
        catchError((err) => {
          this.handleError<StaffModel[]>('Update', err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  public deactivateClient(StaffModel: StaffModel): Observable<StaffModel[]> {
    return this.http
      .delete<StaffModel[]>(
        `${this.clientUrl} /DeactivateStaff?id=${StaffModel.staffId}`,
        httpOptions
      )
      .pipe(
        catchError((err) => {
          this.handleError<StaffModel[]>('Delete', err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  getStaffById(id: number): Observable<StaffModel> {
    const url = `${this.clientUrl}/GetStaff?id=${id}`;
    return this.http.get<StaffModel>(url).pipe(
      tap((_) => this.log(`fetched hero id=${id}`)),
      catchError(this.handleError<StaffModel>(`getClient id=${id}`))
    );
  }

  resetStaffClients(id: number): Observable<StaffModel> {
    const url = `${this.clientUrl}/ResetStaffClients?id=${id}`;
    return this.http.get<StaffModel>(url).pipe(
      tap((_) => this.log(`fetched hero id=${id}`)),
      catchError(this.handleError<StaffModel>(`getClient id=${id}`))
    );
  }

  private log(message: string) {
    this.messageService.add(`ClientReferralService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
