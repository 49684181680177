export let cardData: Object[] = [
    {
        taskId: 'Task 1',
        taskTitle: 'Task - 29001',
        status: 'Open',
        taskSummary: 'Analyze customer requirements.',
        priority: 'High',
        tags: 'Bug, Release Bug',
        rankId: 1,
        assignee: 'Nancy Davloio',
        dueDate: '09/26/2024 12:43:22',
        createdDate: '09/26/2023 12:43:22',
        modifiedDate: '',
    },
    {
        taskId: 'Task 2',
        taskTitle: 'Task - 29002',
        status: 'InProgress',
        taskSummary: 'Add responsive support to applicaton',
        priority: 'Low',
        tags: 'Story, Kanban',
        rankId: 1,
        assignee: 'Andrew Fuller',
        dueDate: '09/20/2023 12:43:22',
        createdDate: '09/26/2023 12:43:22',
        modifiedDate: '',
    },
    {
        taskId: 'Task 3',
        taskTitle: 'Task - 29003',
        status: 'Open',
        taskSummary: 'Show the retrived data from the server in grid control.',
        priority: 'High',
        tags: 'Bug, Breaking Issue',
        rankId: 2,
        assignee: 'Janet Leverling',
        dueDate: '09/23/2023 12:43:22',
        createdDate: '09/26/2023 12:43:22',
        modifiedDate: '',
    },
    {
        taskId: 'Task 4',
        taskTitle: 'Task - 29004',
        status: 'Open',
        taskSummary: 'Fix the issues reported in the IE browser.',
        priority: 'Critical',
        tags: 'Bug, Customer',
        rankId: 3,
        assignee: 'Andrew Fuller',
        dueDate: '09/15/2023 12:43:22',
        createdDate: '09/26/2023 12:43:22',
        modifiedDate: '',
    },
    {
        taskId: 'Task 5',
        taskTitle: 'Task - 29005',
        status: 'Review',
        taskSummary: 'Improve application performance.',
        priority: 'Medium',
        tags: 'Story, Kanban',
        rankId: 1,
        assignee: 'Steven walker',
        dueDate: '09/21/2023 12:43:22',
        createdDate: '09/26/2023 12:43:22',
        modifiedDate: '',
    },
    {
        taskId: 'Task 6',
        taskTitle: 'Task - 29009',
        status: 'Review',
        taskSummary: 'API Improvements.',
        priority: 'Critical',
        tags: 'Bug, Customer',
        rankId: 2,
        assignee: 'Nancy Davloio',
        dueDate: '09/26/2024 12:43:22',
        createdDate: '09/26/2023 12:43:22',
        modifiedDate: '',
    },
    {
        taskId: 'Task 7',
        taskTitle: 'Task - 29010',
        status: 'Close',
        taskSummary: "Fix cannot open user's default database sql error.",
        priority: 'High',
        tags: 'Bug, Internal',
        rankId: 8,
        assignee: 'Margaret hamilt',
        dueDate: '09/29/2023 12:43:22',
        createdDate: '09/21/2023 12:43:22',
        modifiedDate: '',
    },
];

export let kanbanData: Object[] = [
    {
        taskId: 'Task 1',
        taskTitle: 'Task - 29001',
        status: 'Open',
        taskSummary: 'Analyze the new requirements gathered from the customer.',
        Type: 'Story',
        priority: 'Low',
        tags: 'Analyze,Customer',
        Estimate: 3.5,
        assignee: 'Nancy Davloio',
        rankId: 1,
        Color: '#02897B',
        ClassName: 'e-story, e-low, e-nancy-davloio',
    },
    {
        taskId: 'Task 2',
        taskTitle: 'Task - 29002',
        status: 'InProgress',
        taskSummary: 'Improve application performance',
        Type: 'Improvement',
        priority: 'Medium',
        tags: 'Improvement',
        Estimate: 6,
        assignee: 'Andrew Fuller',
        rankId: 1,
        Color: '#673AB8',
        ClassName: 'e-improvement, e-normal, e-andrew-fuller',
    },
    {
        taskId: 'Task 3',
        taskTitle: 'Task - 29003',
        status: 'Open',
        taskSummary: 'Arrange a web meeting with the customer to get new requirements.',
        Type: 'Others',
        priority: 'Critical',
        tags: 'Meeting',
        Estimate: 5.5,
        assignee: 'Janet Leverling',
        rankId: 2,
        Color: '#1F88E5',
        ClassName: 'e-others, e-critical, e-janet-leverling',
    },
    {
        taskId: 'Task 4',
        taskTitle: 'Task - 29004',
        status: 'InProgress',
        taskSummary: 'Fix the issues reported in the IE browser.',
        Type: 'Bug',
        priority: 'Critical',
        tags: 'IE',
        Estimate: 2.5,
        assignee: 'Janet Leverling',
        rankId: 2,
        Color: '#E64A19',
        ClassName: 'e-bug, e-release, e-janet-leverling',
    },
    {
        taskId: 'Task 5',
        taskTitle: 'Task - 29005',
        status: 'Review',
        taskSummary: 'Fix the issues reported by the customer.',
        Type: 'Bug',
        priority: 'Low',
        tags: 'Customer',
        Estimate: '3.5',
        assignee: 'Steven walker',
        rankId: 1,
        Color: '#E64A19',
        ClassName: 'e-bug, e-low, e-steven-walker',
    },
    {
        taskId: 'Task 6',
        taskTitle: 'Task - 29007',
        status: 'Validate',
        taskSummary: 'Validate new requirements',
        Type: 'Improvement',
        priority: 'Low',
        tags: 'Validation',
        Estimate: 1.5,
        assignee: 'Robert King',
        rankId: 1,
        Color: '#673AB8',
        ClassName: 'e-improvement, e-low, e-robert-king',
    },
    {
        taskId: 'Task 7',
        taskTitle: 'Task - 29009',
        status: 'Review',
        taskSummary: 'Fix the issues reported in Safari browser.',
        Type: 'Bug',
        priority: 'Critical',
        tags: 'Fix,Safari',
        Estimate: 1.5,
        assignee: 'Nancy Davloio',
        rankId: 2,
        Color: '#E64A19',
        ClassName: 'e-bug, e-release, e-nancy-davloio',
    },
    {
        taskId: 'Task 8',
        taskTitle: 'Task - 29010',
        status: 'Close',
        taskSummary: 'Test the application in the IE browser.',
        Type: 'Story',
        priority: 'Low',
        tags: 'Review,IE',
        Estimate: 5.5,
        assignee: 'Margaret hamilt',
        rankId: 3,
        Color: '#02897B',
        ClassName: 'e-story, e-low, e-margaret-hamilt',
    },
    {
        taskId: 'Task 9',
        taskTitle: 'Task - 29011',
        status: 'Validate',
        taskSummary: 'Validate the issues reported by the customer.',
        Type: 'Story',
        priority: 'High',
        tags: 'Validation,Fix',
        Estimate: 1,
        assignee: 'Steven walker',
        rankId: 1,
        Color: '#02897B',
        ClassName: 'e-story, e-high, e-steven-walker',
    },
    {
        taskId: 'Task 10',
        taskTitle: 'Task - 29015',
        status: 'Open',
        taskSummary: 'Show the retrieved data from the server in grid control.',
        Type: 'Story',
        priority: 'High',
        tags: 'Database,SQL',
        Estimate: 5.5,
        assignee: 'Margaret hamilt',
        rankId: 4,
        Color: '#02897B',
        ClassName: 'e-story, e-high, e-margaret-hamilt',
    },
    {
        taskId: 'Task 11',
        taskTitle: 'Task - 29016',
        status: 'InProgress',
        taskSummary: 'Fix cannot open user’s default database SQL error.',
        priority: 'Critical',
        Type: 'Bug',
        tags: 'Database,Sql2008',
        Estimate: 2.5,
        assignee: 'Janet Leverling',
        rankId: 4,
        Color: '#E64A19',
        ClassName: 'e-bug, e-critical, e-janet-leverling',
    },
    {
        taskId: 'Task 12',
        taskTitle: 'Task - 29017',
        status: 'Review',
        taskSummary: 'Fix the issues reported in data binding.',
        Type: 'Story',
        priority: 'Medium',
        tags: 'Databinding',
        Estimate: '3.5',
        assignee: 'Janet Leverling',
        rankId: 4,
        Color: '#02897B',
        ClassName: 'e-story, e-normal, e-janet-leverling',
    },
    {
        taskId: 'Task 13',
        taskTitle: 'Task - 29018',
        status: 'Close',
        taskSummary: 'Analyze SQL server 2008 connection.',
        Type: 'Story',
        priority: 'Critical',
        tags: 'Grid,Sql',
        Estimate: 2,
        assignee: 'Andrew Fuller',
        rankId: 4,
        Color: '#02897B',
        ClassName: 'e-story, e-release, e-andrew-fuller',
    },
    {
        taskId: 'Task 14',
        taskTitle: 'Task - 29019',
        status: 'Validate',
        taskSummary: 'Validate databinding issues.',
        Type: 'Story',
        priority: 'Low',
        tags: 'Validation',
        Estimate: 1.5,
        assignee: 'Margaret hamilt',
        rankId: 1,
        Color: '#02897B',
        ClassName: 'e-story, e-low, e-margaret-hamilt',
    },
    {
        taskId: 'Task 15',
        taskTitle: 'Task - 29020',
        status: 'Close',
        taskSummary: 'Analyze grid control.',
        Type: 'Story',
        priority: 'High',
        tags: 'Analyze',
        Estimate: 2.5,
        assignee: 'Margaret hamilt',
        rankId: 5,
        Color: '#02897B',
        ClassName: 'e-story, e-high, e-margaret-hamilt',
    },
    {
        taskId: 'Task 16',
        taskTitle: 'Task - 29021',
        status: 'Close',
        taskSummary: 'Stored procedure for initial data binding of the grid.',
        Type: 'Others',
        priority: 'Critical',
        tags: 'Databinding',
        Estimate: 1.5,
        assignee: 'Steven walker',
        rankId: 6,
        Color: '#1F88E5',
        ClassName: 'e-others, e-release, e-steven-walker',
    },
    {
        taskId: 'Task 17',
        taskTitle: 'Task - 29022',
        status: 'Close',
        taskSummary: 'Analyze stored procedures.',
        Type: 'Story',
        priority: 'Critical',
        tags: 'Procedures',
        Estimate: 5.5,
        assignee: 'Janet Leverling',
        rankId: 7,
        Color: '#02897B',
        ClassName: 'e-story, e-release, e-janet-leverling',
    },
    {
        taskId: 'Task 18',
        taskTitle: 'Task - 29023',
        status: 'Validate',
        taskSummary: 'Validate editing issues.',
        Type: 'Story',
        priority: 'Critical',
        tags: 'Editing',
        Estimate: 1,
        assignee: 'Nancy Davloio',
        rankId: 1,
        Color: '#02897B',
        ClassName: 'e-story, e-critical, e-nancy-davloio',
    },
    {
        taskId: 'Task 19',
        taskTitle: 'Task - 29024',
        status: 'Review',
        taskSummary: 'Test editing functionality.',
        Type: 'Story',
        priority: 'Medium',
        tags: 'Editing,Test',
        Estimate: 0.5,
        assignee: 'Nancy Davloio',
        rankId: 5,
        Color: '#02897B',
        ClassName: 'e-story, e-normal, e-nancy-davloio',
    },
    {
        taskId: 'Task 20',
        taskTitle: 'Task - 29025',
        status: 'Open',
        taskSummary: 'Enhance editing functionality.',
        Type: 'Improvement',
        priority: 'Low',
        tags: 'Editing',
        Estimate: 3.5,
        assignee: 'Andrew Fuller',
        rankId: 5,
        Color: '#673AB8',
        ClassName: 'e-improvement, e-low, e-andrew-fuller',
    },
    {
        taskId: 'Task 21',
        taskTitle: 'Task - 29026',
        status: 'InProgress',
        taskSummary: 'Improve the performance of the editing functionality.',
        Type: 'Epic',
        priority: 'High',
        tags: 'Performance',
        Estimate: 6,
        assignee: 'Nancy Davloio',
        rankId: 5,
        Color: '#e91e64',
        ClassName: 'e-epic, e-high, e-nancy-davloio',
    },
    {
        taskId: 'Task 22',
        taskTitle: 'Task - 29027',
        status: 'Open',
        taskSummary: 'Arrange web meeting with the customer to show editing demo.',
        Type: 'Others',
        priority: 'High',
        tags: 'Meeting,Editing',
        Estimate: 5.5,
        assignee: 'Steven walker',
        rankId: 6,
        Color: '#1F88E5',
        ClassName: 'e-others, e-high, e-steven-walker',
    },
    {
        taskId: 'Task 23',
        taskTitle: 'Task - 29029',
        status: 'Review',
        taskSummary: 'Fix the editing issues reported by the customer.',
        Type: 'Bug',
        priority: 'Low',
        tags: 'Editing,Fix',
        Estimate: '3.5',
        assignee: 'Janet Leverling',
        rankId: 6,
        Color: '#E64A19',
        ClassName: 'e-bug, e-low, e-janet-leverling',
    },
    {
        taskId: 'Task 24',
        taskTitle: 'Task - 29030',
        status: 'Testing',
        taskSummary: 'Fix the issues reported by the customer.',
        Type: 'Bug',
        priority: 'Critical',
        tags: 'Customer',
        Estimate: '3.5',
        assignee: 'Steven walker',
        rankId: 1,
        Color: '#E64A19',
        ClassName: 'e-bug, e-critical, e-steven-walker',
    },
    {
        taskId: 'Task 25',
        taskTitle: 'Task - 29031',
        status: 'Testing',
        taskSummary: 'Fix the issues reported in Safari browser.',
        Type: 'Bug',
        priority: 'Critical',
        tags: 'Fix,Safari',
        Estimate: 1.5,
        assignee: 'Nancy Davloio',
        rankId: 2,
        Color: '#E64A19',
        ClassName: 'e-bug, e-release, e-nancy-davloio',
    },
    {
        taskId: 'Task 26',
        taskTitle: 'Task - 29032',
        status: 'Testing',
        taskSummary: 'Check Login page validation.',
        Type: 'Story',
        priority: 'Critical',
        tags: 'Testing',
        Estimate: 0.5,
        assignee: 'Michael Suyama',
        rankId: 3,
        Color: '#02897B',
        ClassName: 'e-story, e-release, e-michael-suyama',
    },
    {
        taskId: 'Task 27',
        taskTitle: 'Task - 29033',
        status: 'Testing',
        taskSummary: 'Fix the issues reported in data binding.',
        Type: 'Story',
        priority: 'Medium',
        tags: 'Databinding',
        Estimate: '3.5',
        assignee: 'Janet Leverling',
        rankId: 4,
        Color: '#02897B',
        ClassName: 'e-story, e-normal, e-janet-leverling',
    },
    {
        taskId: 'Task 28',
        taskTitle: 'Task - 29034',
        status: 'Testing',
        taskSummary: 'Test editing functionality.',
        Type: 'Story',
        priority: 'Medium',
        tags: 'Editing,Test',
        Estimate: 0.5,
        assignee: 'Nancy Davloio',
        rankId: 5,
        Color: '#02897B',
        ClassName: 'e-story, e-normal, e-nancy-davloio',
    },
    {
        taskId: 'Task 29',
        taskTitle: 'Task - 29035',
        status: 'Testing',
        taskSummary: 'Fix editing issues reported in Firefox.',
        Type: 'Bug',
        priority: 'Critical',
        tags: 'Editing,Fix',
        Estimate: 1.5,
        assignee: 'Robert King',
        rankId: 7,
        Color: '#E64A19',
        ClassName: 'e-bug, e-critical, e-robert-king',
    },
    {
        taskId: 'Task 30',
        taskTitle: 'Task - 29036',
        status: 'Testing',
        taskSummary: 'Test editing feature in the IE browser.',
        Type: 'Story',
        priority: 'Medium',
        tags: 'Testing',
        Estimate: 5.5,
        assignee: 'Janet Leverling',
        rankId: 10,
        Color: '#02897B',
        ClassName: 'e-story, e-normal, e-janet-leverling',
    },
];
