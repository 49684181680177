import { Guid } from 'guid-typescript';

export class UserModel {
  id?: string = '';
  email?: string = '';
  refreshToken?: string = '';
  refreshTokenExpiryTime?: string = '';
  role?: string = '';
  firstName?: string = '';
  vendorName?: string = '';
  lastName?: string;
  verificationToken?: string = '';
  verifiedAt?: string = '';
  password?: string = '';
  passwordresetToken?: string = '';
  resetTokenExpires?: string;
}
