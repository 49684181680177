import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  VERSION,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, Observer, Subject } from 'rxjs';
import { ClientreferralModel } from '../clientreferral/models/clientreferral.model';
import { DialogComponent } from '../common/dialog.component';

@Component({
  selector: 'app-add-customer',
  templateUrl: './apptemp.component.html',
  styles: [
    `
      [nz-form] {
        max-width: 600px;
      }

      button {
        margin-left: 8px;
      }
    `,
  ],
})
export class AddCustomerComponent implements OnInit {
  validateForm: FormGroup;
  @Input() title: string = '';
  @Input() model: ClientreferralModel;
  @Input() files: any;
  @Output() output = new EventEmitter<any>();
  mode: string = '';
  modeLanguage: string = '';
  formData = new FormData();
  private modalClose: Subject<any> = new Subject();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogComponent>,
    public dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.title = this.data.title;
    this.model = this.data.model;
    this.files = this.data.files;
    this.mode = this.data.mode;
    this.modeLanguage = this.mode == 'Update' ? 'updating' : 'creating';
    this.validateForm = new FormGroup({
      clientId: new FormControl(''),
      clientName: new FormControl('', Validators.required),
      clientNumber: new FormControl('', Validators.required),
      clientDOB: new FormControl('', Validators.required),
      caseType: new FormControl('', Validators.required),
      dateSubmitted: new FormControl(''),
      reportStatus: new FormControl(''),
      referralNotes: new FormControl(''),
    });

    if (this.title.toLowerCase().includes('update')) {
      this.patchFormValues();
    }
  }

  patchFormValues() {
    this.validateForm.get('clientName').setValue(this.model.clientName);
    this.validateForm.get('clientNumber').setValue(this.model.clientNumber);
    this.validateForm.get('clientDOB').setValue(this.model.clientDOB);
    this.validateForm.get('caseType').setValue(this.model.caseType);
    this.validateForm.get('reportStatus').setValue(this.model.reportStatus);
    this.validateForm.get('referralNotes').setValue(this.model.referralNotes);
    this.validateForm.get('clientId').setValue(this.model.clientId);
  }
  submitForm(): void {
    console.log('submit', this.validateForm.value);
    this.onAddClientReferral();
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(key)) {
        this.validateForm.controls[key].markAsPristine();
        this.validateForm.controls[key].updateValueAndValidity();
      }
    }
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.validateForm.controls.confirm.updateValueAndValidity()
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  userNameAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (control.value === 'JasonWood') {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 1000);
    });

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  onAddClientReferral() {
    this.model = this.validateForm.value;
    this.output.emit({ form: this.model, files: this.formData });
    this.closeModal();
  }

  onModalClose(): Observable<any> {
    return this.modalClose.asObservable();
  }

  closeModal() {
    //if(this.tempMode == 'Cancel'){return;}
    this.modalClose.next({
      form: this.model,
      files: this.formData,
      mode: this.mode,
    });
    this.modalClose.complete();
    this.dialogRef.close();
  }
  public dropped(files: NgxFileDropEntry[]) {
    if (files.length > 1) {
      alert('Only one file can be attached to this referral');
    }
    this.files = files;
    this.formData = new FormData();
    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {
          // Here you can access the real file
          console.log(droppedFile.relativePath, file);
          try {
            // File Check
            // Here you can have some custom function to check whatever parameters you want, return true if valid...
            if (!this.isValidFile(file)) {
              alert('Invalid file format');
            }
            // Process your file now
            this.formData.append('referrals', file, droppedFile.relativePath);
          } catch (err) {
            this.files = null;
          }
        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }
    }
  }
  public fileOver(event) {
    console.log(event);
  }
  cancelModal() {
    this.modalClose.complete();
    this.dialogRef.close();
  }
  public fileLeave(event) {
    console.log(event);
  }

  public isValidFile(file: File) {
    // enforce a "." being contained in the file name
    if (!file?.name.includes('.')) {
      return false;
    }

    // There should be a total of 2 strings, with a period in between in my case
    const names = file?.name.split('.');
    if (names.length !== 2) {
      return false;
    }

    const ext = names[1];
    if (['pdf', 'doc', 'docx'].includes(ext)) {
      return true;
    }

    return false;
  }
}
