import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/material.module';
import { TaskRoutingModule } from './tasks.routing';
import { KanbanAllModule } from '@syncfusion/ej2-angular-kanban';
import { AlertService } from 'src/app/alerts/alert.service';
import { TaskService } from '../services/task.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgZorroAntdModule } from 'src/app/zorro.module';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { MaskitoModule } from '@maskito/angular';
import { SwitchComponent, SwitchModule } from '@syncfusion/ej2-angular-buttons';
import { rippleMouseHandler } from '@syncfusion/ej2-buttons';
import { AuthService } from 'src/app/login/service/auth.service';
import { TasksComponent } from '../components/tasks-board/tasks.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        TaskRoutingModule,
        AngularMaterialModule,
        KanbanAllModule,
        DialogModule,
        DropDownListAllModule,
        FormsModule,
        AngularMaterialModule,
        NgZorroAntdModule,
        FlexLayoutModule,
        MaskitoModule,
        SwitchModule,
    ],
    declarations: [TasksComponent],
    providers: [TaskService, AlertService, AuthService],
})
export class TaskModule {}
