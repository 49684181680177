import { Guid } from 'guid-typescript';
import { ClientsModel } from '../../client/models/client.model';

export class StaffModel {
    staffId: string = Guid.create().toString();
    staffNumber: string = '';
    createdDate: string = new Date().toISOString();
    clients: ClientsModel[] = [];
    isActive: string = 'Y';
    role?: string = '';
    firstName?: string = '';
    middleName?: string = '';
    lastName?: string = '';
    staffTitle?: string = '';
    vendorName?: string = '';
    modifiedDate?: Date = null;
    titlesCertifications?: string = '';
    titlesName?: string = '';
    hireDate?: Date = null;
    terminationDate?: Date = null;
    degreeLevel?: string = '';
    staffLanguage?: string = 'en';
    serviceAreaZipCode?: string = '';
    emailAddress?: string = '';
    password?: string = '';
    confirmPassword?: string = '';
    mobilePhone?: string = '';
    officePhone?: string = '';
    position?: string = '';
    taxId?: string = '';
    providerNPI?: string = '';
    providerLicense?: string = '';
    DEANumber?: string = '';
    dateofBirth?: Date = null;
    driverlicense?: string = '';
    socialSecurityNumber?: string = '';
    gender?: string = '';
    pronouns?: string = '';
    street?: string = '';
    street2?: string = '';
    city?: string = '';
    state?: string = '';
    zip?: string = '';
    country?: string = 'US';
    numberOfClients?: number;
    staffName?: string = '';
}
