import {
    Component,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    VERSION,
    ViewChild,
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
    ValidationErrors,
    Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, Observer, Subject, take } from 'rxjs';
import { DialogComponent } from 'src/app/common/dialog.component';
import { CustomValidators } from 'src/app/helpers/customvalidators';
import {
    VendorModel,
    CompanyInformationModel,
    LocationInformationModel,
} from '../../models/assessment.model';
import { AssessmentService } from '../../services/assessment.service';
var _ = require('lodash');

@Component({
    selector: 'app-assessment-location-info',
    templateUrl: './assessment.locationform.component.html',
    styles: [
        `
            [nz-form] {
                max-width: 600px;
            }

            button {
                margin-left: 8px;
            }
            .dynamic-delete-button {
                cursor: pointer;
                position: relative;
                top: 4px;
                font-size: 14px;
                transition: all 0.3s;
                padding-left: 20px;
                color: red;
            }
            .dynamic-delete-button:hover {
                color: #777;
            }
        `,
    ],
})
export class AssessmentLocationFormComponent implements OnInit {
    @Input() title: string = '';
    @Input() model: VendorModel;
    @Input() files: any;
    @Output() assessmentOutput = new EventEmitter<any>();
    mode: string = '';
    public validateForm: Array<FormGroup> = [];
    public stateList: any;
    showBreakBar: boolean = false;
    loading = false;
    submitted = false;
    address1: string = '';
    address2: string = '';
    city: string = '';
    state: string = '';
    zip: string = '';

    options = {
        types: [],
        componentRestrictions: { country: 'US' },
    };

    formData = new FormData();
    private modalClose: Subject<any> = new Subject();
    frmSignup: FormGroup = this.fb.group({
        validationForm: this.fb.array([]),
    });
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private dialogRef: MatDialogRef<DialogComponent>,
        public dialog: MatDialog,
        private service: AssessmentService
    ) {
        this.service
            .getNextEvent()
            .pipe(take(1))
            .subscribe(() => {
                this.next();
            });
        //this.stateList = states.map((x) => x.abbreviation);
        const formArry = this.createLocationForm();
        this.frmSignup.setControl('validationForm', formArry);
        console.log('ValidateForm', this.frmSignup);
    }
    @ViewChild('placesRef') placesRef: any;

    public handleAddressChange(address: any) {
        // Do some stuff
    }
    ngOnInit(): void {
        this.title = this.data.title;
        this.model = this.data.model;
        this.mode = this.data.mode;
        this.patchFormValues();
        if (this.getForm.length > 1) {
            this.showBreakBar = true;
        }
    }

    keydownInDropdown(event) {
        if (event.keyCode == 13) {
            // set highlighted value as selected value. (default)
            console.log(event);
        }

        if (event.keyCode == 37 || event.keyCode == 39) {
            // set highlighted value as selected value.
            console.log(event);
        }
        // console.log("keydown is ",event)
    }

    createLocationForm(): FormArray {
        return new FormArray([
            new FormGroup({
                address1: new FormControl('', Validators.required),
                address2: new FormControl(''),
                city: new FormControl('', Validators.required),
                state: new FormControl('', Validators.required),
                zip: new FormControl('', Validators.required),
            }),
        ]);
    }

    createSignupForm(): FormGroup {
        return this.fb.group({
            address1: ['', Validators.required],
            address2: [],
            city: ['', Validators.required],
            state: ['', Validators.required],
            zip: ['', Validators.required],
        });
    }

    addField(e?: MouseEvent): void {
        if (e) {
            e.preventDefault();
        }
        const formArry = this.createSignupForm();
        let valForm = this.getForm;
        valForm.push(formArry);
        this.frmSignup = this.fb.group({
            validationForm: this.fb.array([]),
        });
        this.frmSignup.setControl('validationForm', valForm);
        console.log('Added Form', this.frmSignup);
        this.showBreakBar = true;
    }

    removeField(i: number, e: MouseEvent): void {
        e.preventDefault();
        let valForm = this.getForm;
        if (valForm.length > 1) {
            this.frmSignup = this.fb.group({
                validationForm: this.fb.array([]),
            });
            const index = i;
            valForm.controls.splice(index, 1);
            this.frmSignup.setControl('validationForm', valForm);
            if (valForm.controls.length == 1) {
                this.showBreakBar = false;
            }
        }
    }

    // convenience getter for easy access to form fields
    get getForm(): FormArray {
        return this.frmSignup.get('validationForm') as FormArray;
    }

    onAutocompleteSelected(address: any) {
        var addressModel = this.getAddressObject(address.address_components);
        this.frmSignup[0].patchValue({
            address1: addressModel['home'] + ' ' + addressModel['street'],
            address2: '',
            city: addressModel['city'],
            state: addressModel['region'],
            zip: addressModel['postal_code'],
        });
        this.frmSignup[0].updateValueAndValidity();
    }

    patchFormValues() {
        let patchForm = this.getForm;
        let valForm = new FormArray([]);
        this.model.locationInformationModels.forEach((location, index) => {
            patchForm.controls.forEach((f: FormGroup) => {
                Object.keys(f.controls).forEach((control: string) => {
                    try {
                        f.controls[control].setValue(location[control]);
                    } catch (error) {
                        // we'll proceed, but let's report it
                        console.log(error);
                    }
                });

                var deep = _.cloneDeep(f);
                valForm.push(deep);
            });
        });
        this.frmSignup = this.fb.group({
            validationForm: this.fb.array([]),
        });
        this.frmSignup.setControl('validationForm', valForm);
        this.frmSignup.updateValueAndValidity();
    }

    submitForm(): void {
        console.log('submit', this.validateForm[0].value);
        this.onAddClientReferral();
    }

    resetForm(e: MouseEvent): void {
        e.preventDefault();
        this.validateForm[0].reset();
        for (const key in this.validateForm[0].controls) {
            if (this.validateForm[0].controls.hasOwnProperty(key)) {
                this.validateForm[0].controls[key].markAsPristine();
                this.validateForm[0].controls[key].updateValueAndValidity();
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    userNameAsyncValidator = (control: FormControl) =>
        new Observable((observer: Observer<ValidationErrors | null>) => {
            setTimeout(() => {
                if (control.value === 'JasonWood') {
                    // you have to return `{error: true}` to mark it as an error event
                    observer.next({ error: true, duplicated: true });
                } else {
                    observer.next(null);
                }
                observer.complete();
            }, 1000);
        });

    next() {
        this.model.locationInformationModels = this.getForm.controls.map((x) => x.value);
        console.log('nextinfomodel', this.model.locationInformationModels);
    }

    onAddClientReferral() {
        this.model = this.validateForm[0].value;
        this.assessmentOutput.emit({ form: this.model });
    }
    getAddressObject(address_components) {
        var ShouldBeComponent = {
            home: ['street_number'],
            postal_code: ['postal_code'],
            street: ['street_address', 'route'],
            region: [
                'administrative_area_level_1',
                'administrative_area_level_2',
                'administrative_area_level_3',
                'administrative_area_level_4',
                'administrative_area_level_5',
            ],
            city: [
                'locality',
                'sublocality',
                'sublocality_level_1',
                'sublocality_level_2',
                'sublocality_level_3',
                'sublocality_level_4',
            ],
            country: ['country'],
        };

        var address = {
            home: '',
            postal_code: '',
            street: '',
            region: '',
            city: '',
            country: '',
        };
        address_components.forEach((component) => {
            for (var shouldBe in ShouldBeComponent) {
                if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                    if (shouldBe === 'country') {
                        address[shouldBe] = component.short_name;
                    } else {
                        address[shouldBe] = component.long_name;
                    }
                }
            }
        });
        return address;
    }
}
