import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, Subject } from 'rxjs';
import { AlertService } from 'src/app/alerts/alert.service';
import { ClientsModel } from '../models/client.model';
import { ReportService } from 'src/app/reports/services/report.service';

@Component({
    selector: 'app-clientreportmodal',
    templateUrl: `./client.report.modal.component.html`,
})
export class ClientReportModalComponent implements OnInit {
    @Output() output = new EventEmitter<any>();
    @Input() model: ClientsModel;
    public frmClientreferral: FormGroup;
    reportTemplate: any;
    formData = new FormData();
    loading = false;
    submitted = false;
    startDate = new Date(1990, 0, 1);
    mode: string = '';
    private modalClose: Subject<any> = new Subject();

    constructor(
        private fb: FormBuilder,
        private alertService: AlertService,
        public dialog: MatDialog,
        private cdRef: ChangeDetectorRef,
        private reportService: ReportService
    ) {}

    ngOnInit(): void {
        this.loading = true;
        if (this.mode == 'Add') {
            // this.createReferralForm(this.data.model);
        }
        this.reportService.getCaseTypes().subscribe((res) => {
            this.reportTemplate = res;
        });
    }

    ngAfterViewChecked() {
        this.patchValues(this.model);
    }
    createReferralForm(model: ClientsModel): FormGroup<any> {
        return this.fb.group({
            clientFirstName: [model.clientFirstName, Validators.required],
            clienLastName: [model.clientLastName, Validators.required],
            clientNumber: [model.clientNumber, Validators.required],
            clientDOB: [model.clientDOB, Validators.required],
            reportTemplate: [model.clientReports, Validators.required],
        });
    }

    patchValues(model: ClientsModel) {
        this.frmClientreferral.patchValue({
            clientFirstName: model.clientFirstName,
            clienLastName: model.clientLastName,
            clientNumber: model.clientNumber,
            clientDOB: model.clientDOB,
            reportTemplate: model.clientReports,
        });
    }

    public isValidFile(file: File) {
        // enforce a "." being contained in the file name
        if (!file?.name.includes('.')) {
            return false;
        }

        // There should be a total of 2 strings, with a period in between in my case
        const names = file?.name.split('.');
        if (names.length !== 2) {
            return false;
        }

        const ext = names[1];
        if (['pdf', 'doc', 'docx'].includes(ext)) {
            return true;
        }

        return false;
    }

    onAddClientReport($event) {
        this.model = this.frmClientreferral.value;
        this.output.emit({ form: this.model });
        this.closeModal();
    }

    onModalClose(): Observable<any> {
        return this.modalClose.asObservable();
    }

    closeModal() {
        this.modalClose.next({
            form: this.model,
        });
        this.modalClose.complete();
    }
}
