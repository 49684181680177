import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  VERSION,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, Observer, Subject } from 'rxjs';
import { ClientreferralModel } from 'src/app/clientreferral/models/clientreferral.model';
import { DialogComponent } from 'src/app/common/dialog.component';
import { UserModel } from '../models/user.model';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styles: [
    `
      [nz-form] {
        max-width: 600px;
      }

      button {
        margin-left: 8px;
      }
    `,
  ],
})
export class UserComponent implements OnInit {
  validateForm: FormGroup;
  @Input() title: string = '';
  @Input() model: UserModel;
  @Input() files: any;
  @Output() output = new EventEmitter<any>();
  mode: string = '';
  formData = new FormData();
  private modalClose: Subject<any> = new Subject();
  deleteUser: boolean;
  editUser: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogComponent>,
    public dialog: MatDialog
  ) {}
  ngOnInit(): void {
    this.title = this.data.title;
    this.model = this.data.model;
    this.mode = this.data.mode;
    this.validateForm = new FormGroup({
      id: new FormControl(this.model.id),
      firstName: new FormControl(''),
      vendorName: new FormControl(''),
      lastName: new FormControl(''),
      role: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      password: new FormControl(''),
    });

    if (this.mode == 'Edit') {
      this.editUser = true;
      this.patchFormValues();
    }
    if (this.mode == 'Delete') {
      this.deleteUser = true;
      this.patchFormValues();
    }
  }

  patchFormValues() {
    this.validateForm.get('firstName').setValue(this.model.firstName);
    this.validateForm.get('vendorName').setValue(this.model.vendorName);
    this.validateForm.get('lastName').setValue(this.model.lastName);
    this.validateForm.get('role').setValue(this.model.role);
    this.validateForm.get('email').setValue(this.model.email);
  }
  cancelModal() {
    this.modalClose.complete();
    this.dialogRef.close();
  }
  submitForm(): void {
    console.log('submit', this.validateForm.value);
    this.onAddClientReferral();
  }
  DeleteUser(): void {
    console.log('submit', this.model.id);
    this.onAddClientReferral();
  }
  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(key)) {
        this.validateForm.controls[key].markAsPristine();
        this.validateForm.controls[key].updateValueAndValidity();
      }
    }
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.validateForm.controls.confirm.updateValueAndValidity()
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  userNameAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (control.value === 'JasonWood') {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 1000);
    });

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  onAddClientReferral() {
    this.model = this.validateForm.value;
    this.output.emit({ form: this.model, files: this.formData });
    this.closeModal();
  }

  onModalClose(): Observable<any> {
    return this.modalClose.asObservable();
  }

  closeModal() {
    //if(this.tempMode == 'Cancel'){return;}
    this.modalClose.next({
      form: this.model,
      files: this.formData,
      mode: this.mode,
    });
    this.modalClose.complete();
    this.dialogRef.close();
  }
}
