import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Alert, AlertOptions, AlertType } from '../login/models/alert';
import { ToastComponent } from '@syncfusion/ej2-angular-notifications';
import { ToastUtility } from '@syncfusion/ej2-notifications';

@Injectable({ providedIn: 'root' })
export class AlertService {
    options = {
        autoClose: true,
        keepAfterRouteChange: true,
    };
    public toastObj?: ToastComponent;
    private subject = new Subject<Alert>();
    private defaultId = 'default-alert';
    position = { X: 'Center', Y: 'Top' };
    enablePersistence = true;
    constructor(public dialog: MatDialog) {}
    // enable subscribing to alerts observable
    onAlert(id = this.defaultId): Observable<Alert> {
        return this.subject.asObservable().pipe(filter((x) => x && x.id === id));
    }

    // convenience methods
    success(message: string, options: AlertOptions = this.options) {
        this.toastObj = ToastUtility.show({
            title: 'Success',
            content: message,
            timeOut: 5000,
            width: '700',
            cssClass: 'e-toast-success font-awesome',
            position: this.position,
            icon: 'fa-regular fa-thumbs-up',
            showCloseButton: false,
            showProgressBar: true,
            enablePersistence: this.enablePersistence,
        }) as ToastComponent;
    }

    error(message: string, options: AlertOptions = this.options) {
        this.toastObj = ToastUtility.show({
            title: 'Error',
            content: message,
            timeOut: 6000,
            width: '1000',
            cssClass: 'e-toast-danger font-awesome',
            position: this.position,
            icon: 'fa-regular fa-circle-xmark',
            showCloseButton: false,
            showProgressBar: true,
            enablePersistence: this.enablePersistence,
        }) as ToastComponent;
    }

    info(message: string, options: AlertOptions = this.options) {
        this.toastObj = ToastUtility.show({
            title: 'Information',
            content: message,
            timeOut: 5000,
            width: '700',
            cssClass: 'e-toast-info font-awesome',
            icon: 'fas fa-solid fa-circle-info',
            position: this.position,
            showCloseButton: false,
            showProgressBar: true,
            enablePersistence: this.enablePersistence,
        }) as ToastComponent;
    }

    warn(message: string, options: AlertOptions = this.options) {
        this.toastObj = ToastUtility.show({
            title: 'Warning',
            content: message,
            timeOut: 5000,
            width: '700',
            cssClass: 'e-toast-warning font-awesome',
            icon: 'fas fa-solid fa-triangle-exclamation',
            position: this.position,
            showCloseButton: false,
            showProgressBar: true,
            enablePersistence: this.enablePersistence,
        }) as ToastComponent;
    }

    // main alert method
    alert(alert: Alert) {
        alert.id = alert.id || this.defaultId;
        this.subject.next(alert);
    }

    // clear alerts
    clear(id = this.defaultId) {
        this.subject.next(new Alert({ id }));
    }
}
