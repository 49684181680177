import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable, Output } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MessageService } from 'src/app/common/message.service';
import { environment } from 'src/environments/environment';
import { VendorModel } from '../models/assessment.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class AssessmentService {
  private subject = new Subject<any>();
  private changeIndexSubject = new Subject<any>();
  http: HttpClient;
  clientUrl: string = environment.apiUrl + '/Assessment';

  constructor(httpClient: HttpClient, private messageService: MessageService) {
    this.http = httpClient;
  }

  goToPage(index: string) {
    this.changeIndexSubject.next(index);
  }

  getIndexSubjectEvent(): Observable<any> {
    return this.changeIndexSubject.asObservable();
  }
  sendNextEvent(event: any) {
    this.subject.next(event);
  }

  getNextEvent(): Observable<any> {
    return this.subject.asObservable();
  }

  public getAssessment(): Observable<HttpResponse<VendorModel[]>> {
    return this.http.get<VendorModel[]>(this.clientUrl + '/GetAll', {
      observe: 'response',
    });
  }

  addNewAssessment(VendorModel: VendorModel): Observable<VendorModel[]> {
    return this.http.post<VendorModel[]>(
      this.clientUrl,
      VendorModel,
      httpOptions
    );
  }

  updateAssessment(VendorModel: VendorModel): Observable<VendorModel[]> {
    return this.http.post<VendorModel[]>(
      this.clientUrl,
      VendorModel,
      httpOptions
    );
  }
}
