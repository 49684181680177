export class ReportsTemplateModel {
    templateId: string = '';
    templateName?: string = '';
    templateStatus?: string = '';
    templateHtml?: string;
    fileContentJson?: string = '';
    createdDate?: string = '';
    modifiedDate?: string = '';
    relativePath?: string = '';
}
