import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { MessageService } from 'src/app/common/message.service';
import { environment } from 'src/environments/environment';
import { ClientreferralModel } from '../models/clientreferral.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class ClientreferralService {
    http: HttpClient;
    clientUrl: string = environment.apiUrl + '/Referalls';

    constructor(httpClient: HttpClient, private messageService: MessageService) {
        this.http = httpClient;
    }

    getClientCases(): Observable<HttpResponse<ClientreferralModel[]>> {
        return this.http
            .get<ClientreferralModel[]>(this.clientUrl + '/GetAll', {
                observe: 'response',
            })
            .pipe(
                catchError((err) => {
                    this.handleError<ClientreferralModel[]>('Updloading Form', err);
                    throw 'Error in getClientReferral. Details: ' + err;
                })
            );
    }
    public getClientreferral(): Observable<HttpResponse<ClientreferralModel[]>> {
        return this.http
            .get<ClientreferralModel[]>(this.clientUrl + '/GetAll', {
                observe: 'response',
            })
            .pipe(
                catchError((err) => {
                    this.handleError<ClientreferralModel[]>('Updloading Form', err);
                    throw 'Error in getClientReferral. Details: ' + err;
                })
            );
    }

    public uploadReferralAttachment(formData: any, reportId: any): Observable<any> {
        let options = {
            headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
        };
        return this.http.post<any>(this.clientUrl + `/Upload/?reportId=${reportId}`, formData).pipe(
            catchError((err) => {
                this.handleError<ClientreferralModel[]>('Updloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public getReferralAttachment(reportId: any): Observable<any> {
        return this.http.post<FormData>(this.clientUrl + `/GetAttachment?reportId=${reportId}`, {}).pipe(
            catchError((err) => {
                this.handleError<ClientreferralModel[]>('Downloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public addClientReferral(clientreferralModel: ClientreferralModel): Observable<ClientreferralModel[]> {
        return this.http.post<ClientreferralModel[]>(this.clientUrl, clientreferralModel, httpOptions).pipe(
            catchError((err) => {
                this.handleError<ClientreferralModel[]>('Add', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public updateClientReferral(clientreferralModel: ClientreferralModel): Observable<ClientreferralModel[]> {
        return this.http
            .post<ClientreferralModel[]>(
                this.clientUrl + '/UpdateClientReferral?id=' + clientreferralModel.clientId,
                clientreferralModel,
                httpOptions
            )
            .pipe(
                catchError((err) => {
                    this.handleError<ClientreferralModel[]>('Update', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    public deleteClientReferral(clientreferralModel: ClientreferralModel): Observable<ClientreferralModel[]> {
        return this.http
            .delete<ClientreferralModel[]>(
                `${this.clientUrl} /DeleteClientReferral?id=${clientreferralModel.clientId}`,
                httpOptions
            )
            .pipe(
                catchError((err) => {
                    this.handleError<ClientreferralModel[]>('Delete', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    getClientbyId(id: number): Observable<ClientreferralModel> {
        const url = `${this.clientUrl}/GetClientReferral?id=${id}`;
        return this.http.get<ClientreferralModel>(url).pipe(
            tap((_) => this.log(`fetched hero id=${id}`)),
            catchError(this.handleError<ClientreferralModel>(`getClient id=${id}`))
        );
    }

    private log(message: string) {
        this.messageService.add(`ClientReferralService: ${message}`);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
