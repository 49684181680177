import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { ClientreferralService } from './services/clientreferral.service';
import { ClientreferralModel } from './models/clientreferral.model';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, Observable, pipe, tap } from 'rxjs';
import { AlertService } from '../alerts/alert.service';
import { MatDialog } from '@angular/material/dialog';
import { BtnCellRenderer } from '../common/buttoncell.renderer';
import { DialogComponent } from '../common/dialog.component';
import { ClientReferralModalComponent } from './modal/clientreferral.component.modal';
import { ComponentPortal } from '@angular/cdk/portal';
import { AddCustomerComponent } from '../apptemp/apptemp.component';
import { ImageForm } from './models/imageform.model';
import { Router } from '@angular/router';
import { RouterLinkRendererComponent } from '../common/routerlink.renderer.component';

const rowDataGetter = function (params) {
    return params.data;
};
@Component({
    selector: 'app-clientreferral',
    templateUrl: './clientreferral.component.html',
    styleUrls: ['./clientreferral.component.css'],
})
export class ClientReferralComponent implements OnInit {
    private gridApi!: GridApi<ClientreferralModel>;
    public frmClientreferral: FormGroup;
    formData = new FormData();
    loading = false;
    submitted = false;
    startDate = new Date(1990, 0, 1);
    public clientReferralData: ClientreferralModel[];
    public clientReferralModel: ClientreferralModel;
    public gridColumnApi;
    public columnDefs: ColDef[];
    public defaultColDef: ColDef;
    public autoGroupColumnDef: ColDef;
    public groupDefaultExpanded;
    public rowSelection;
    portal: ComponentPortal<any>;
    constructor(
        public clientreferralService: ClientreferralService,
        private fb: FormBuilder,
        private alertService: AlertService,
        public dialog: MatDialog,
        public router: Router
    ) {
        this.columnDefs = [
            {
                field: 'clientName',
                flex: 1,
                sortable: true,
                cellRendererParams: {
                    inRouterLink: '/client',
                },
            },
            { field: 'clientName', sortable: true, flex: 1 },
            { field: 'clientNumber', sortable: true, flex: 1 },
            {
                field: 'clientDOB',
                sortable: true,
                flex: 1,
                cellRenderer: (data) => {
                    return data ? new Date(data.value).toLocaleDateString() : '';
                },
            },
            { field: 'dateSubmitted', sortable: true, flex: 1 },
            { field: 'reportStatus', sortable: true, flex: 1 },
        ];
        this.defaultColDef = {
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
        };
        this.autoGroupColumnDef = {
            minWidth: 200,
        };
        this.groupDefaultExpanded = 1;
        this.rowSelection = 'single';
    }

    ngOnInit(): void {
        this.loading = true;
    }

    onSelectionChanged() {
        var selectedRows = this.gridApi.getSelectedRows();
        document.querySelector('#selectedRows').innerHTML =
            selectedRows.length === 1 ? selectedRows[0].clientName : '';
    }

    addClientReferralModal() {
        this.openDialog(null, 'Add Client Referral', 'Add');
    }

    openDialog(model: ClientreferralModel, title: string, mode: string): void {
        this.portal = new ComponentPortal(ClientReferralModalComponent);

        this.frmClientreferral = this.createReferralForm();

        let dialogRef = this.dialog.open(AddCustomerComponent, {
            width: '640px',
            disableClose: true,
            data: {
                title: title,
                model: model,
                files: '',
                form: this.frmClientreferral,
                mode: mode,
            },
        });
        dialogRef?.componentInstance?.onModalClose().subscribe((result) => {
            if (result.mode == 'Add') {
                this.onAddClientReferral(result);
            } else if (result.mode == 'Update') {
                this.onUpdateClientReferral(result);
            }
        });
    }

    createReferralForm(): FormGroup<any> {
        return this.fb.group({
            clientName: ['', Validators.required],
            clientNumber: ['', Validators.required],
            clientDOB: ['', Validators.required],
            caseType: ['', Validators.required],
            dateSubmitted: [''],
            reportStatus: [''],
            referralNotes: [''],
        });
    }

    onUpdateClientReferral(result) {
        /* form code */
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        this.loading = true;
        this.clientreferralService
            .updateClientReferral(result.form)
            .pipe()
            .subscribe((data) => {
                this.clientReferralData = data['referralModels'];
                this.gridApi.setRowData(this.clientReferralData);
                let clientId = data['referralClientId'];
                this.loading = false;
                this.clientreferralService
                    .uploadReferralAttachment(result?.files, clientId)
                    .pipe()
                    .subscribe((data) => {
                        console.log(`Has Upload Completed: ${data}`);
                    }),
                    (error) => {
                        this.alertService.error(error);
                    };
            }),
            (error) => {
                this.alertService.error(error);
                this.loading = false;
            };
    }

    onAddClientReferral(result) {
        /* form code */
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        this.loading = true;
        this.clientreferralService
            .addClientReferral(result.form)
            .pipe()
            .subscribe((data) => {
                if (data) {
                    this.clientReferralData = data['referralModels'];
                    this.gridApi.setRowData(this.clientReferralData);
                    let clientId = data['referralClientId'];
                    this.loading = false;
                    this.clientreferralService
                        .uploadReferralAttachment(result?.files, clientId)
                        .pipe()
                        .subscribe((data) => {
                            console.log(`Has Upload Completed: ${data}`);
                        }),
                        (error) => {
                            this.alertService.error(error);
                        };
                }
            }),
            (error) => {
                this.alertService.error(error);
                this.loading = false;
            };
    }

    getClientReferralAttachment(clientReferralModel: ClientreferralModel): Observable<ImageForm> {
        return this.clientreferralService.getReferralAttachment(clientReferralModel.clientId).pipe(
            tap((data) => {
                return data;
            })
        );
    }

    onFilterTextBoxChanged() {
        this.gridApi.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
    }

    onPrintQuickFilterTexts() {
        this.gridApi.forEachNode(function (rowNode, index) {
            console.log('Row ' + index + ' quick filter text is ' + rowNode.quickFilterAggregateText);
        });
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.clientreferralService.getClientreferral().subscribe((data) => {
            this.clientReferralData = data['body'];
            const sortModel = [{ colId: 'dateSubmitted', sort: 'desc' }];
            this.sortGrid(params, 'dateSubmitted', 'desc');
            this.gridApi.setRowData(this.clientReferralData.slice(0, 10));
            params.api!.setRowData(this.clientReferralData.slice(0, 10));
            this.loading = false;
        });
    }

    sortGrid(event, field, sortDir) {
        const columnState = {
            // https://www.ag-grid.com/javascript-grid-column-state/#column-state-interface
            state: [
                {
                    colId: field,
                    sort: sortDir,
                },
            ],
        };
        event.columnApi.applyColumnState(columnState);
    }
}
