// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-form-field {
  width: 100%;
}
.modal-btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin-bottom: 5px;
}

.error-message {
  color: red;
  margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/reports/components/add-report/row-column-modal/row-column-modal.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,WAAW;EACX,yBAAyB;EACzB,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,cAAc;EACd,sBAAsB;EACtB,4BAA4B;EAC5B,yBAAyB;EAGzB,gBAAgB;EAChB,sBAAsB;EACtB,wEAAwE;EACxE,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,kBAAkB;AACpB","sourcesContent":["mat-form-field {\n  width: 100%;\n}\n.modal-btn {\n  display: flex;\n  justify-content: flex-end;\n  margin-bottom: 15px;\n}\n\n.form-control {\n  display: block;\n  width: 100%;\n  padding: 0.375rem 0.75rem;\n  font-size: 1rem;\n  font-weight: 400;\n  line-height: 1.5;\n  color: #212529;\n  background-color: #fff;\n  background-clip: padding-box;\n  border: 1px solid #ced4da;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  appearance: none;\n  border-radius: 0.25rem;\n  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n  margin-bottom: 5px;\n}\n\n.error-message {\n  color: red;\n  margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
