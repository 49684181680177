import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, Subject } from 'rxjs';
import { AlertService } from 'src/app/alerts/alert.service';
import { ClientreferralModel } from '../models/clientreferral.model';
import { ClientreferralService } from '../services/clientreferral.service';

@Component({
    selector: 'app-clientreferralmodal',
    templateUrl: `./clientreferral.component.modal.html`,
})
export class ClientReferralModalComponent implements OnInit {
    @Output() output = new EventEmitter<any>();
    @Input() model: ClientreferralModel;
    @Input() files: NgxFileDropEntry[] = [];
    public frmClientreferral: FormGroup;
    formData = new FormData();
    loading = false;
    submitted = false;
    startDate = new Date(1990, 0, 1);
    mode: string = '';
    public clientReferralData: ClientreferralModel[];
    private modalClose: Subject<any> = new Subject();

    constructor(
        public clientreferralService: ClientreferralService,
        private fb: FormBuilder,
        private alertService: AlertService,
        public dialog: MatDialog,
        private cdRef: ChangeDetectorRef
    ) {}

    ngOnInit(): void {
        this.loading = true;
        if (this.model) {
            this.mode = 'Update';

            if (this.model.image && this.files.length == 0) {
                this.files.push(this.model.image);
            }
        } else {
            this.mode = 'Add';
        }
        this.cdRef.detectChanges();
    }

    ngAfterViewChecked() {
        this.patchValues(this.model);
    }
    createReferralForm(model: ClientreferralModel): FormGroup<any> {
        return this.fb.group({
            clientName: [model.clientName, Validators.required],
            clientNumber: [model.clientNumber, Validators.required],
            clientDOB: [model.clientDOB, Validators.required],
            caseType: [model.caseType, Validators.required],
            dateSubmitted: [model.dateSubmitted],
            reportStatus: [model.reportStatus],
            referralNotes: [model.referralNotes],
        });
    }

    patchValues(model: ClientreferralModel) {
        this.frmClientreferral.patchValue({
            clientName: model.clientName,
            clientNumber: model.clientNumber,
            clientDOB: model.clientDOB,
            caseType: model.caseType,
            dateSubmitted: model.dateSubmitted,
            reportStatus: model.reportStatus,
            referralNotes: model.referralNotes,
        });
    }

    public dropped(files: NgxFileDropEntry[]) {
        if (files.length > 1) {
            alert('Only one file can be attached to this referral');
        }
        this.files = files;
        this.formData = new FormData();
        for (const droppedFile of files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    // Here you can access the real file
                    console.log(droppedFile.relativePath, file);
                    try {
                        // File Check
                        // Here you can have some custom function to check whatever parameters you want, return true if valid...
                        if (!this.isValidFile(file)) {
                            alert('Invalid file format');
                        }
                        // Process your file now
                        this.formData.append('referrals', file, droppedFile.relativePath);
                    } catch (err) {
                        this.files = null;
                    }
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log(droppedFile.relativePath, fileEntry);
            }
        }
    }
    public fileOver(event) {
        console.log(event);
    }

    public fileLeave(event) {
        console.log(event);
    }

    public isValidFile(file: File) {
        // enforce a "." being contained in the file name
        if (!file?.name.includes('.')) {
            return false;
        }

        // There should be a total of 2 strings, with a period in between in my case
        const names = file?.name.split('.');
        if (names.length !== 2) {
            return false;
        }

        const ext = names[1];
        if (['pdf', 'doc', 'docx'].includes(ext)) {
            return true;
        }

        return false;
    }

    onAddClientReferral($event) {
        this.model = this.frmClientreferral.value;
        this.output.emit({ form: this.model, files: this.formData });
        this.closeModal();
    }

    onModalClose(): Observable<any> {
        return this.modalClose.asObservable();
    }

    closeModal() {
        this.modalClose.next({
            form: this.model,
            files: this.formData,
            mode: this.mode,
        });
        this.modalClose.complete();
    }
}
