import { Guid } from 'guid-typescript';
import { CaseLoadModel } from 'src/app/cases/model/caseload.model';
import { ImageForm } from 'src/app/clientreferral/models/imageform.model';
import { Assessment, ReportsModel } from 'src/app/reportsScreen/models/reports.model';

export class ClientsModel {
    clientId: string = Guid.create().toString();
    staffId?: string | null;
    reportId?: string | null;
    clientFirstName?: string | null;
    clientMiddleName?: string | null;
    clientLastName?: string | null;
    preferredName?: string | null;
    clientNumber?: string = '';
    clientDOB?: string | null;
    streetAddress?: string | null;
    city?: string | null;
    state?: string | null;
    zip?: string | null;
    homePhone?: string | null;
    mobilePhone?: string | null;
    email?: string | null;
    isActive?: string = 'true';
    createdDate?: string = new Date().toString();
    modifiedDate?: string | null;
    clientLocation?: string | null;
    streetAddress2?: string | null;
    clientAddressCounty?: string | null;
    isClientCfrPartTwoEnabled?: string = 'false';
    clientContactsWorkPhone?: string | null;
    doNotMentionAgency?: string = 'false';
    doNotContactByPhoneCall?: string = 'false';
    doNotContactBySms?: string = 'false';
    doNotContactByEmail?: string = 'false';
    clientEmergencyContactFullName?: string | null;
    clientEmergencyContactPhoneNumber?: string | null;
    clientEmergencyContactRelationship?: string | null;
    clientNotes?: string | null;
    clientHealthInsuranceType?: string | null;
    clientInsuranceProvider?: string | null;
    clientInsuranceMemberNumber?: string | null;
    clientInsuranceGroupNumber?: string | null;
    clientInsurancePhoneNumber?: string | null;
    clientDemographicInfoSsn?: string | null;
    clientDemographicInfoGender?: string | null;
    clientDemographicInfoGenderIdentity?: string | null;
    clientOtherGenderIdentity?: string | null;
    clientDemographicInfoPronouns?: string | null;
    clientDemographicInfoRace?: string | null;
    clientOtherRace?: string | null;
    clientDemographicInfoMaritalStatus?: string | null;
    clientDemographicInfoReligion?: string | null;
    clientDemographicInfoOtherReligion?: string | null;
    clientDemographicInfoPreferredLanguage?: string | null;
    clientDemographicInfoOtherPreferredLanguage?: string | null;
    clientDemographicInfoEmployment?: string | null;
    clientDemographicInfoEmployer?: string | null;
    reportStatus?: string | null;
    reportTemplateName?: string | null;
    reportName?: string = '';
    openCases?: string = '';
    nextAppointment?: string = '';
    profileImageString?: string = '';
    profileImage?: ImageForm;
    assessment?: Assessment;
    clientCases?: CaseLoadModel[] | null;
    clientReports?: ReportsModel[] | null;
}
