import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { MessageService } from 'src/app/common/message.service';
import { environment } from 'src/environments/environment';
import { ImageForm } from '../clientreferral/models/imageform.model';
import { ReportsModel } from '../reportsScreen/models/reports.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
  providedIn: 'root',
})
export class FileAttachmentService {
  http: HttpClient;
  clientUrl: string = environment.apiUrl + '/Referalls';

  constructor(httpClient: HttpClient, private messageService: MessageService) {
    this.http = httpClient;
  }

  public uploadAttachment(formData: any, clientId: any): Observable<any> {
    let options = {
      headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
    };
    return this.http
      .post<any>(this.clientUrl + `/Upload/?id=${clientId}`, formData)
      .pipe(
        catchError((err) => {
          this.handleError<any[]>('Updloading Form', err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  public getAttachment(clientId: any): Observable<any> {
    return this.http
      .post<FormData>(this.clientUrl + `/GetAttachment?id=${clientId}`, {})
      .pipe(
        catchError((err) => {
          this.handleError<any[]>('Downloading Form', err);
          throw 'error in source. Details: ' + err;
        })
      );
  }

  downloadForm(referralForm: ImageForm): Observable<any> {
    return this.http
      .post(this.clientUrl + '/DownloadFile', referralForm, {
        responseType: 'blob',
      })
      .pipe(
        catchError((err) => {
          this.handleError<ReportsModel[]>('DownloadFile', err);
          throw 'error in Downloading File. Details: ' + err;
        })
      );
  }
  private log(message: string) {
    this.messageService.add(`AttachmentService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
