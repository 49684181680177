import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ContentChild,
    EventEmitter,
    Inject,
    Input,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    debounceTime,
    delay,
    finalize,
    map,
    merge,
    Observable,
    scan,
    startWith,
    Subject,
    Subscription,
    timer,
} from 'rxjs';

import { DialogComponent } from 'src/app/common/dialog.component';
import { AlertService } from 'src/app/alerts/alert.service';
import { VendorModel } from '../models/assessment.model';
import { AssessmentService } from '../services/assessment.service';
import { AssessmentCompanyFormComponent } from './modalforms/assessment.companyinfoform.component';
import { AssessmentJobsFormComponent } from './modalforms/assessment.jobsform.component';
import { AssessmentLocationFormComponent } from './modalforms/assessment.locationform.component';
import { AssessmentReviewSubmitComponent } from './modalforms/assessment.reviewsubmitform.component';
import { AssessmentSubmitComponent } from './modalforms/assessment.submit.component';

interface SyncStep {
    id: number;
    title: string;
    description: string;
    icon: string;
    async: false;
    percentage: null;
    disabled: boolean;
}

interface AsyncStep {
    id: number;
    title: string;
    description: string;
    icon: string;
    async: true;
    percentage: number;
    disabled: boolean;
}

type Step = SyncStep | AsyncStep;

function mockAsyncStep(): Observable<number> {
    const subStep1 = timer(600).pipe(map(() => 25));
    const subStep2 = subStep1.pipe(delay(600));
    const subStep3 = subStep2.pipe(delay(600));
    const subStep4 = subStep3.pipe(delay(600));
    return merge(subStep1, subStep2, subStep3, subStep4).pipe(scan((a, b) => a + b));
}

@Component({
    selector: 'app-assessment-modal',
    templateUrl: './assessmentmodal.component.html',
    styleUrls: ['./assessmentmodal.component.css'],
})
export class AssessmentModalComponent implements OnInit {
    validateForm: FormGroup;
    @Input() title: string = '';
    @Input() model: VendorModel;
    @Input() files: any;
    @Output() output = new EventEmitter<any>();
    form: FormGroup = new FormGroup({});
    @ViewChild(FormGroupDirective, { static: true }) _form: FormGroup;
    ref: ComponentRef<any>;
    isModelComplete: boolean = false;
    componentPortal: ComponentPortal<any>;
    mode: string = '';
    modeLanguage: string = '';
    formData = new FormData();
    current = 0;
    public index = 'First-content';
    processing = false;
    private modalClose: Subject<any> = new Subject();
    indexChangeSubscription: Subscription;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DialogComponent>,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private service: AssessmentService,
        private alertService: AlertService
    ) {}

    ngOnInit(): void {
        this.title = this.data.title;
        this.model = this.data.model;
        this.mode = this.data.mode;
        this.model.mode = this.mode;
        this.modeLanguage = this.mode == 'Update' ? 'updating' : 'creating';
        this.componentPortal = new ComponentPortal(AssessmentCompanyFormComponent);
    }

    onFormValidation(validity: boolean) {
        switch (validity) {
            case true:
                this.isModelComplete = true;
                break;
            case false:
                // do 'form invalid' action
                this.isModelComplete = false;

                break;
        }
    }
    steps: Step[] = [
        {
            id: 1,
            title: `Company Info`,
            description: `This step is synchronous.`,
            icon: 'user',
            async: false,
            percentage: null,
            disabled: false,
        },
        {
            id: 2,
            title: `Site Info`,
            description: `This step is asynchronous.`,
            icon: 'environment',
            async: false,
            percentage: null,
            disabled: false,
        },
        {
            id: 3,
            title: `Job Info`,
            description: `This step is asynchronous.`,
            icon: 'team',
            async: false,
            percentage: null,
            disabled: false,
        },
        {
            id: 4,
            title: `Review`,
            description: `This step is asynchronous.`,
            icon: 'file-search',
            async: true,
            percentage: 0,
            disabled: false,
        },
        {
            id: 5,
            title: `Submit`,
            description: `This step is asynchronous.`,
            icon: 'safety-certificate',
            async: false,
            percentage: null,
            disabled: true,
        },
    ];

    returnModelComplete() {
        //Only want to enable review click if the model is complete and the user edits the model any way
        //This will only be true if the user hits the edit button from the review screen
        // the user can do their edits then immediately go back to the review screen by clicking the review step
        return this.isModelComplete;
    }

    pre(): void {
        this.current -= 1;
        this.changeContent();
    }

    next(): void {
        this.current += 1;
        this.service.sendNextEvent(this.ref.instance.model);
        this.model = this.ref.instance.model;
        this.changeContent();
    }

    goToPage(index): void {
        this.current = index;
        this.isModelComplete = true;
        this.changeContent();
    }
    submit(): void {
        this.model = this.ref.instance?.model;
        this.submitForm();
    }

    close() {
        this.closeModal();
    }

    onIndexChange(event: number): void {
        this.current = event;
        this.changeContent();
    }

    trackById(_: number, item: Step): number {
        return item.id;
    }

    changeContent(): void {
        switch (this.current) {
            case 0: {
                this.index = 'First-content';
                this.componentPortal = new ComponentPortal(AssessmentCompanyFormComponent);
                this.cdr.detectChanges();
                break;
            }
            case 1: {
                this.index = 'Second-content';
                this.componentPortal = new ComponentPortal(AssessmentLocationFormComponent);
                this.cdr.detectChanges();
                break;
            }
            case 2: {
                this.index = 'third-content';
                this.componentPortal = new ComponentPortal(AssessmentJobsFormComponent);
                this.cdr.detectChanges();
                break;
            }
            case 3: {
                this.index = 'fourth-content';
                this.componentPortal = new ComponentPortal(AssessmentReviewSubmitComponent);
                this.cdr.detectChanges();
                break;
            }
            case 4: {
                this.index = 'fifth-content';
                this.componentPortal = new ComponentPortal(AssessmentSubmitComponent);
                this.cdr.detectChanges();
                break;
            }
            default: {
                this.index = 'error';
            }
        }
    }
    loadingAndStep(): void {
        if (this.current < this.steps.length) {
            const step = this.steps[this.current];
            if (step.async) {
                this.processing = true;
                mockAsyncStep()
                    .pipe(
                        finalize(() => {
                            step.percentage = 0;
                            this.processing = false;
                            this.current += 1;
                            this.changeContent();
                        })
                    )
                    .subscribe((p) => {
                        step.percentage = p;
                    });
            } else {
                this.current += 1;
                this.changeContent();
            }
        }
    }
    portalAttached(_ref: CdkPortalOutletAttachedRef) {
        this.ref = _ref as ComponentRef<any>;
        this.ref.instance.model = this.model;
        if (this.current == 4) {
            this.service.sendNextEvent(this.ref.instance.model);
        }
        this.ref.instance.assessmentOutput.subscribe((d) => {
            this.goToPage(d);
        });
        setTimeout(() => {
            const form = this.ref.instance?.validateForm;
            if (form)
                form.statusChanges
                    .pipe(
                        startWith(form.status),
                        map((x) => (x == 'VALID' ? true : false))
                    )
                    .subscribe((result: any) => {
                        this.onFormValidation(result);
                    });
        });
    }

    submitForm(): void {
        if (this.model.mode == 'Add') {
            this.service
                .addNewAssessment(this.model)
                .pipe()
                .subscribe((data) => {
                    this.service.sendNextEvent(data);
                    this.loadingAndStep();
                }),
                (error) => {
                    this.service.sendNextEvent(error);
                };
        } else {
            this.service
                .updateAssessment(this.model)
                .pipe()
                .subscribe((data) => {
                    this.service.sendNextEvent(data);
                    this.loadingAndStep();
                }),
                (error) => {
                    this.service.sendNextEvent(error);
                };
        }
    }

    onAddClientReferral() {
        this.model = this.ref.instance?.validateForm?.value;
        this.output.emit({ form: this.model, files: this.formData });
        this.closeModal();
    }

    onModalClose(): Observable<any> {
        return this.modalClose.asObservable();
    }

    cancelModal() {
        this.modalClose.complete();
        this.dialogRef.close();
    }

    closeModal() {
        //if(this.tempMode == 'Cancel'){return;}
        this.modalClose.next({
            form: this.model,
            files: this.formData,
            mode: this.mode,
        });
        this.modalClose.complete();
        this.dialogRef.close();
    }
}
