// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            [nz-form] {
                max-width: 600px;
            }

            button {
                margin-left: 8px;
            }
            .dynamic-delete-button {
                cursor: pointer;
                position: relative;
                top: 4px;
                font-size: 14px;
                transition: all 0.3s;
                padding-left: 20px;
                color: red;
            }
            .dynamic-delete-button:hover {
                color: #777;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/assessment/modal/modalforms/assessment.locationform.component.ts"],"names":[],"mappings":";YACY;gBACI,gBAAgB;YACpB;;YAEA;gBACI,gBAAgB;YACpB;YACA;gBACI,eAAe;gBACf,kBAAkB;gBAClB,QAAQ;gBACR,eAAe;gBACf,oBAAoB;gBACpB,kBAAkB;gBAClB,UAAU;YACd;YACA;gBACI,WAAW;YACf","sourcesContent":["\n            [nz-form] {\n                max-width: 600px;\n            }\n\n            button {\n                margin-left: 8px;\n            }\n            .dynamic-delete-button {\n                cursor: pointer;\n                position: relative;\n                top: 4px;\n                font-size: 14px;\n                transition: all 0.3s;\n                padding-left: 20px;\n                color: red;\n            }\n            .dynamic-delete-button:hover {\n                color: #777;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
