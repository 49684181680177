import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportGridComponent } from '../components/reports.grid/report.grid.component';
import { ReportComponent } from '../components/reports/report.component';
import { PDFViewerComponent } from '../components/report.preview/pdf.viewer.component';

const routes: Routes = [
    { path: 'report-grid', component: ReportGridComponent },
    { path: 'report', component: ReportComponent },
    { path: 'pdfViewer', component: PDFViewerComponent },
    { path: '', component: ReportGridComponent },
];

@NgModule({
    imports: [RouterModule.forChild(routes), CommonModule],
    exports: [RouterModule],
})
export class ReportRoutingModule {}
