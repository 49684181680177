import { CdkPortal, CdkPortalOutlet, ComponentPortal } from '@angular/cdk/portal';
import {
    OnInit,
    Component,
    ChangeDetectorRef,
    ViewChild,
    AfterViewInit,
    AfterContentInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { PageSettingsModel } from '@syncfusion/ej2-angular-grids';
import { DashboardLayoutComponent, PanelModel } from '@syncfusion/ej2-angular-layouts';
import { Subject } from 'rxjs';
import { ClientCasesComponent } from 'src/app/cases/components/client.cases.component';
import { ClientCaseService } from 'src/app/cases/services/client.cases.service';
import { ClientService } from 'src/app/client/services/client.services';
import { UtilityService } from 'src/app/common/utility';
import { User } from 'src/app/login/models/user';
import { AccountService } from 'src/app/login/service/account.service';
import { AuthService } from 'src/app/login/service/auth.service';
import { TaskModel } from 'src/app/tasks/model/task.model';
import { TaskService } from 'src/app/tasks/services/task.service';
import * as moment from 'moment';
import { ClientsModel } from 'src/app/client/models/client.model';
import { EventSettingsModel, View } from '@syncfusion/ej2-angular-schedule';
import { DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
var _ = require('lodash');
declare function showHeader(): any;

@Component({
    selector: 'app-dashboard',
    styleUrls: ['./dashboard.component.css'],
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
    @ViewChild('api_dashboard') public dashboardObject: DashboardLayoutComponent;
    public mappedCasesGridData: any[];
    public mappedTaskGridData: any[];
    clientGridData: any[];
    taskGridData: TaskModel[];
    public pageSettings: PageSettingsModel;
    public loadingIndicator?: any;
    staffClientData: any = [];
    public toolbar: string[];
    public sortSettings;
    public cellSpacing: number[] = [10, 10];
    mobileArray = [];
    effect = 'scrollx';
    Role: string;
    refreshcases: boolean = false;
    refreshtask1: boolean = false;
    refreshtask2: boolean = false;
    get staffValue() {
        return this.staff;
    }

    set staffValue(staff) {
        this.staff = staff;
    }
    private staff: string = 'Staff Member';
    viewDate: Date = new Date();
    refresh = new Subject<void>();
    user: User;

    public readonly = true;
    public currentView: View = 'Month';
    private calendarId = 'en.usa%23holiday@group.v.calendar.google.com';
    private publicKey = 'AIzaSyBgbX_tgmVanBP4yafDPPXxWr70sjbKAXM';
    private dataManger: DataManager = new DataManager({
        url:
            'https://www.googleapis.com/calendar/v3/calendars/' +
            this.calendarId +
            '/events?key=' +
            this.publicKey,
        adaptor: new WebApiAdaptor(),
        crossDomain: true,
    });
    public eventSettings: EventSettingsModel = { dataSource: this.dataManger };

    constructor(
        private authService: AuthService,
        private taskService: TaskService,
        private accountService: AccountService,
        private clientService: ClientService,
        private utilityService: UtilityService,
        private router: Router,
        private cdr: ChangeDetectorRef
    ) {
        this.accountService.setLoading(true);
        this.Role = authService.getRole();
        let tempuser = this.authService.getUser();
        if (tempuser != '') {
            this.user = <User>JSON.parse(tempuser);
            this.staffValue = this.user?.vendorName || `${this.user?.firstName} ${this.user?.lastName}`;
        }
        this.Role = this.authService.getRole();
    }

    ngOnInit(): void {
        showHeader();
        this.accountService.setLoading(false);
        this.refreshCasesGrid();
        this.refreshTaskGrid(1);
    }

    refreshCasesGrid() {
        this.refreshcases = true;
        this.clientService.getAllClients().subscribe((data) => {
            this.clientGridData = data['body'];
            let cloned: ClientsModel[] = _.cloneDeep(this.clientGridData);
            let staffFilter = this.utilityService.getStaffFilter(cloned, this.authService.getStaffId());
            let openFilter = this.utilityService.getOpenFilter(staffFilter);
            console.log('Open Filde', openFilter);
            let model: ClientsModel[] = this.utilityService.getFinalOpenFilter(openFilter);
            this.mappedCasesGridData = model.map((res) => ({
                clientFirstName: `${res.clientFirstName} ${res.clientLastName}`,
                clientNumber: res?.clientNumber,
                openCases: res?.clientCases?.length,
                caseStatus: res?.isActive == 'Y' ? 'Active' : 'Inactive',
                createdDate: res?.createdDate,
            }));
            this.refreshcases = false;
        });
    }

    refreshTaskGrid(num: number) {
        if (num == 1) {
            this.refreshtask1 = true;
        } else {
            this.refreshtask2 = true;
        }
        this.taskService.getTasksByStaffId(this.authService.getStaffId()).subscribe((data) => {
            this.taskGridData = data; //data['body'];
            let cloned: TaskModel[] = _.cloneDeep(this.taskGridData);
            this.mappedTaskGridData = cloned.map((item) => ({
                isOverdue: this.utilityService.isTaskOverDue(item.dueDate),
                taskDaysRemaining: this.utilityService.ToTitleCase(
                    this.utilityService.CalculateTaskDays(item.dueDate)
                ),
                isTaskComingDue: this.utilityService.isTaskComingDue(item.dueDate),
                taskTitle: item.taskTitle,
                taskSummary: item.taskSummary,
                priority: item.priority,
                deadlineDate: moment(item.dueDate).format('MM/DD/YYYY'),
                modifiedDate:
                    item.modifiedDate != ''
                        ? moment(item.modifiedDate).format('MM/DD/YYYY')
                        : moment(item.createdDate).format('MM/DD/YYYY'),
            }));
            this.refreshtask1 = false;
            this.refreshtask2 = false;
        });
    }

    onClientClick(event, data) {
        this.router.navigate(['/client'], {
            queryParams: { client_id: data.clientId },
        });
    }

    onTaskClick(event, data) {
        this.router.navigate(['/taskDetail'], {
            queryParams: { task_id: data.taskId },
        });
    }

    queryCellInfo(args: any): void {
        if (args.column.field == 'taskDaysRemaining') {
            if (args.data['isOverdue'] == true) {
                args.cell.classList.add('task-red');
            } else if (args.data['isTaskComingDue'] == true) {
                args.cell.classList.add('task-purple');
            } else {
                args.cell.classList.add('task-green');
            }
        }
        if (args.column.field == 'priority') {
            if (args.data.priority == 'Critical') {
                args.cell.classList.add('task-red');
            } else if (args.data.priority == 'High') {
                args.cell.classList.add('task-orange');
            } else if (args.data.priority == 'Medium') {
                args.cell.classList.add('task-purple');
            } else if (args.data.priority == 'Low') {
                args.cell.classList.add('task-green');
            }
        }
    }

    public onDataBinding(e: Record<string, any>): void {
        const items: Record<string, any>[] = (e.result as Record<string, Record<string, any>[]>).items;
        const scheduleData: Record<string, any>[] = [];
        if (items.length > 0) {
            for (const event of items) {
                let when: string = event.start.dateTime as string;
                let start: string = event.start.dateTime as string;
                let end: string = event.end.dateTime as string;
                if (!when) {
                    when = event.start.date as string;
                    start = event.start.date as string;
                    end = event.end.date as string;
                }
                scheduleData.push({
                    Id: event.id,
                    Subject: event.summary,
                    StartTime: new Date(start),
                    EndTime: new Date(end),
                    IsAllDay: !event.start.dateTime,
                });
            }
        }
        e.result = scheduleData;
    }
}
