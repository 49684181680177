import { Component } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ClientreferralService } from './clientreferral/services/clientreferral.service';
import { JobpostService } from './jobpost/services/jobpost.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [JobpostService, ClientreferralService],
})
export class AppComponent {
  title = 'Cornerstone';

  showHead: boolean = false;

  ngOnInit() {}

  constructor(private router: Router) {
    // on route change to '/login', set the variable showHead to false
    router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event['url'] == '/account/login' || event['url'] == '/dashboard') {
          this.showHead = false;
        } else {
          this.showHead = true;
        }
      }
    });
  }
}
