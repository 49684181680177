import { ChangeDetectorRef, Component, ComponentRef, ElementRef, Input, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlertService } from 'src/app/alerts/alert.service';
import { ClientService } from '../../services/client.services';
import { ClientsModel } from '../../models/client.model';
import { ViewChild } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription, forkJoin, switchMap, take } from 'rxjs';
import { FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { CustomValidators } from 'src/app/helpers/customvalidators';
import { AuthService } from 'src/app/login/service/auth.service';
import { DropDownList, MultiSelectComponent } from '@syncfusion/ej2-angular-dropdowns';
import { StaffModel } from 'src/app/staff/model/staff.model';
import { StaffService } from 'src/app/staff/services/staff.service';
import { isTemplateRef } from 'ng-zorro-antd/core/util';
import { Query, DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
import { NzSelectModule, NzSelectPlacementType } from 'ng-zorro-antd/select';
import { ChangeDetectionStrategy } from '@angular/compiler';
import * as moment from 'moment';
import { __FORCE_MODULE_DETECTION } from 'ag-grid-community';
import { UtilityService } from 'src/app/common/utility';
import { Guid } from 'guid-typescript';
import { stateList } from '../../../common/json/stateList';
import { languages } from '../../../common/json/languageList';
import type { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import { maskitoDateOptionsGenerator } from '@maskito/kit';
import { ReportService } from 'src/app/reports/services/report.service';
import { Assessment, ClientInformationModel, ReportsModel } from 'src/app/reportsScreen/models/reports.model';
import {
    PageSettingsModel,
    EditSettingsModel,
    ToolbarItems,
    IEditCell,
    actionBegin,
    GridComponent,
    QueryCellInfoEventArgs,
    CellEditArgs,
    CommandModel,
    CommandClickEventArgs,
} from '@syncfusion/ej2-angular-grids';
import { ClientReportModalComponent } from '../../modal/client.report.modal.component';
import { ReportModalComponent } from 'src/app/reportsScreen/modal.base/reportmodal.base.component';
import { ReportComponent } from 'src/app/reports/components/reports/report.component';
import { ReportGridComponent } from 'src/app/reports/components/reports.grid/report.grid.component';
import { ComponentPortal, CdkPortalOutletAttachedRef } from '@angular/cdk/portal';
import { MouseEventArgs } from '@syncfusion/ej2-base';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete';
import { NzResultComponent } from 'ng-zorro-antd/result';
var _ = require('lodash');

@Component({
    selector: 'app-client',
    templateUrl: './client.component.html',
    styleUrls: ['./client.component.css'],
})
export class ClientComponent implements OnInit {
    @ViewChild(ComponentPortal, { static: true }) componentPortal: ComponentPortal<any>;
    ref: ComponentRef<any>;
    @ViewChild('select') select: NzSelectModule;
    age: any;
    @ViewChild('deactivateBox') dbox: ElementRef;
    @ViewChild('grid')
    public grid?: GridComponent;
    @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
    options: NgxGpAutocompleteOptions = {
        componentRestrictions: { country: ['US'] },
        types: ['geocode'],
    };
    @Input() max: any;
    dateFormat = 'MM/dd/yyyy';
    stateList: any = stateList;
    languageList: any = languages;
    public disabledDate = (current: Date): boolean => current > new Date();
    startAt: Date;
    today = new Date();
    clientDOB: any;
    validateForm!: FormGroup;
    formGroupDirective: FormGroupDirective;
    public clientId: any;
    public addEdit: string = 'Add New';
    public client: string = '';
    private _subscription: Subscription;
    isEnable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isEnable: boolean;
    fullReportList: ReportsModel[];
    private unsubscribe: Subscription[] = [];
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLoading: boolean;
    model: ClientsModel = new ClientsModel();
    clientDataModel: ClientsModel = new ClientsModel();
    formData = new FormData();
    staffList: any;
    assignedStaff: any;
    staffSelected = null;
    clientName: string = 'Assign Staff';
    isDisabled = false;
    public fields: Object = { text: 'staffName', value: 'staffId' };
    public waterMark: string = 'Assign Staff';
    placement: NzSelectPlacementType = 'bottomLeft';
    checked = false;
    clientStatus: string = 'Deactivate Client';
    public box: string = 'Box';
    refreshcases: boolean = false;
    public mappedReportData: ReportsModel[] = [];
    public pageSettings: PageSettingsModel;
    public loadingIndicator?: any;
    public sortSettings;
    public cellSpacing: number[] = [10, 10];
    indeterminate = true;
    statusParams?: IEditCell;
    staffParams?: IEditCell;
    public staffElem?: HTMLElement;
    public staffObj?: DropDownList;
    public isDropdown = false;
    reportStatusValues: [];
    public editSettings?: EditSettingsModel;
    public toolbar?: ToolbarItems[];
    public commands?: CommandModel[];
    template;
    maskOptions: MaskitoOptions = maskitoDateOptionsGenerator({
        mode: 'mm/dd/yyyy',
        separator: '/',
        max: new Date(),
    });
    readonly elementPredicate: MaskitoElementPredicateAsync = async (host) =>
        new Promise((resolve) =>
            // next macro task
            setTimeout(() => resolve(host.querySelector<HTMLInputElement>('input')!))
        );

    constructor(
        public clientService: ClientService,
        private fb: FormBuilder,
        private alertService: AlertService,
        public dialog: MatDialog,
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private staffService: StaffService,
        private cdr: ChangeDetectorRef,
        private utility: UtilityService,
        private reportService: ReportService
    ) {
        this.startAt = this.utility.getDate18Years();
        this.isDisabled = this.authService.getRole().toUpperCase() !== 'ADMIN';

        const enableSubscr = this.isEnable$.asObservable().subscribe((res) => (this.isEnable = res));
        this.unsubscribe.push(enableSubscr);

        const loadingSubscr = this.isLoading$.asObservable().subscribe((res) => (this.isLoading = res));
        this.unsubscribe.push(loadingSubscr);

        this.createForm();
        this.route.queryParams.subscribe((params) => {
            this.clientId = params['client_id'] ?? '';
            if (this.clientId) {
                this.addEdit = 'Edit';
                this.clientService.getClientById(this.clientId).subscribe((data) => {
                    this.clientDataModel = data;
                    console.log('clientDataModel', this.clientDataModel);
                    this.patchForm(data);
                    this.staffService.getAllStaff().subscribe((result) => {
                        this.staffList = result['body']
                            .filter((x) => !x.vendorName)
                            .map((r) => ({
                                staffName: `${r?.firstName} ${r?.lastName}`,
                                staffId: r?.staffId,
                            }));

                        let staffFound = this.staffList?.find(
                            (x) => x.staffId == this.clientDataModel?.staffId
                        );
                        if (staffFound) {
                            console.log('stafffound', staffFound);
                            this.assignedStaff = staffFound['staffName'];
                        }
                    });
                });
                this.setReportGridByClientId(this.clientId);
            } else if (this.addEdit == 'Add New') {
                this.clientId = 'Add New';
            }
        });
        this._subscription = this.validateForm.statusChanges?.subscribe((result: any) => {
            let val = result === 'VALID' ? true : false;
            this.onFormValidation(val);
        });
        this.reportStatusValues = this.utility.getReportStatusValues();
    }

    setReportGridByClientId(clientId: any) {
        this.reportService.getAllReportsbyClientId(clientId).subscribe((data) => {
            this.fullReportList = _.cloneDeep(data);
            this.mappedReportData = data
                ?.filter((x) => x?.reportStatus == 'Active')
                .map((r) => ({
                    staffName: `${r?.firstName} ${r?.lastName}`,
                    staffId: r?.staffId,
                    reportId: r?.reportId,
                    reportNumber: r?.reportNumber,
                    reportName: r?.reportName,
                    reportStatus: r?.reportStatus,
                    modifiedDateSubmitted: r?.modifiedDateSubmitted || r?.dateSubmitted,
                    clientInfoModel: r?.clientInfoModel,
                }));
        });
    }

    createForm() {
        this.validateForm = new FormGroup({
            clientId: new FormControl(''),
            clientFirstName: new FormControl('', Validators.required),
            clientLastName: new FormControl('', Validators.required),
            clientMiddleName: new FormControl(''),
            homePhone: new FormControl(
                '',
                Validators.compose([
                    CustomValidators.patternValidator(/[- +()0-9]{10,12}/, {
                        hasNumNumber: true,
                    }),
                ])
            ),
            mobilePhone: new FormControl('', Validators.required),
            email: new FormControl(null, Validators.compose([Validators.email, Validators.required])),
            preferredName: new FormControl(''),
            clientLocation: new FormControl(''),
            clientDefaultServiceType: new FormControl(''),
            streetAddress: new FormControl(''),
            streetAddress2: new FormControl(''),
            city: new FormControl(''),
            state: new FormControl(''),
            zip: new FormControl(''),
            clientAddressCounty: new FormControl(''),
            isClientCfrPartTwoEnabled: new FormControl(''),
            clientAnnualFamilyIncome: new FormControl(''),
            clientPaymentAmount: new FormControl(''),
            clientDOB: new FormControl('', Validators.required),
            clientDemographicInfoSsn: new FormControl(''),
            clientDemographicInfoGender: new FormControl(''),
            clientDemographicInfoGenderIdentity: new FormControl(''),
            clientOtherGenderIdentity: new FormControl(''),
            clientDemographicInfoPronouns: new FormControl(''),
            clientDemographicInfoSexualOrientation: new FormControl(''),
            clientOtherSexualOrientation: new FormControl(''),
            clientDemographicInfoRace: new FormControl(''),
            clientContactsWorkPhone: new FormControl(''),
            doNotMentionAgency: new FormControl(''),
            doNotContactByPhoneCall: new FormControl(''),
            doNotContactBySms: new FormControl(''),
            doNotContactByEmail: new FormControl(''),
            clientEmergencyContactFullName: new FormControl(''),
            clientEmergencyContactPhoneNumber: new FormControl(''),
            clientEmergencyContactRelationship: new FormControl(''),
            clientNotes: new FormControl(''),
            clientFlags: new FormControl(''),
            clientHealthInsurance: new FormControl(''),
            clientReferralId: new FormControl(''),
            clientNumber: new FormControl('', Validators.required),
            clientOtherRace: new FormControl(''),
            clientDemographicInfoMaritalStatus: new FormControl(''),
            clientDemographicInfoReligion: new FormControl(''),
            clientDemographicInfoOtherReligion: new FormControl(''),
            clientDemographicInfoPreferredLanguage: new FormControl(''),
            clientDemographicInfoOtherPreferredLanguage: new FormControl(''),
            clientDemographicInfoEmployment: new FormControl(''),
            clientDemographicInfoEmployer: new FormControl(''),
            clientHealthInsuranceType: new FormControl(''),
            clientInsuranceProvider: new FormControl(''),
            clientInsuranceMemberNumber: new FormControl(''),
            clientInsuranceGroupNumber: new FormControl(''),
            clientInsurancePhoneNumber: new FormControl(''),
            clientFullName: new FormControl(''),
            clientDateOfBirth: new FormControl(''),
            clientRelationship: new FormControl(''),
            clientEmail: new FormControl(''),
            clientPhoneNumber: new FormControl(''),
            isActive: new FormControl('Y'),
        });
    }

    ngOnInit(): void {
        this.editSettings = { allowEditing: true, allowAdding: false, allowDeleting: false, mode: 'Normal' };
        this.toolbar = ['Update', 'Cancel'];
        this.commands = [
            {
                type: 'Edit',
                buttonOption: { cssClass: 'font-awesome-primary', iconCss: 'fas fa-pencil' },
            },
            {
                title: 'Deactivate this Report',
                type: 'Delete',
                buttonOption: { cssClass: 'font-awesome-danger', iconCss: 'fas fa-file-zipper' },
            },
        ];
        this.statusParams = {
            params: {
                allowFiltering: true,
                dataSource: this.reportStatusValues,
                fields: { text: 'reportStatus', value: 'reportStatus' },
                query: new Query(),
                cssClass: '.dropdown',
                actionComplete: () => false,
            },
        };

        this.staffParams = {
            create: () => {
                //create function is used to create the element at time of initialization
                this.staffElem = document.createElement('input');
                return this.staffElem;
            },
            destroy: () => {
                //destroy function is used to destroy the component.
                this.staffObj.destroy();
            },
            read: () => {
                //read function is used to read the value from component at time of save.
                return (this.staffObj as any).text;
            },
            write: () => {
                //	write function is used to create custom component or assign default value at time of editing.
                this.staffObj = new DropDownList({
                    //Custom DropDownList component
                    dataSource: this.staffList,
                    fields: { text: 'staffName', value: 'staffId' },
                    placeholder: 'Assign a Staff Member',
                    enabled: true,
                    floatLabelType: 'Never',
                });
                this.staffObj.appendTo(this.staffElem);
            },
        };
    }

    load(args: any) {
        (this.grid as any).element.addEventListener('mouseup', (e: MouseEventArgs) => {
            if ((e.target as HTMLElement).classList.contains('e-rowcell')) {
                if ((this.grid as any).isEdit) (this.grid as any).endEdit();
                let rowInfo = (this.grid as any).getRowInfo(e.target);
                if (rowInfo.column.field === 'reportStatus' || rowInfo.column.field === 'assignedStaff')
                    this.isDropdown = true;
                (this.grid as any).selectRow(rowInfo.rowIndex);
                (this.grid as any).startEdit();
            }
        });
    }

    actionBegin(args) {
        console.log('Action Begin', args);
    }

    actionComplete(args) {
        if ((args as any).requestType == 'beginEdit' && this.isDropdown) {
            this.isDropdown = false;
            let dropdownObj = (args as any).form.querySelector('.e-dropdownlist').ej2_instances[0];
            dropdownObj.element.focus();
            dropdownObj.showPopup();
        }
        if (args.requestType == 'save') {
            let reportModel: ReportsModel = args.data;
            this.reportService.updateReportStatusStaffAsync(reportModel).subscribe((data) => {
                this.fullReportList = _.cloneDeep(data);
                this.mappedReportData = data
                    ?.filter((x) => x?.reportStatus == 'Active')
                    .map((r) => ({
                        staffName: `${r?.firstName} ${r?.lastName}`,
                        staffId: r?.staffId,
                        reportId: r?.reportId,
                        reportNumber: r?.reportNumber,
                        reportName: r?.reportName,
                        reportStatus: r?.reportStatus,
                        modifiedDateSubmitted: r?.modifiedDateSubmitted || r?.dateSubmitted,
                        clientInfoModel: r?.clientInfoModel,
                    }));
                this.alertService.success('Changes have been updated successfully');
            });
        }
    }
    customizeCell(args: QueryCellInfoEventArgs) {
        if ((args as any).column.field === 'reportStatus') {
            (args as any).cell.classList.add('dropdown');
        }
        if ((args as any).column.field === 'staffId') {
            (args as any).cell.classList.add('dropdown');
        }
    }
    patchForm(model: ClientsModel) {
        this.clientName = 'Assign Staff for ' + model?.clientFirstName + ' ' + model?.clientLastName;
        this.client = model?.clientFirstName + ' ' + model?.clientLastName;
        this.validateForm.get('clientFirstName').setValue(model?.clientFirstName);
        this.validateForm.get('clientLastName').setValue(model?.clientLastName);
        this.validateForm.get('clientMiddleName').setValue(model?.clientMiddleName);
        if (model?.clientDOB !== '') {
            this.validateForm.get('clientDOB').setValue(new Date(model?.clientDOB));
        } else {
            this.validateForm.get('clientDOB').setValue(model?.clientDOB);
        }
        this.validateForm.get('clientNumber').setValue(model?.clientNumber);
        this.validateForm.get('email').setValue(model?.email);
        this.validateForm.get('mobilePhone').setValue(model?.mobilePhone);
        this.validateForm.get('homePhone').setValue(model?.homePhone);
        this.validateForm.get('preferredName').setValue(model?.preferredName);
        this.validateForm.get('streetAddress').setValue(model?.streetAddress);
        this.validateForm.get('city').setValue(model?.city);
        this.validateForm.get('state').setValue(model?.state);
        this.validateForm.get('zip').setValue(model?.zip);
        this.validateForm.get('clientAddressCounty').setValue(model?.clientAddressCounty);
        this.validateForm.get('clientId').setValue(model?.clientId);
        this.validateForm.get('email').setValue(model?.email);

        this.validateForm.get('clientLocation').setValue(model?.clientLocation);
        this.validateForm.get('streetAddress2').setValue(model?.streetAddress2);
        this.validateForm
            .get('isClientCfrPartTwoEnabled')
            .setValue(this.utility.getBoolean(model?.isClientCfrPartTwoEnabled));
        this.validateForm.get('clientContactsWorkPhone').setValue(model?.clientContactsWorkPhone);

        this.validateForm
            .get('doNotMentionAgency')
            .setValue(this.utility.getBoolean(model?.doNotMentionAgency));
        this.validateForm
            .get('doNotContactByPhoneCall')
            .setValue(this.utility.getBoolean(model?.doNotContactByPhoneCall));
        this.validateForm
            .get('doNotContactBySms')
            .setValue(this.utility.getBoolean(model?.doNotContactBySms));
        this.validateForm
            .get('doNotContactByEmail')
            .setValue(this.utility.getBoolean(model?.doNotContactByEmail));
        this.validateForm
            .get('clientEmergencyContactFullName')
            .setValue(model?.clientEmergencyContactFullName);
        this.validateForm
            .get('clientEmergencyContactPhoneNumber')
            .setValue(model?.clientEmergencyContactPhoneNumber);
        this.validateForm
            .get('clientEmergencyContactRelationship')
            .setValue(model?.clientEmergencyContactRelationship);
        this.validateForm.get('clientNotes').setValue(model?.clientNotes);
        this.validateForm.get('clientHealthInsuranceType').setValue(model?.clientHealthInsuranceType);
        this.validateForm.get('clientInsuranceProvider').setValue(model?.clientInsuranceProvider);
        this.validateForm.get('clientInsuranceMemberNumber').setValue(model?.clientInsuranceMemberNumber);
        this.validateForm.get('clientInsuranceGroupNumber').setValue(model?.clientInsuranceGroupNumber);
        this.validateForm.get('clientInsurancePhoneNumber').setValue(model?.clientInsurancePhoneNumber);
        this.validateForm.get('clientDemographicInfoSsn').setValue(model?.clientDemographicInfoSsn);
        this.validateForm.get('clientDemographicInfoGender').setValue(model?.clientDemographicInfoGender);
        this.validateForm
            .get('clientDemographicInfoGenderIdentity')
            .setValue(model?.clientDemographicInfoGenderIdentity);
        this.validateForm.get('clientOtherGenderIdentity').setValue(model?.clientOtherGenderIdentity);
        this.validateForm.get('clientDemographicInfoPronouns').setValue(model?.clientDemographicInfoPronouns);
        this.validateForm.get('clientDemographicInfoRace').setValue(model?.clientDemographicInfoRace);
        this.validateForm.get('clientOtherRace').setValue(model?.clientOtherRace);
        this.validateForm
            .get('clientDemographicInfoMaritalStatus')
            .setValue(model?.clientDemographicInfoMaritalStatus);
        this.validateForm.get('clientDemographicInfoReligion').setValue(model?.clientDemographicInfoReligion);
        this.validateForm
            .get('clientDemographicInfoOtherReligion')
            .setValue(model?.clientDemographicInfoOtherReligion);
        this.validateForm
            .get('clientDemographicInfoPreferredLanguage')
            .setValue(model?.clientDemographicInfoPreferredLanguage);
        this.validateForm
            .get('clientDemographicInfoOtherPreferredLanguage')
            .setValue(model?.clientDemographicInfoOtherPreferredLanguage);
        this.validateForm
            .get('clientDemographicInfoEmployment')
            .setValue(model?.clientDemographicInfoEmployment);
        this.validateForm.get('clientDemographicInfoEmployer').setValue(model?.clientDemographicInfoEmployer);
        this.validateForm.get('isActive').setValue(this.utility.getBoolean(model?.isActive));
        this.checked = this.utility.getBoolean(model?.isActive) == false ? true : false;
        this.validateForm.markAllAsTouched();
    }
    keydownInDropdown(event) {
        if (event.keyCode == 13) {
            // set highlighted value as selected value. (default)
            console.log(event);
        }

        if (event.keyCode == 37 || event.keyCode == 39) {
            // set highlighted value as selected value.
            console.log(event);
        }
        // console.log("keydown is ",event)
    }
    onFormValidation(validity: boolean) {
        switch (validity) {
            case true:
                this.isEnable$.next(true);
                break;
            case false:
                this.isEnable$.next(false);
                break;
        }
    }

    assignStaffToClient() {
        this.staffSelected = this.select['value'];
        this.assignedStaff = this.staffSelected['staffName'];
        if (this.staffSelected) {
            let clientsModel = new ClientsModel();
            clientsModel.clientId = this.clientDataModel.clientId;
            clientsModel.staffId = this.staffSelected;
            clientsModel.createdDate = this.clientDataModel.createdDate || new Date().toISOString();
            clientsModel.modifiedDate = new Date().toISOString();
            clientsModel.reportId = this.clientDataModel.reportId || null;
            this.clientService.assignStaffToClient(clientsModel).subscribe((r) => {
                this.alertService.success('Staff Member has been added successfully');
                this.assignedStaff = this.select['listOfTopItem'][0]['nzLabel'];
                this.cdr.detectChanges();
            });
        }
    }
    archive() {
        if (this.clientId) {
            this.isLoading$.next(true);
            this.model = this.validateForm.value;
            console.log(this.model, 'this.model');

            this.clientService
                .deactivateClient(this.model)
                .pipe(take(1))
                .subscribe(
                    (data) => {
                        this.alertService.success('Client Successfully archived');
                    },
                    (err) => {
                        this.isLoading$.next(false);
                        this.alertService.error(err);
                    }
                );
        }
    }

    save() {
        if (this.checked == true) {
            Object.keys(this.validateForm.controls).forEach((key) => {
                this.validateForm.get(key).setErrors(null);
            });
        }
        if (this.isEnable) {
            this.isLoading$.next(true);
            this.model = this.validateForm.value;
            console.log('Model', this.model);
            this.model.clientId = this.model.clientId || Guid.create().toString();
            this.model.isClientCfrPartTwoEnabled = this.model?.isClientCfrPartTwoEnabled?.toString();
            this.model.doNotMentionAgency = this.model?.doNotMentionAgency?.toString();
            this.model.doNotContactByPhoneCall = this.model?.doNotContactByPhoneCall?.toString();
            this.model.doNotContactBySms = this.model?.doNotContactBySms?.toString();
            this.model.doNotContactByEmail = this.model?.doNotContactByEmail?.toString();
            //In this case if the checkbox is checked that means isActive is false.
            //We want the opposite. Checked == isActive == False
            // Not checked == is Active == true as form is enabled when not checked
            this.model.isActive = this.checked == false ? 'true' : 'false';
            if (this.model.isActive == 'false' && this.model.staffId != '') {
                //If client is deactivated the remove the assigned staff member
                this.clientService.resetStaffClients(this.model.clientId).subscribe((res) => {
                    console.log(res);
                });
            }
            if (this.clientId != 'Add New') {
                this.clientService
                    .updateClient(this.model)
                    .pipe(take(1))
                    .subscribe(
                        (data) => {
                            this.alertService.success('Updates were saved successfully');
                            this.patchForm(this.model);
                        },
                        (err) => {
                            this.isLoading$.next(false);
                            this.alertService.error(err);
                        }
                    );
            } else {
                this.clientService
                    .AddClient(this.model)
                    .pipe(take(1))
                    .subscribe(
                        (data) => {
                            this.alertService.success('Changes were saved successfully');
                            this.router.navigate(['/client'], {
                                queryParams: { client_id: this.model.clientId },
                            });
                        },
                        (err) => {
                            this.isLoading$.next(false);
                            this.alertService.error(err);
                        }
                    );
            }
        } else {
            Object.values(this.validateForm.controls).forEach((control) => {
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({ onlySelf: true });
                }
            });
            let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
            invalidFields[1].focus();
            this.alertService.error('Form validation error!');
        }
    }

    close() {
        this.router.navigate(['/clientGrid']);
    }

    setActive(e: any) {
        let reportActiveCount = this.mappedReportData.filter((x) => x.reportStatus == 'Active');
        if (this.dbox.nativeElement.checked == true && reportActiveCount?.length > 0) {
            setTimeout(() => {
                this.dbox.nativeElement.checked = false;
                this.checked = false;
            }, 200);

            this.alertService.warn(
                `There are ${reportActiveCount?.length} active reports associated with this client.
                 Please close these reports before deactivating this client`
            );

            this.cdr.detectChanges();
            return;
        }
        if (this.dbox.nativeElement.checked) {
            this.clientStatus = 'Client Deactivated';
        } else {
            this.clientStatus = 'Deactivate Client';
        }

        this.checked = this.dbox.nativeElement.checked;
        this.validateForm.get('isActive').setValue(this.checked);
    }

    calculateAge(event) {
        if (event) {
            var dob: any = new Date(event); // mm/dd/yyyy
            var today: any = new Date();
            var timediff = Math.abs(today - dob);
            this.age = Math.floor(timediff / (1000 * 3600 * 24) / 365);
        }
    }

    refreshCasesGrid() {
        this.refreshcases = true;
        this.setReportGridByClientId(this.clientId);
    }

    updateInsuranceType(event) {
        console.log(event);
    }
    checkInsurance() {
        if (
            this.clientDataModel?.clientHealthInsuranceType.toUpperCase() == 'WITH' ||
            this.clientDataModel?.clientHealthInsuranceType.toUpperCase() == 'WITHOUT'
        ) {
            return true;
        } else {
            return false;
        }
    }

    checkRace() {
        return this.clientDataModel?.clientDemographicInfoRace;
    }

    checkReligion() {
        return this.clientDataModel?.clientDemographicInfoReligion;
    }
    checkLanguage() {
        return this.clientDataModel?.clientDemographicInfoPreferredLanguage;
    }

    checkIdentity() {
        return this.clientDataModel?.clientDemographicInfoGenderIdentity;
    }
    getClientNumber() {
        return this.clientDataModel?.clientNumber;
    }
    generateClientId() {
        let newNumber = Math.floor(Math.random() * 899999 + 100000);
        this.validateForm.get('clientNumber').setValue(newNumber.toString());
        this.clientDataModel.clientNumber = newNumber.toString();
        this.cdr.detectChanges();
    }

    public commandClick(args: CommandClickEventArgs): void {
        console.log('row data', args);
        if (args.rowData) {
            let mode = args.commandColumn.type;
            if (mode == 'Edit') {
                let model = this.mappedReportData.find((x) => x.reportNumber == args.rowData['reportNumber']);
                console.log('Report Model to pdf', model);
                this.router.navigate(['/pdfViewer'], {
                    state: {
                        report_id: model?.reportId,
                        reportTemplateId: null,
                        templateTitle: `Update ${model?.reportName}`,
                        model: this?.clientDataModel,
                        clientModel: this?.clientDataModel,
                        mode: 'Edit Client',
                    },
                });
            } else {
                this.onArchiveReport(args.rowData);
            }
        }
    }
    btnClickedHandler(data: any, mode: string) {}

    addNewClientReportModal() {
        let model = _.cloneDeep(this.clientDataModel);
        model.clientReports = [];
        model.clientReports.push(new ReportsModel());
        model.assessment = new Assessment();
        this.openDialog(model, 'Create New Client Report for ' + this.client, 'Add Client');
    }

    openDialog(model: ClientsModel, title: string, formMode: string): void {
        let files = [];
        if (model?.profileImage) {
            files.push(model?.profileImage);
        }

        let dialogRef = this.dialog.open(ReportModalComponent, {
            maxWidth: '96vw',
            width: '85vw',
            hasBackdrop: true,
            backdropClass: 'bdrop',
            disableClose: true,
            panelClass: 'overflow-dialog',
            autoFocus: false,
            data: {
                title: title,
                model: model,
                files: files,
                mode: formMode,
            },
        });
        dialogRef?.componentInstance?.onModalClose().subscribe((result) => {
            console.log('ReportId', result);
            let reportsModel = new ReportsModel();
            reportsModel.clientInfoModel = this.clientDataModel;
            reportsModel.reportId = result.form['reportId'];
            reportsModel.reportName = result.form['templateTitle'];
            this.reportService.addReport(reportsModel).subscribe((report) => {
                console.log('NewReportInfo', result);
                this.router.navigate(['/report'], {
                    state: {
                        report_id: result.form['reportId'],
                        reportTemplateId: result.form['reportTemplate'] || null,
                        templateTitle: result.form['templateTitle'] || null,
                        mode: 'Add Client',
                        clientModel: this.clientDataModel,
                    },
                });
            });
        });
    }

    onArchiveReport(data: ReportsModel) {
        this.reportService.deactivateClientReport(data).subscribe((result) => {
            this.fullReportList = _.cloneDeep(result);
            this.mappedReportData = result
                ?.filter((x) => x.reportStatus == 'Active')
                .map((r) => ({
                    staffName: `${r?.firstName} ${r?.lastName}`,
                    staffId: r?.staffId,
                    reportId: r?.reportId,
                    reportNumber: r?.reportNumber,
                    reportName: r?.reportName,
                    reportStatus: r?.reportStatus,
                    modifiedDateSubmitted: r?.modifiedDateSubmitted || r?.dateSubmitted,
                    clientInfoModel: r?.clientInfoModel,
                }));
            this.alertService.success('Changes have been updated successfully');
        });
    }
    goToCases() {}

    public handleAddressChange(place: google.maps.places.PlaceResult) {
        console.log(place);
        var addressModel = this.utility.getAddressObject(place.address_components);
        this.validateForm.get('streetAddress').setValue(addressModel['home'] + ' ' + addressModel['street']);
        this.validateForm.get('city').setValue(addressModel['city']);
        this.validateForm.get('state').setValue(addressModel['region']);
        this.validateForm.get('zip').setValue(addressModel['postal_code']);
        this.validateForm.get('clientAddressCounty').setValue(addressModel['county']);
        this.validateForm.updateValueAndValidity();
    }
}

window.onerror = function (message, url, lineNo) {
    console.log('Error: ' + message + '\n' + 'Line Number: ' + lineNo);

    return true;
};
