import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    Output,
    ViewChild,
    ViewContainerRef,
} from '@angular/core';
import { Subscription, filter, take } from 'rxjs';
import BlotFormatter, { AlignAction, DeleteAction, ImageSpec, ResizeAction } from 'quill-blot-formatter';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import htmlToPdfmake from 'html-to-pdfmake';
import ImageCompress from 'quill-image-compress';
import { ModalDialogService } from 'ngx-modal-dialog';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { RowColumnModalComponent } from './row-column-modal/row-column-modal.component';
import { NzFormTooltipIcon } from 'ng-zorro-antd/form';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { AlertService } from 'src/app/alerts/alert.service';
import { ReportsModel, ClientInformationModel, Assessment } from 'src/app/reportsScreen/models/reports.model';
import { ReportService } from '../../services/report.service';
import { PDFViewerComponent } from '../report.preview/pdf.viewer.component';
import { ClientsModel } from 'src/app/client/models/client.model';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var _ = require('lodash');

declare const Quill: any;
declare const quillBetterTable: any;
class CustomImageSpec extends ImageSpec {
    override getActions() {
        return [AlignAction, DeleteAction, ResizeAction];
    }
}

const fontSizeArr = ['8px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '52px'];

const DirectionAttribute = Quill.import('attributors/attribute/direction');
Quill.register(DirectionAttribute, true);
const AlignClass = Quill.import('attributors/class/align');
Quill.register(AlignClass, true);
const BackgroundClass = Quill.import('attributors/class/background');
Quill.register(BackgroundClass, true);
const ColorClass = Quill.import('attributors/class/color');
Quill.register(ColorClass, true);
const DirectionClass = Quill.import('attributors/class/direction');
Quill.register(DirectionClass, true);
const FontClass = Quill.import('attributors/class/font');
Quill.register(FontClass, true);
const SizeClass = Quill.import('attributors/class/size');
Quill.register(SizeClass, true);
const AlignStyle = Quill.import('attributors/style/align');
Quill.register(AlignStyle, true);
const BackgroundStyle = Quill.import('attributors/style/background');
Quill.register(BackgroundStyle, true);
const ColorStyle = Quill.import('attributors/style/color');
Quill.register(ColorStyle, true);
const DirectionStyle = Quill.import('attributors/style/direction');
Quill.register(DirectionStyle, true);
const FontStyle = Quill.import('attributors/style/font');
Quill.register(FontStyle, true);
const Size = Quill.import('attributors/style/size');
Size.whitelist = fontSizeArr;
Quill.register(Size, true);

@Component({
    selector: 'app-add-report',
    templateUrl: './add-report.component.html',
    styleUrls: ['./add-report.component.css'],
})
export class AddReportComponent {
    @Input() title: string = 'Assessment';
    @Input() model: ReportsModel = new ReportsModel();
    @Input() files: any;
    @Input() reportId: any;
    @Output() assessmentOutput = new EventEmitter<any>();
    mode: string = 'Add';
    editing: boolean = false;
    formData = new FormData();
    size: 'small' = 'small';
    @ViewChild('tabGroup', { static: false }) public tabGroup: any;
    @ViewChild('simple-modal-dialog') pdfreport: ElementRef;
    @ViewChild('editableSummary', { static: false }) editRefSummary: ElementRef;
    @ViewChild('editableOverallTesting', { static: false })
    editRefOverallTesting: ElementRef;
    @ViewChild('editableSituationalAssessment', { static: false })
    editRefSituationalAssessment: ElementRef;
    @ViewChild('editableRecommendations', { static: false })
    editRefRecommendations: ElementRef;
    @ViewChild('editableConclusion', { static: false })
    editRefConclusion: ElementRef;
    @ViewChild('editableCitation', { static: false })
    editRefCitation: ElementRef;
    visible: boolean = false;
    panelNo: number = 0;
    isVisible: boolean = false;
    tabTitle: string = '';
    tabFullTitle: string = '';
    isVisibleTab: boolean = false;
    isVisibleTabEdit: boolean = false;
    editedTab: any[] = [];
    editedTabCopy: any[] = [];

    panelIndex = 0;
    name = 'Summary';
    dataForTable = {
        rows: '',
        columns: '',
    };
    tabsWithoutEditor = ['Evaluation', 'Barriers', 'Skills'];
    evaluationForm: FormGroup;
    barriersForm: FormGroup;
    skillsForm: FormGroup;
    summaryEditor: any;
    conclusionEditor: any;
    evaluationEditor: any;
    overallTestingEditor: any;
    situationalAssessmentEditor: any;
    recommendationsEditor: any;
    citationEditor: any;

    reportFontSizes = { panel: '', tab: '', tab_child: '' };
    reportFontArr = ['8px', '10px', '12px', '14px', '16px', '18px', '20px', '24px', '32px', '42px', '52px'];

    panelOpenWithIndex = [
        {
            index: 0,
            name: 'Summary',
        },
        {
            index: 1,
            name: 'Measures',
        },
        {
            index: 2,
            name: 'Summary',
        },
        {
            index: 3,
            name: 'Evaluation',
        },
        {
            index: 4,
            name: 'Recommendations',
        },
        {
            index: 5,
            name: 'Summary',
        },
        {
            index: 6,
            name: 'Citation',
        },
    ];
    panels = [
        {
            active: true,
            title: 'Compendium',
            name: 'Compendium',
            disabled: false,
            tabControls: [
                {
                    title: 'Summary',
                    fullTitle: 'Overall Summary',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'Education',
                    fullTitle: 'Educational History',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'Medical',
                    fullTitle: 'Medical History',
                    value: '',
                    font: '',
                    fontSize: '',
                },

                {
                    title: 'Vocational',
                    fullTitle: 'Vocational History',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'Financial',
                    fullTitle: 'Financial History',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'Hobbies',
                    fullTitle: 'Client Hobbies',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'Transportation',
                    fullTitle: 'Transportation Information',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'Other',
                    fullTitle: 'Other Pertinent Information',
                    value: '',
                    font: '',
                    fontSize: '',
                },
            ],
        },
        {
            active: false,
            disabled: false,
            title: 'Overall Testing',
            name: 'Overall Testing',
            tabControls: [
                {
                    title: 'Measures',
                    fullTitle: 'Testing Measures Used',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'Testing',
                    fullTitle: 'Testing Observations and Environment',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'COPS',
                    fullTitle: 'Scoring: Career Occupational Preference System Interest Inventory',
                    value: '',
                    font: '',
                    fontSize: '',
                },

                {
                    title: 'RAIT',
                    fullTitle: 'The Reynolds IQ Assessment',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'WIAT',
                    fullTitle: 'The Wechsler Academic Adult Test (WIAT 4th Edition)',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'WRAT',
                    fullTitle: 'Wide Range Achievement Test 5, 10th Edition (WRAT5)',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'ONET',
                    fullTitle: 'O*Net Interest Profiler',
                    value: '',
                    font: '',
                    fontSize: '',
                },
            ],
        },
        {
            active: false,
            disabled: false,
            title: 'Situational Assessment',
            name: 'Situational Assessment',
            tabControls: [
                {
                    title: 'Summary',
                    fullTitle: 'Situational Assessment Summary',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'Observation',
                    fullTitle: 'Observations During Situational Assessment',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'Feedback',
                    fullTitle: 'Feedback From Situational Assessment',
                    value: '',
                    font: '',
                    fontSize: '',
                },
            ],
        },
        {
            active: false,
            disabled: false,
            title: 'Evaluation',
            name: 'Evaluation',
            tabControls: [
                {
                    title: 'Evaluation',
                    fullTitle: 'Evaluation Behaviors and Reflections',
                    value: {},
                },
            ],
        },
        {
            active: false,
            disabled: false,
            title: 'Recommendations',
            name: 'Recommendations',
            tabControls: [
                {
                    title: 'Recommendations',
                    fullTitle: 'Recommendations Summary',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'Barriers',
                    fullTitle: 'Barriers To Employment',
                    value: { barrier: [] },
                },
                {
                    title: 'Skills',
                    fullTitle: 'Transferable Skills',
                    value: { skill: [] },
                },
            ],
        },
        {
            active: false,
            disabled: false,
            title: 'Conclusion',
            name: 'Conclusion',
            tabControls: [
                {
                    title: 'Summary',
                    fullTitle: 'Conclusion Summary',
                    value: '',
                    font: '',
                    fontSize: '',
                },
                {
                    title: 'Evaluator',
                    fullTitle: 'Evaluator Information',
                    value: '',
                    font: '',
                    fontSize: '',
                },
            ],
        },
        {
            active: false,
            disabled: false,
            title: 'Citation',
            name: 'Citation',
            tabControls: [
                {
                    title: 'Citation',
                    fullTitle: 'Citation Summary',
                    value: '',
                    font: '',
                    fontSize: '',
                },
            ],
        },
    ];
    editorToolbar = {
        model: '',
        theme: 'snow',
        placeholder: 'Enter Text Here',
        bounds: document.body,
        modules: {
            table: false,
            toolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote'],
                [{ list: 'ordered' }, { list: 'bullet' }],
                [{ direction: 'rtl' }],
                [{ script: 'sub' }, { script: 'super' }],
                [{ indent: '-1' }, { indent: '+1' }],
                [{ header: [1, 2, 3, 4, 5, 6, false] }],
                [{ font: [] }],
                [{ size: fontSizeArr }],
                [{ color: [] }, { background: [] }],
                [{ align: [] }],
                ['clean'],
                ['link', 'image'],
            ],
            blotFormatter: {
                specs: [CustomImageSpec],
                overlay: {
                    style: {
                        border: '1px solid black',
                    },
                },
            },
            'better-table': {
                operationMenu: {
                    items: {
                        unmergeCells: {
                            text: 'Another unmerge cells name',
                        },
                    },
                    color: {
                        colors: ['green', 'red', 'yellow', 'blue', 'white'],
                        text: 'Background Colors:',
                    },
                },
            },
            imageCompress: {
                quality: 0.7,
                maxWidth: 1000,
                maxHeight: 1000,
                imageType: 'image/jpeg',
                debug: true,
                suppressErrorLogging: false,
                insertIntoEditor: undefined,
            },
            keyboard: {
                bindings: quillBetterTable.keyboardBindings,
            },
        },
    };

    private subscription: Subscription;
    public activeTabIndex: number | undefined = undefined;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private fb: FormBuilder,
        private modalDialogService: ModalDialogService,
        private viewContainer: ViewContainerRef,
        private dialogRef: MatDialogRef<DialogComponent>,
        public dialog: MatDialog,
        private service: ReportService,
        private alertService: AlertService,
        private cdRef: ChangeDetectorRef,
        private router: Router
    ) {
        this.router.events
            .pipe(filter((e) => e instanceof NavigationStart))
            .subscribe((e: NavigationStart) => {
                const navigation = this.router.getCurrentNavigation();
                this.model = navigation.extras.state?.data ?? new ReportsModel();
                this.reportId = navigation.extras.state?.report_id ?? null;
                this.mode = navigation.extras.state?.mode ?? 'Add';
                this.title = navigation.extras.state?.title ?? 'Assessment';
            });

        this.subscription = this.service
            .getNextEvent()
            .pipe(take(1))
            .subscribe(() => {
                this.next();
            });
    }

    drop(event: CdkDragDrop<string[]>): void {
        moveItemInArray(this.editedTabCopy, event.previousIndex, event.currentIndex);
    }
    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    validateForm!: FormGroup;
    captchaTooltipIcon: NzFormTooltipIcon = {
        type: 'info-circle',
        theme: 'twotone',
    };

    submitForm(): void {
        if (this.validateForm.valid) {
        } else {
            Object.values(this.validateForm.controls).forEach((control) => {});
        }
    }

    public ngAfterViewInit() {
        this.editorSummary();
        this.editorOverallTesting();
        this.editorSituationalAssessment();
        this.editorRecommendations();
        this.editorConclusion();
        this.editorCitation();
        document.querySelectorAll('.ql-toolbar').forEach((item, index) => {
            if (index < 6) {
                item.appendChild(document.getElementById(`add-table${index}`));
            }
        });
        const editorContent = JSON.parse(sessionStorage.getItem('editorContent'));
        if (editorContent === null) {
            this.panels[3].tabControls[0].value = {
                txtEval: '',
                txtSituation: '',
                txtSelf: '',
                txtFailure: '',
                txtPraise: '',
                txtDevelopSkills: '',
                txtLeastDeveloped: '',
            };
            sessionStorage.setItem('editorContent', JSON.stringify(this.panels));
        } else {
            editorContent[0].tabControls[0].value?.length > 0
                ? (document.getElementsByClassName('ql-editor')[0].innerHTML =
                      editorContent[0].tabControls[0].value)
                : '';
        }
        this.barriersForm = this.fb.group({
            barrier: this.fb.array([]),
        });
        this.skillsForm = this.fb.group({
            skill: this.fb.array([]),
        });
        this.evaluationForm = this.fb.group({
            txtEval: [editorContent ? editorContent[3]?.tabControls[0]?.value?.txtEval : ''],
            txtSituation: [editorContent ? editorContent[3]?.tabControls[0]?.value?.txtSituation : ''],
            txtSelf: [editorContent ? editorContent[3]?.tabControls[0]?.value?.txtSelf : ''],
            txtFailure: [editorContent ? editorContent[3]?.tabControls[0]?.value?.txtFailure : ''],
            txtPraise: [editorContent ? editorContent[3]?.tabControls[0]?.value?.txtPraise : ''],
            txtDevelopSkills: [
                editorContent ? editorContent[3]?.tabControls[0]?.value?.txtDevelopSkills : '',
            ],
            txtLeastDeveloped: [
                editorContent ? editorContent[3]?.tabControls[0]?.value?.txtLeastDeveloped : '',
            ],
        });

        if (
            editorContent &&
            editorContent[4]?.tabControls[1]?.value?.barrier &&
            editorContent &&
            editorContent[4]?.tabControls[1]?.value?.barrier.length > 0
        ) {
            editorContent[4]?.tabControls[1]?.value?.barrier.map((item, index) => {
                this.addBarrierItem(item.barrier);
            });
        } else {
            this.addBarrierItem('');
        }

        if (
            editorContent &&
            editorContent[4]?.tabControls[2]?.value?.skill &&
            editorContent &&
            editorContent[4]?.tabControls[2]?.value?.skill.length > 0
        ) {
            editorContent[4]?.tabControls[2]?.value?.skill.map((item, index) => {
                this.addSkillItem(item.skill);
            });
        } else {
            this.addSkillItem('');
        }
    }

    getBarrierItems() {
        return (<FormArray>this.barriersForm.get('barrier')).controls;
    }

    getSkillItems() {
        return (<FormArray>this.skillsForm.get('skill')).controls;
    }

    addBarrierItem(name?: string): void {
        const items = this.barriersForm.get('barrier') as FormArray;
        items.push(this.createBarrierItem(name));
    }

    addSkillItem(name?: string): void {
        const items = this.skillsForm.get('skill') as FormArray;
        items.push(this.createSkillItem(name));
    }

    createBarrierItem(name?: string): FormGroup {
        return this.fb.group({
            barrier: name ? name : '',
        });
    }

    createSkillItem(name?: string): FormGroup {
        return this.fb.group({
            skill: name ? name : '',
        });
    }

    removeBarrierItem(): void {
        const items = this.barriersForm.get('barrier') as FormArray;

        if (items.controls.length > 1) {
            items.controls.splice(items.controls.length - 1, 1);
        }
    }

    removeSkillItem(): void {
        const items = this.skillsForm.get('skill') as FormArray;

        if (items.controls.length > 1) {
            items.controls.splice(items.controls.length - 1, 1);
        }
    }

    ngOnInit() {
        // this.title = this.data?.title;
        // this.model = this.data?.model;
        // this.mode = this.data?.mode;
        this.model.mode = this.mode;
        this.model.clientInfoModel = new ClientsModel();
        this.model.clientInfoModel.assessment = new Assessment();
        if (this.model.clientInfoModel.assessment['assessmentData'].length > 0) {
            sessionStorage.removeItem('editorContent');
            sessionStorage.removeItem('pdfContent');
            sessionStorage.setItem('editorContent', this.model.clientInfoModel?.assessment?.assessmentData);
        }
        const editorContent = JSON.parse(sessionStorage.getItem('editorContent'));

        if (editorContent !== null) {
            this.panels = editorContent;
            this.name = editorContent[0]?.tabControls[0]?.title;
            if (!this.panels[0].hasOwnProperty('title')) {
                this.panels.forEach(async (item, index) => {
                    this.panels[index]['title'] = this.panels[index]['name'];
                });
                sessionStorage.setItem('editorContent', JSON.stringify(this.panels));
            }
        }
    }

    editorSummary(): void {
        Quill.register(
            {
                'modules/better-table': quillBetterTable,
            },
            true
        );
        Quill.register('modules/blotFormatter', BlotFormatter);
        Quill.register('modules/imageCompress', ImageCompress);
        this.summaryEditor = new Quill(this.editRefSummary.nativeElement, this.editorToolbar);
    }

    editorOverallTesting(): void {
        Quill.register(
            {
                'modules/better-table': quillBetterTable,
            },
            true
        );
        Quill.register('modules/blotFormatter', BlotFormatter);
        Quill.register('modules/imageCompress', ImageCompress);
        this.overallTestingEditor = new Quill(this.editRefOverallTesting.nativeElement, this.editorToolbar);
    }

    editorSituationalAssessment(): void {
        Quill.register(
            {
                'modules/better-table': quillBetterTable,
            },
            true
        );
        Quill.register('modules/blotFormatter', BlotFormatter);
        Quill.register('modules/imageCompress', ImageCompress);
        this.situationalAssessmentEditor = new Quill(
            this.editRefSituationalAssessment.nativeElement,
            this.editorToolbar
        );
    }

    editorRecommendations(): void {
        Quill.register(
            {
                'modules/better-table': quillBetterTable,
            },
            true
        );
        Quill.register('modules/blotFormatter', BlotFormatter);
        Quill.register('modules/imageCompress', ImageCompress);
        this.recommendationsEditor = new Quill(
            this.editRefRecommendations?.nativeElement,
            this.editorToolbar
        );
    }

    editorConclusion(): void {
        Quill.register(
            {
                'modules/better-table': quillBetterTable,
            },
            true
        );
        Quill.register('modules/blotFormatter', BlotFormatter);
        Quill.register('modules/imageCompress', ImageCompress);
        this.conclusionEditor = new Quill(this.editRefConclusion.nativeElement, this.editorToolbar);
    }

    editorCitation(): void {
        Quill.register(
            {
                'modules/better-table': quillBetterTable,
            },
            true
        );
        Quill.register('modules/blotFormatter', BlotFormatter);
        Quill.register('modules/imageCompress', ImageCompress);
        this.citationEditor = new Quill(this.editRefCitation.nativeElement, this.editorToolbar);
    }

    insertTable(accordionName: string) {
        const dialogRef = this.dialog.open(RowColumnModalComponent, {
            width: '400px',
            height: '300px',
        });
        dialogRef.afterClosed().subscribe((data) => {
            this.dataForTable = data?.form
                ? data.form
                : {
                      rows: 0,
                      columns: 0,
                  };
            if (Number(this.dataForTable?.rows) > 0 && Number(this.dataForTable?.columns) > 0) {
                switch (accordionName) {
                    case 'Compendium':
                        this.summaryEditor
                            .getModule('better-table')
                            .insertTable(Number(this.dataForTable?.rows), Number(this.dataForTable?.columns));
                        break;
                    case 'OverallTesting':
                        this.overallTestingEditor
                            .getModule('better-table')
                            .insertTable(Number(this.dataForTable?.rows), Number(this.dataForTable?.columns));
                        break;
                    case 'SituationalAssessment':
                        this.situationalAssessmentEditor
                            .getModule('better-table')
                            .insertTable(Number(this.dataForTable?.rows), Number(this.dataForTable?.columns));
                        break;
                    case 'Recommendations':
                        this.recommendationsEditor
                            .getModule('better-table')
                            .insertTable(Number(this.dataForTable?.rows), Number(this.dataForTable?.columns));
                        break;
                    case 'Conclusion':
                        this.conclusionEditor
                            .getModule('better-table')
                            .insertTable(Number(this.dataForTable?.rows), Number(this.dataForTable?.columns));
                        break;
                    case 'Citation':
                        this.citationEditor
                            .getModule('better-table')
                            .insertTable(Number(this.dataForTable?.rows), Number(this.dataForTable?.columns));
                        break;
                }
            } else if (data.form) {
                this.alertService.warn('Invalid Rows or Columns');
            }
        });
    }

    changeTab(tabName: string, panelName: string) {
        const lastTabName = this.name;
        this.name = tabName;
        if (tabName === 'Evaluation') {
            document.querySelectorAll('.ql-toolbar')[2].classList.add('no-editor');
            document.querySelectorAll('.ql-editor')[2].classList.add('no-editor');
        } else if (document.querySelectorAll('.ql-toolbar')[2].classList.contains('no-editor')) {
            document.querySelectorAll('.ql-toolbar')[2].classList.remove('no-editor');
            document.querySelectorAll('.ql-editor')[2].classList.remove('no-editor');
        }
        const regex = /<[^>]*>/gim;
        const panelIndex = this.panels?.findIndex((item) => item?.name === panelName);
        if (panelIndex !== -1) {
            const previousTabIndex = this.panels[panelIndex]?.tabControls?.findIndex(
                (item) => item?.title === lastTabName
            );
            const currentTabIndex = this.panels[panelIndex]?.tabControls?.findIndex(
                (item) => item?.title === tabName
            );
            const tabIndex = this.panelOpenWithIndex?.findIndex((item) => item?.index === panelIndex);
            this.panelOpenWithIndex[tabIndex] = {
                index: panelIndex,
                name: tabName,
            };
            const editorContent = JSON.parse(sessionStorage.getItem('editorContent'));
            const allEditorContent = document.querySelectorAll('.ql-editor');
            if (
                editorContent !== null &&
                allEditorContent[panelIndex > 3 ? panelIndex - 1 : panelIndex] !== undefined
            ) {
                if (this.tabsWithoutEditor.find((item) => item === lastTabName) === undefined) {
                    let editorData = allEditorContent[panelIndex > 3 ? panelIndex - 1 : panelIndex].innerHTML;
                    editorData?.replace(regex, '')?.length === 0 ? (editorData = '') : '';
                    editorContent[panelIndex].tabControls[previousTabIndex].value = editorData;
                    sessionStorage.setItem('editorContent', JSON.stringify(editorContent));
                } else {
                    editorContent[panelIndex].tabControls[previousTabIndex].value =
                        lastTabName === 'Evaluation'
                            ? this.evaluationForm.value
                            : lastTabName === 'Barriers'
                            ? this.barriersForm.value
                            : this.skillsForm.value;
                    sessionStorage.setItem('editorContent', JSON.stringify(editorContent));
                }
                if (this.tabsWithoutEditor?.find((item) => item === tabName) === undefined) {
                    allEditorContent[panelIndex > 3 ? panelIndex - 1 : panelIndex].innerHTML =
                        editorContent[panelIndex].tabControls[currentTabIndex].value?.length > 0
                            ? editorContent[panelIndex].tabControls[currentTabIndex].value
                            : '';
                }
            }
        }
    }

    panelChange(panelName: String) {
        const regex = /<[^>]*>/gim;
        const selectedPanelIndex = this.panels?.findIndex((item) => item?.name === panelName);
        if (selectedPanelIndex !== -1 && selectedPanelIndex !== this.panelIndex) {
            const tabDetails = this.panelOpenWithIndex?.find((item) => item.index === selectedPanelIndex);
            if (tabDetails?.name === 'Evaluation') {
                document.querySelectorAll('.ql-toolbar')[2].classList.add('no-editor');
                document.querySelectorAll('.ql-editor')[2].classList.add('no-editor');
            } else if (document.querySelectorAll('.ql-toolbar')[2].classList.contains('no-editor')) {
                document.querySelectorAll('.ql-toolbar')[2].classList.remove('no-editor');
                document.querySelectorAll('.ql-editor')[2].classList.remove('no-editor');
            }
            const previousTabIndex = this.panels[this.panelIndex]?.tabControls?.findIndex(
                (item) => item.title === this.name
            );
            const currentTabIndex = this.panels[selectedPanelIndex]?.tabControls?.findIndex(
                (item) => item.title === tabDetails.name
            );
            const editorContent = JSON.parse(sessionStorage.getItem('editorContent'));
            const allEditorContent = document.querySelectorAll('.ql-editor');
            if (editorContent !== null) {
                if (
                    this.tabsWithoutEditor?.find((item) => item === this.name) === undefined &&
                    allEditorContent[this.panelIndex > 3 ? this.panelIndex - 1 : this.panelIndex]
                ) {
                    let editorData =
                        allEditorContent[this.panelIndex > 3 ? this.panelIndex - 1 : this.panelIndex]
                            .innerHTML;
                    allEditorContent[this.panelIndex > 3 ? this.panelIndex - 1 : this.panelIndex].innerHTML =
                        '';
                    editorData?.replace(regex, '')?.length === 0 ? (editorData = '') : '';
                    editorContent[this.panelIndex].tabControls[previousTabIndex].value = editorData;
                    sessionStorage.setItem('editorContent', JSON.stringify(editorContent));
                } else {
                    editorContent[this.panelIndex].tabControls[previousTabIndex].value =
                        this.name === 'Evaluation'
                            ? this.evaluationForm.value
                            : this.name === 'Barriers'
                            ? this.barriersForm.value
                            : this.skillsForm.value;
                    sessionStorage.setItem('editorContent', JSON.stringify(editorContent));
                }
                this.name = tabDetails?.name;
                if (
                    this.tabsWithoutEditor?.find((item) => item === tabDetails?.name) === undefined &&
                    allEditorContent[selectedPanelIndex > 3 ? selectedPanelIndex - 1 : selectedPanelIndex]
                ) {
                    allEditorContent[
                        selectedPanelIndex > 3 ? selectedPanelIndex - 1 : selectedPanelIndex
                    ].innerHTML = editorContent[selectedPanelIndex].tabControls[currentTabIndex].value?.length
                        ? editorContent[selectedPanelIndex].tabControls[currentTabIndex].value
                        : '';
                }
            }
            this.panelIndex = selectedPanelIndex;
        }
    }

    confirmDialog() {
        this.alertService.warn(`No Written Data Found , Won't be able to Download Pdf Report`);
        // this.dialog.open(WarningModalComponent, {
        //   width: '400px',
        //   height: '100px',
        //   data: {
        //     message: `No Written Data Found , Won't be able to Download Pdf Report`,
        //   },
        //   backdropClass: 'confirmDialogComponent',
        //   hasBackdrop: true,
        // });
    }

    getBase64ImageFromURL(url) {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.setAttribute('crossOrigin', 'anonymous');

            img.onload = () => {
                const canvas = document.createElement('canvas');
                canvas.width = img.width;
                canvas.height = img.height;

                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0);

                const dataURL = canvas.toDataURL('image/png');

                resolve(dataURL);
            };

            img.onerror = (error) => {
                reject(error);
            };

            img.src = url;
        });
    }

    async next() {
        const regex = /<[^>]*>/gim;
        const allEditorContent = document.querySelectorAll('.ql-editor');
        const editorContent = JSON.parse(sessionStorage.getItem('editorContent'));
        const selectedTabIndex = this.panels[this.panelIndex]?.tabControls?.findIndex(
            (item) => item?.title === this.name
        );
        if (this?.tabsWithoutEditor?.find((item) => item === this.name) === undefined) {
            if (
                allEditorContent[this.panelIndex > 3 ? this.panelIndex - 1 : this.panelIndex] &&
                allEditorContent[this.panelIndex > 3 ? this.panelIndex - 1 : this.panelIndex].innerHTML !==
                    undefined
            ) {
                let editorData =
                    allEditorContent[this.panelIndex > 3 ? this.panelIndex - 1 : this.panelIndex].innerHTML;
                editorData?.replace(regex, '')?.length === 0 ? (editorData = '') : '';
                editorContent[this.panelIndex].tabControls[selectedTabIndex].value = editorData;
                sessionStorage.setItem('editorContent', JSON.stringify(editorContent));
            }
        } else if (this?.tabsWithoutEditor?.includes(this?.name)) {
            editorContent[this?.panelIndex].tabControls[selectedTabIndex].value =
                this?.name === 'Evaluation'
                    ? this?.evaluationForm?.value
                    : this?.name === 'Barriers'
                    ? this?.barriersForm?.value
                    : this?.skillsForm?.value;
            sessionStorage.setItem('editorContent', JSON.stringify(editorContent));
        }

        let reportContent = editorContent
            .map((item: { title: string; tabControls: { value: string }[]; name: string }) => {
                const tabsContent = item.tabControls.map((item1: { title: string; value: string }) => {
                    if (!this.tabsWithoutEditor.includes(item1.title)) {
                        if (item1.value.length > 0) {
                            return (
                                `<div class="nameTitle"><i><h4 ${
                                    this.reportFontSizes.tab != ''
                                        ? `style="font-size:${this.reportFontSizes.tab}"`
                                        : ``
                                }>${item1.title} :- </h4><i></div>` +
                                item1.value +
                                `<br>`
                            );
                        } else {
                            return '';
                        }
                    } else {
                        if (item1.title === 'Evaluation') {
                            const evaluationValues = Object.keys(item1.value)
                                .map((item, index) => {
                                    if (
                                        index === 0 &&
                                        item1.value[item] !== null &&
                                        item1.value[item].length > 0
                                    ) {
                                        return `<h6 ${
                                            this.reportFontSizes.tab_child != ''
                                                ? `style="font-size:${this.reportFontSizes.tab_child}"`
                                                : ``
                                        }>Attitude Toward The Evaluator - </h6><p>${item1.value[item]}</p>`;
                                    } else if (
                                        index === 1 &&
                                        item1.value[item] !== null &&
                                        item1.value[item].length > 0
                                    ) {
                                        return `<h6 ${
                                            this.reportFontSizes.tab_child != ''
                                                ? `style="font-size:${this.reportFontSizes.tab_child}"`
                                                : ``
                                        }>Attitude Toward The Assessment Situation - </h6><p>${
                                            item1.value[item]
                                        }</p>`;
                                    } else if (
                                        index === 2 &&
                                        item1.value[item] !== null &&
                                        item1.value[item].length > 0
                                    ) {
                                        return `<h6 ${
                                            this.reportFontSizes.tab_child != ''
                                                ? `style="font-size:${this.reportFontSizes.tab_child}"`
                                                : ``
                                        }>Attitude Toward Self - </h6><p>${item1.value[item]}</p>`;
                                    } else if (
                                        index === 3 &&
                                        item1.value[item] !== null &&
                                        item1.value[item].length > 0
                                    ) {
                                        return `<h6 ${
                                            this.reportFontSizes.tab_child != ''
                                                ? `style="font-size:${this.reportFontSizes.tab_child}"`
                                                : ``
                                        }>Reaction To Failure - </h6><p>${item1.value[item]}</p>`;
                                    } else if (
                                        index === 4 &&
                                        item1.value[item] !== null &&
                                        item1.value[item].length > 0
                                    ) {
                                        return `<h6 ${
                                            this.reportFontSizes.tab_child != ''
                                                ? `style="font-size:${this.reportFontSizes.tab_child}"`
                                                : ``
                                        }>Reaction To Praise - </h6><p>${item1.value[item]}</p>`;
                                    } else if (
                                        index === 5 &&
                                        item1.value[item] !== null &&
                                        item1.value[item].length > 0
                                    ) {
                                        return `<h6 ${
                                            this.reportFontSizes.tab_child != ''
                                                ? `style="font-size:${this.reportFontSizes.tab_child}"`
                                                : ``
                                        }>Most Developed Skills/Behaviors From Assessment Results - </h6><p>${
                                            item1.value[item]
                                        }</p>`;
                                    } else if (
                                        index === 6 &&
                                        item1.value[item] !== null &&
                                        item1.value[item].length > 0
                                    ) {
                                        return `<h6 ${
                                            this.reportFontSizes.tab_child != ''
                                                ? `style="font-size:${this.reportFontSizes.tab_child}"`
                                                : ``
                                        }>Least Developed Skills/Behaviors From Assessment Results - </h6><p>${
                                            item1.value[item]
                                        }</p>`;
                                    }
                                })
                                .join('');
                            return evaluationValues?.length > 0
                                ? `<div class="nameTitle"><i><h4 ${
                                      this.reportFontSizes.tab != ''
                                          ? `style="font-size:${this.reportFontSizes.tab}"`
                                          : ``
                                  }>${item1.title} :- </h4><i></div>` +
                                      evaluationValues +
                                      `<br>`
                                : '';
                        } else if (item1.title === 'Barriers') {
                            const barriersValues =
                                item1.value.hasOwnProperty('barrier') &&
                                item1.value['barrier']
                                    .map((item, index) => {
                                        return `<h6 ${
                                            this.reportFontSizes.tab_child != ''
                                                ? `style="font-size:${this.reportFontSizes.tab_child}"`
                                                : ``
                                        }>Barrier Info ${index + 1} - </h6><p>${item['barrier']}</p>`;
                                    })
                                    .join('');
                            return barriersValues?.length > 0
                                ? `<div class="nameTitle"><i><h4 ${
                                      this.reportFontSizes.tab != ''
                                          ? `style="font-size:${this.reportFontSizes.tab}"`
                                          : ``
                                  }>${item1.title} :- </h4><i></div>` +
                                      barriersValues +
                                      `<br>`
                                : '';
                        } else {
                            const skillsValues =
                                item1.value.hasOwnProperty('skill') &&
                                item1.value['skill']
                                    .map((item, index) => {
                                        return `<h6 ${
                                            this.reportFontSizes.tab_child != ''
                                                ? `style="font-size:${this.reportFontSizes.tab_child}"`
                                                : ``
                                        }>Skill Info ${index + 1} - </h6><p>${item['skill']}</p>`;
                                    })
                                    .join('');
                            return skillsValues?.length > 0
                                ? `<div class="nameTitle"><i><h4 ${
                                      this.reportFontSizes.tab != ''
                                          ? `style="font-size:${this.reportFontSizes.tab}"`
                                          : ``
                                  }>${item1.title} :- </h4><i></div>` +
                                      skillsValues +
                                      `<br>`
                                : '';
                        }
                    }
                });
                if (tabsContent.join('').length > 0) {
                    return (
                        `<div class="nameTitle"><h2 ${
                            this.reportFontSizes.panel != ''
                                ? `style="font-size:${this.reportFontSizes.panel}"`
                                : ``
                        }>${item.title}</h2></div><hr>` + tabsContent.join('')
                    );
                } else {
                    return tabsContent.join('');
                }
            })
            .join('');

        if (reportContent?.includes('ql-indent-')) {
            const indexes = [...reportContent.matchAll(new RegExp('ql-indent-', 'gi'))].map(
                (item) => item.index
            );

            indexes.map((item, index) => {
                const newIndex = [...reportContent.matchAll(new RegExp('ql-indent-', 'gi'))].map(
                    (item) => item.index
                );

                reportContent =
                    reportContent.slice(0, newIndex[index] + 12) +
                    ` style = "text-indent : ${reportContent[newIndex[index] + 10] * 3}em;"` +
                    reportContent.slice(newIndex[index] + 12, reportContent.length);
            });
        }

        const html = await htmlToPdfmake(
            reportContent.replace(/<p[^>]*><br[\/]?><[\/]?p>/g, '').replace(/<p><br[\/]?><[\/]?p>/g, '')
        );

        const documentDefinition = {
            pageMargins: [30, 60],
            header: {
                margin: 6,
                columns: [
                    {
                        image: await this.getBase64ImageFromURL('assets/images/logo.png'),
                        width: 60,
                    },
                    {
                        margin: [370, 0, 0, 0],
                        text: [
                            'Land of Goods Vocational Consulting\n referrals@cornerstone.com\n www.cornerstone.com \n 627 N Grandview Ave Suite 222 \n Daytona Beach, FL 32118',
                        ],
                        fontSize: 8,
                    },
                ],
            },
            content: [
                {
                    text: 'Vocational Assessment Report',
                    style: ['header', 'anotherStyle'],
                },
                {
                    margin: 12,
                    columns:
                        this.model.clientInfoModel.profileImageString &&
                        this.model.clientInfoModel.profileImageString?.length
                            ? [
                                  {
                                      text: [
                                          `Name: ${
                                              this.model.clientInfoModel.clientFirstName
                                          }\n Client Number: ${
                                              this.model.clientInfoModel.clientNumber
                                          }\n Age: ${
                                              new Date().getFullYear() -
                                              new Date(this.model.clientInfoModel.clientDOB).getFullYear()
                                          } Years Old \n Date Of Birth: ${
                                              new Date(this.model.clientInfoModel.clientDOB)
                                                  .toLocaleString()
                                                  .split(',')[0]
                                          } \n Date Of Report: ${new Date().toLocaleString().split(',')[0]}`,
                                      ],
                                      fontSize: 12,
                                  },
                                  {
                                      image: this.model.clientInfoModel.profileImageString,
                                      width: 120,
                                  },
                              ]
                            : [
                                  {
                                      text: [
                                          `Name: ${
                                              this.model.clientInfoModel.clientFirstName
                                          }\n Client Number: ${
                                              this.model.clientInfoModel.clientNumber
                                          }\n Age: ${
                                              new Date().getFullYear() -
                                              new Date(this.model.clientInfoModel.clientDOB).getFullYear()
                                          } Years Old\n Date Of Birth: ${
                                              new Date(this.model.clientInfoModel.clientDOB)
                                                  .toLocaleString()
                                                  .split(',')[0]
                                          } \n Date Of Report: ${new Date().toLocaleString().split(',')[0]}`,
                                      ],
                                      fontSize: 12,
                                  },
                              ],
                },
                ...html,
            ],
            styles: {
                'quill-better-table': {
                    margin: [4, 0],
                    fontSize: 12,
                },
                header: {
                    fontSize: 20,
                    bold: true,
                },
                anotherStyle: {
                    italics: true,
                    alignment: 'center',
                },
            },
        };

        if (reportContent.replace(regex, '').length > 0) {
            sessionStorage.setItem(
                'pdfDefinition',
                JSON.stringify({
                    contentLength: reportContent.replace(regex, '').length,
                    pdf: documentDefinition,
                })
            );
        } else {
            this.confirmDialog();
            sessionStorage.setItem(
                'pdfDefinition',
                JSON.stringify({
                    contentLength: 0,
                    pdf: documentDefinition,
                })
            );
        }
        this.model.clientInfoModel.assessment.assessmentData = sessionStorage.getItem('editorContent');
    }

    openPanelTitleModal(index) {
        this.panelNo = index;
        this.isVisible = true;
    }

    handleOk(): void {
        this.isVisible = false;
        sessionStorage.setItem('editorContent', JSON.stringify(this.panels));
    }

    handleCancel(): void {
        this.isVisible = false;
    }

    closeTab({ index }: { index: number }, parentIndex: number): void {
        if (parentIndex !== 4) {
            if (this.panels[parentIndex]['tabControls'].length > 1) {
                this.panels[parentIndex]['tabControls'].splice(index, 1);
                sessionStorage.setItem('editorContent', JSON.stringify(this.panels));
            }
        }
    }

    newTab(index: number): void {
        if (index !== 3 && index !== 4) {
            this.tabTitle = '';
            this.tabFullTitle = '';
            this.panelNo = index;
            this.isVisibleTab = true;
        }
    }

    tabHandleCancel(): void {
        this.isVisibleTab = false;
    }

    tabHandleOk(): void {
        this.panels[this.panelNo]['tabControls'].push({
            title: this.tabTitle,
            fullTitle: this.tabFullTitle,
            value: '',
            font: '',
            fontSize: '',
        });
        this.isVisibleTab = false;
        sessionStorage.setItem('editorContent', JSON.stringify(this.panels));
    }

    openEditTabTitleModal(index) {
        this.panelNo = index;
        this.editedTab = this.panels[index]['tabControls'];
        this.editedTabCopy = _.cloneDeep(this.editedTab);
        this.isVisibleTabEdit = true;
    }

    editTabHandleCancel(): void {
        this.isVisibleTabEdit = false;
    }

    editTabHandleOk(): void {
        this.isVisibleTabEdit = false;
        this.panels[this.panelNo]['tabControls'] = this.editedTabCopy;
        sessionStorage.setItem('editorContent', JSON.stringify(this.panels));
    }

    close() {
        this.router.navigate(['/reporting']);
    }

    previewReport() {
        //TODO Use the https://ej2.syncfusion.com/documentation/pdfviewer/how-to/create-pdfviewer-service-core
        // to create the report on server side. Then pass back the service url to the dialog.
        //this.service.createPdf(this.model).subscribe(result => {
        //this.openDialog(result.serviceURL);
        //});
        this.openDialog('');
    }

    async save() {
        await this.next(); //Create the server side model to save to the database
        if (this.model.mode == 'Add') {
            const pdfDefinition = JSON.parse(sessionStorage.getItem('pdfDefinition'));
            pdfDefinition &&
                pdfDefinition?.contentLength > 0 &&
                pdfMake.createPdf(pdfDefinition.pdf).download('pdfreport.pdf');
            this.service
                .addReport(this.model)
                .pipe(take(1))
                .subscribe((data) => {
                    this.model.reportNumber = data.reportNumber;
                    sessionStorage.removeItem('editorContent');
                    sessionStorage.removeItem('pdfDefinition');

                    this.service.sendNextEvent(data);
                }),
                (error) => {
                    this.service.sendNextEvent(error);
                };
        } else {
            this.service
                .updateReport(this.model)
                .pipe(take(1))
                .subscribe((data) => {
                    let reportId = data['reportId'];
                    this.service.sendNextEvent(data);
                }),
                (error) => {
                    this.service.sendNextEvent(error);
                };
        }
    }

    edit(element: any) {
        this.editing = true;
        setTimeout(() => {
            element.focus();
        });
    }

    openDialog(serviceUrl) {
        let dialogRef = this.dialog.open(PDFViewerComponent, {
            width: '1140px',
            height: '700px',
            maxHeight: '80vh',
            hasBackdrop: true,
            backdropClass: 'bdrop',
            disableClose: true,
            panelClass: 'overflow-dialog',
            data: { serviceUrl: serviceUrl },
        });
        //   dialogRef?.componentInstance?.onModalClose().subscribe((result) => {
        //     this.save(result)
        //
        //     }
        //   });
    }
}
