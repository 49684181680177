import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMaterialModule } from 'src/app/material.module';
import { ReportGridComponent } from '../components/reports.grid/report.grid.component';
import { ReportComponent } from '../components/reports/report.component';
import { ReportRoutingModule } from './report.routing';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AgGridModule } from 'ag-grid-angular';
import { NgZorroAntdModule } from 'src/app/zorro.module';
import { CDK_DRAG_CONFIG, DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    TextSearchService,
    TextSelectionService,
    PrintService,
    PdfViewerModule,
    AnnotationService,
    ToolbarService,
    NavigationService,
    FormDesignerService,
    FormFieldsService,
} from '@syncfusion/ej2-angular-pdfviewer';
import { PDFViewerComponent } from '../components/report.preview/pdf.viewer.component';
import { AddReportComponent } from '../components/add-report/add-report.component';
import { RowColumnModalComponent } from '../components/add-report/row-column-modal/row-column-modal.component';
import { ReportService } from '../services/report.service';
import { AlertService } from 'src/app/alerts/alert.service';
import { DashboardLayoutAllModule, DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import {
    DocumentEditorAllModule,
    DocumentEditorContainerAllModule,
    EditorHistoryService,
    EditorService,
    SearchService,
    OptionsPaneService,
    SelectionService,
    SfdtExportService,
    TextExportService,
    HyperlinkDialogService,
    FontDialogService,
    ImageResizerService,
    BulletsAndNumberingDialogService,
    StyleDialogService,
    TableDialogService,
    BordersAndShadingDialogService,
    CellOptionsDialogService,
    TablePropertiesDialog,
    PageSetupDialogService,
    ParagraphDialogService,
    WordExportService,
    TablePropertiesDialogService,
} from '@syncfusion/ej2-angular-documenteditor';
import { DropDownListAllModule } from '@syncfusion/ej2-angular-dropdowns';
import { ToolbarModule } from '@syncfusion/ej2-angular-image-editor';
import { ColorPickerModule } from '@syncfusion/ej2-angular-inputs';
import { TabModule } from '@syncfusion/ej2-angular-navigations';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { SplitButtonModule } from '@syncfusion/ej2-angular-splitbuttons';
import { AccountService } from 'src/app/login/service/account.service';

const DragConfig = {
    dragStartThreshold: 0,
    pointerDirectionChangeThreshold: 5,
    zIndex: 10000,
};
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        ReportRoutingModule,
        AngularMaterialModule,
        AgGridModule,
        NgZorroAntdModule,
        FlexLayoutModule,
        PdfViewerModule,
        DragDropModule,
        ScrollingModule,
        DialogModule,
        DashboardLayoutModule,
        DocumentEditorAllModule,
        DocumentEditorContainerAllModule,
        TabModule,
        SplitButtonModule,
        DropDownListAllModule,
        ColorPickerModule,
    ],
    declarations: [
        ReportComponent,
        ReportGridComponent,
        RowColumnModalComponent,
        PDFViewerComponent,
        AddReportComponent,
    ],

    providers: [
        { provide: CDK_DRAG_CONFIG, useValue: DragConfig },
        LinkAnnotationService,
        BookmarkViewService,
        MagnificationService,
        ThumbnailViewService,
        ToolbarService,
        NavigationService,
        TextSearchService,
        TextSelectionService,
        PrintService,
        AnnotationService,
        FormDesignerService,
        FormFieldsService,
        EditorService,
        SelectionService,
        ImageResizerService,
        BulletsAndNumberingDialogService,
        FontDialogService,
        HyperlinkDialogService,
        SfdtExportService,
        TextExportService,
        SelectionService,
        EditorService,
        EditorHistoryService,
        SearchService,
        OptionsPaneService,
        StyleDialogService,
        TableDialogService,
        ParagraphDialogService,
        WordExportService,
        PageSetupDialogService,
        BordersAndShadingDialogService,
        CellOptionsDialogService,
        TablePropertiesDialogService,
        ReportService,
        AlertService,
        AccountService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ReportModule {}
