import { Component, OnInit } from '@angular/core';
import { CaseLoadModel } from '../model/caseload.model';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
    GridApi,
    ColDef,
    GridReadyEvent,
    GridOptions,
    IDetailCellRendererParams,
    Module,
    PaginationNumberFormatterParams,
} from 'ag-grid-community';
import { BtnCellRenderer } from 'src/app/common/buttoncell.renderer';
import { AlertService } from 'src/app/alerts/alert.service';
import { ClientCaseService } from '../services/client.cases.service';
import { AccountService } from 'src/app/login/service/account.service';
import { ClientService } from 'src/app/client/services/client.services';
import { ClientsModel } from 'src/app/client/models/client.model';
import { AuthService } from 'src/app/login/service/auth.service';
import { NzUploadListType } from 'ng-zorro-antd/upload';
import * as moment from 'moment';
import { RouterLinkRendererComponent } from 'src/app/common/routerlink.renderer.component';
import { UtilityService } from 'src/app/common/utility';

var _ = require('lodash');

@Component({
    selector: 'app-clientcases',
    templateUrl: './client.cases.component.html',
    styleUrls: ['./client.cases.component.css'],
})
export class ClientCasesComponent implements OnInit {
    private gridApi!: GridApi<ClientsModel>;
    public frmClientreferral: FormGroup;
    formData = new FormData();
    loading = false;
    submitted = false;
    startDate = new Date(1990, 0, 1);
    public clientCasesData: CaseLoadModel[];
    public clientData: ClientsModel[];
    public clientCaseData: CaseLoadModel;
    public gridColumnApi;
    public columnDefs: ColDef[];
    public defaultColDef: ColDef;
    public autoGroupColumnDef: ColDef;
    public groupDefaultExpanded;
    public rowSelection;
    radioValue = 'Single';
    public role: string = '';
    public clients: any;
    checked = false;
    public paginationNumberFormatter: (params: PaginationNumberFormatterParams) => string = (
        params: PaginationNumberFormatterParams
    ) => {
        return '[' + params.value.toLocaleString() + ']';
    };
    constructor(
        private clientCaseService: ClientCaseService,
        private clientService: ClientService,
        private fb: FormBuilder,
        private authService: AuthService,
        private alertService: AlertService,
        private utilityService: UtilityService,
        public dialog: MatDialog,
        public router: Router,
        private accountService: AccountService
    ) {
        this.columnDefs = [
            {
                field: 'clientFirstName',
                flex: 1,
                sortable: true,
                // cellRendererFramework: RouterLinkRendererComponent,
                cellRenderer: 'agGroupCellRenderer',
                cellRendererParams: {
                    innerRenderer: RouterLinkRendererComponent,
                    inRouterLink: '/client',
                },
            },
            { field: 'clientNumber', sortable: true, flex: 1 },
            { field: 'email', sortable: true, flex: 1 },
            { field: 'openCases', sortable: true, flex: 1 },
            {
                field: 'createdDate',
                sortable: true,
                flex: 1,
                cellRenderer: (data) => {
                    return data ? new Date(data.value).toDateString() : '';
                },
            },
        ];
        this.defaultColDef = {
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
        };
        this.autoGroupColumnDef = {
            minWidth: 200,
        };
        this.groupDefaultExpanded = 0;
        this.rowSelection = 'single';
    }

    ngOnInit(): void {
        this.role = this.accountService?.userValue?.role;
    }
    public detailCellRendererParams: any = {
        detailGridOptions: {
            columnDefs: [
                { field: 'caseNumber' },
                { field: 'caseName' },
                { field: 'caseStatus' },
                {
                    field: 'createdDate',
                    cellRenderer: (data) => {
                        return data ? new Date(data.value).toDateString() : '';
                    },
                },
            ],
            defaultColDef: {
                flex: 1,
            },
        },
        getDetailRowData: (params) => {
            console.log(params.data);
            params.successCallback(params.data.clientCases);
        },
    } as IDetailCellRendererParams<ClientsModel, CaseLoadModel>;
    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.clientService.getAllClients().subscribe((data) => {
            this.clientData = data['body'];
            this.getClientCaseCount();
            this.sortGrid(params, 'clientFirstName', 'asc');
            let cloned = _.cloneDeep(this.clientData);
            let staffFilter = this.utilityService.getStaffFilter(cloned, this.authService.getStaffId());
            let openFilter = this.utilityService.getOpenFilter(staffFilter);
            let finalfiltered = this.utilityService.getFinalOpenFilter(openFilter);
            this.gridApi.setRowData(finalfiltered);
            this.loading = false;
            console.log(this.clientData);
        });
    }
    sortGrid(event, field, sortDir) {
        const columnState = {
            // https://www.ag-grid.com/javascript-grid-column-state/#column-state-interface
            state: [
                {
                    colId: field,
                    sort: sortDir,
                },
            ],
        };
        event.columnApi.applyColumnState(columnState);
    }

    onPageSizeChanged() {
        var value = (document.getElementById('page-size') as HTMLInputElement).value;
        this.gridApi.paginationSetPageSize(Number(value));
    }

    onFilterTextBoxChanged() {
        this.gridApi.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
    }

    private radio: any;
    onFilterRadioChanged(value: string) {
        if (value == 'All') {
            this.radio = 'All';
            let cloned = _.cloneDeep(this.clientData);
            let filtered = this.utilityService.getOpenFilter(cloned);
            let finalfiltered = this.utilityService.getFinalOpenFilter(filtered);
            this.gridApi.setRowData(finalfiltered);
        } else {
            this.radio = 'Staff';
            let cloned = _.cloneDeep(this.clientData);
            let staffFilter = this.utilityService.getStaffFilter(cloned, this.authService.getStaffId());
            let openFilter = this.utilityService.getOpenFilter(staffFilter);
            let finalfiltered = this.utilityService.getFinalOpenFilter(openFilter);
            this.gridApi.setRowData(finalfiltered);
        }
    }

    oncheckBoxChanged(value: any) {
        //Also include closed cases on a staff level
        if (this.radio == 'Staff') {
            //Staff and ShowClosed
            if (value == true) {
                let cloned = _.cloneDeep(this.clientData);
                let staffFilter = this.utilityService.getStaffFilter(cloned, this.authService.getStaffId());
                this.gridApi.setRowData(staffFilter.slice(0, 10));
            } else {
                //Staff and show only open
                //Go back to the default with only open cases
                let cloned = _.cloneDeep(this.clientData);
                let staffFilter = this.utilityService.getStaffFilter(cloned, this.authService.getStaffId());
                let openFilter = this.utilityService.getOpenFilter(staffFilter);
                let finalfiltered = this.utilityService.getFinalOpenFilter(openFilter);
                this.gridApi.setRowData(finalfiltered);
            }
        } else {
            //ALl Clients and show Closed
            if (value == true) {
                let cloned = _.cloneDeep(this.clientData);
                this.gridApi.setRowData(this.clientData.slice(0, 10));
            } else {
                //All Clients and show open
                //Go back to the default with only open cases
                let cloned = _.cloneDeep(this.clientData);
                let openFilter = this.utilityService.getOpenFilter(cloned);
                let finalfiltered = this.utilityService.getFinalOpenFilter(openFilter);
                this.gridApi.setRowData(finalfiltered);
            }
        }
    }

    getClientCaseCount() {
        for (const client of this.clientData) {
            const openCasesCount: number = client.clientCases.filter(
                (caseItem) => caseItem.caseStatus === 'Open'
            ).length;
            client.openCases = openCasesCount.toString();
        }
    }
}
