import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  VERSION,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Subscription, interval, take, takeWhile } from 'rxjs';
import {
  VendorModel,
  CompanyInformationModel,
  JobInformationModel,
  LocationInformationModel,
} from '../../models/assessment.model';
import { AssessmentService } from '../../services/assessment.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;
var _ = require('lodash');

@Component({
  selector: 'app-assessment-company-info',
  template: ` <nz-skeleton
      *ngIf="pdfUrl.length == 0 && pdfUrl !== 'No PDf Content Found'"
      [nzActive]="pdfUrl.length == 0"
    ></nz-skeleton>
    <div *ngIf="pdfUrl.length > 0 && pdfUrl !== 'No PDf Content Found'">
      <pdf-viewer
        [src]="pdfUrl"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="1"
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="false"
        style="width: 100%; height: 600px;"
      ></pdf-viewer>
    </div>
    <div *ngIf="pdfUrl.length > 0 && pdfUrl === 'No PDf Content Found'">
      {{ pdfUrl }}
    </div>

    <!-- <nz-collapse>
        <nz-collapse-panel
          style=" background: #2c677b!important; color:white"
          *ngFor="let panel of panels"
          [nzHeader]="panel.name"
          [nzActive]="panel.active"
          [nzDisabled]="panel.disabled"
          [nzExtra]="extraTpl"
        >
          <div *ngIf="panel.name !== 'Pdf Content'">
            <table style="width: 100%;text-align: initial">
              <tbody>
                <tr *ngFor="let ctrl of panel.tempControls; let i = index">
                  <td style="width: 15%;text-align: center">
                    <b>{{ ctrl.Title }}:</b>
                  </td>
                  <td>{{ ctrl.Value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="panel.name === 'Pdf Content'">
            <div *ngIf="pdfUrl.length > 0 && pdfUrl !== 'No PDf Content Found'">      
            <pdf-viewer
            [src]="pdfUrl"
            [rotation]="0"
            [original-size]="false"
            [show-all]="true"
            [fit-to-page]="false"
            [zoom]="1"
            [zoom-scale]="'page-width'"
            [stick-to-page]="false"
            [render-text]="true"
            [external-link-target]="'blank'"
            [autoresize]="true"
            [show-borders]="false"
            style="width: 100%; height: 600px;"
          ></pdf-viewer></div>
          <div *ngIf="pdfUrl.length > 0 && pdfUrl === 'No PDf Content Found'">
          {{pdfUrl}}
          </div>
          <div *ngIf="pdfUrl.length === 0">Loading...</div>
          </div>
        </nz-collapse-panel>
      </nz-collapse> -->
    <ng-template #extraTpl>
      <span
        nz-icon
        nzType="edit"
        (click)="goToScreen($event); $event.stopPropagation()"
      ></span>
    </ng-template>`,
  styles: [
    `
      [nz-form] {
        max-width: 600px;
      }

      button {
        margin-left: 8px;
      }
      :host
        ::ng-deep
        .ant-collapse
        > .ant-collapse-item
        > .ant-collapse-header {
        color: white !important;
      }
    `,
  ],
})
export class AssessmentReviewSubmitComponent implements OnInit {
  @ViewChild(FormGroupDirective, { static: true }) validateForm: FormGroup;
  @Input() title: string = '';
  @Input() model: VendorModel;
  @Input() files: any;
  @Output() assessmentOutput: EventEmitter<any> = new EventEmitter<any>();
  companyModel: CompanyInformationModel;
  jobModel: JobInformationModel;
  locationModel: LocationInformationModel;
  pdfReportInterval: any;
  mode: string = '';
  pdfUrl = '';
  loading = true;
  formatterDollar = (value: number): string => `$ ${value}`;
  parserDollar = (value: string): string => value.replace('$ ', '');
  formData = new FormData();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private service: AssessmentService
  ) {
    this.service
      .getNextEvent()
      .pipe(take(1))
      .subscribe(() => {
        this.next();
      });
  }

  panels = [
    {
      active: true,
      disabled: false,
      name: 'Pdf Content',
      tempControls: [],
    },
  ];
  async ngAfterViewInit() {
    (this.pdfReportInterval = setInterval(async () => {
      try {
        const pdfData = JSON.parse(sessionStorage.getItem('pdfDefinition'));
        if (pdfData && pdfData?.contentLength > 0) {
          await pdfMake.createPdf(pdfData.pdf).getBlob(
            (blob) => {
              const currentBlob = new Blob([blob], { type: 'application/pdf' });
              this.pdfUrl = URL.createObjectURL(currentBlob);
              clearInterval(this.pdfReportInterval);
              this.loading = false;
            },
            (error) => {
              this.loading = false;
              console.error(error);
              clearInterval(this.pdfReportInterval);
            }
          );
        } else {
          this.loading = false;
          this.pdfUrl = 'No PDf Content Found';
          clearInterval(this.pdfReportInterval);
        }
      } catch (error) {
        this.loading = false;
        console.error(error);
        clearInterval(this.pdfReportInterval);
      }
    }, 1000)),
      (error) => {
        this.loading = false;
        console.error(error);
        clearInterval(this.pdfReportInterval);
      };
  }

  ngOnInit(): void {
    this.title = this.data.title;
    this.model = this.data.model;
    this.files = this.data.files;
    this.model !== undefined && this.setPanels(this.model);
  }
  ngOnDestroy() {
    clearInterval(this.pdfReportInterval);
    this.pdfReportInterval = null;
  }
  goToScreen(event) {
    if (
      event?.currentTarget?.parentElement?.parentElement?.textContent.length > 1
    ) {
      let index = this.panels.indexOf(
        this.panels.find(
          (object) =>
            object.name ==
            event?.currentTarget?.parentElement?.parentElement?.textContent
        )
      );
      this.assessmentOutput.emit(index);
    }
  }
  setPanels(model: VendorModel) {
    this.panels.forEach((panel) => {
      if (panel.name === 'Company Information') {
        Object.entries(model.companyInformationModel).forEach((entry) => {
          panel.tempControls.push({
            Title: _.startCase(entry[0]),
            Value: entry[1],
          });
        });
      } else if (panel.name === 'Job Information') {
        Object.entries(model.jobInformationModel).forEach((entry) => {
          panel.tempControls.push({
            Title: _.startCase(entry[0]),
            Value: entry[1],
          });
        });
      } else if (panel.name === 'Locations') {
        model.locationInformationModels.forEach((location) => {
          Object.entries(location).forEach((entry) => {
            panel.tempControls.push({
              Title: _.startCase(entry[0]),
              Value: entry[1],
            });
          });
        });
      }
    });
  }

  next() {
    this.assessmentOutput.emit({ form: this.model, files: this.files });
  }
}
