import { Guid } from 'guid-typescript';
import { ErrorMessage } from 'src/app/assessment/models/assessment.model';
import { ClientsModel } from 'src/app/client/models/client.model';
import { ImageForm } from 'src/app/clientreferral/models/imageform.model';

export class ReportsModel {
    reportId?: string | null;
    reportName?: string = 'Vocational Assessment Report';
    reportTemplateName?: string = '';
    reportStatus?: string = '';
    reportNumber?: string = '';
    dateSubmitted?: string = '';
    modifiedDateSubmitted?: string = '';
    errorMessage?: ErrorMessage;
    mode?: string = '';
    clientInfoModel?: ClientsModel;
}

export class ClientInformationModel {
    clientId?: string | null;
    clientName?: string = '';
    clientNumber?: string = '';
    clientDOB?: string = '';
    caseType?: string = '';
    profileImageString: string = '';
    profileImage?: ImageForm;
    assessment?: Assessment;
}

export class Assessment {
    assessmentId?: string | null;
    assessmentData?: string = '';
}

export class CaseTypes {
    id?: string | null;
    caseType?: string = '';
}
