import { EventEmitter, Injectable, Output } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from 'src/environments/environment';
import { Forgotpass, Login, Resetpass } from '../models/login';
import { AuthenticatedResponse, StringResponse } from '../models/authenticatedresponse';
import { AuthService } from './auth.service';
import { MatDialog } from '@angular/material/dialog';
import { VendorService } from 'src/app/vendorList/services/vendor.service';
import { EventService } from 'ag-grid-community';

@Injectable({ providedIn: 'root' })
export class AccountService {
    private userSubject: BehaviorSubject<User | null>;
    public user: Observable<User | null>;
    public vendorCount: number = 0;
    private isLoading: boolean = false;
    loadingScreenObserver: Subject<boolean> = new Subject<boolean>();
    vendorCountObserver: Subject<number> = new Subject<number>();
    private env: any;
    private vendorInterval: any;
    _undefined: undefined;
    constructor(
        private router: Router,
        private http: HttpClient,
        private authService: AuthService,
        private dialogRef: MatDialog,
        private vendorService: VendorService
    ) {
        if (sessionStorage.getItem('USER') == '') {
            sessionStorage.setItem('USER', '""');
        }
        this.userSubject = new BehaviorSubject(JSON.parse(sessionStorage.getItem('USER')!));
        this.user = this.userSubject.asObservable();
        this.env = environment;
        this.vendorCountObserver.subscribe((value) => (this.vendorCount = value));
        this.loadingScreenObserver.subscribe((value) => (this.isLoading = value));
        if (this.authService.isLoggedIn() && typeof this.vendorInterval === 'undefined') {
            this.setVendorInterval();
        }
    }

    public get userValue() {
        return this.userSubject.value;
    }

    isNullUndefined(value): boolean {
        return value === null || value === undefined;
    }
    public setVendorInterval() {
        (this.vendorInterval = setInterval(() => {
            try {
                if (typeof this.user !== 'undefined') {
                    this.vendorService.checkNewVendors().subscribe((x) => {
                        let numb = x.filter((y) => y.isNew == 1).length;
                        this.vendorCountObserver.next(numb);
                    });
                }
            } catch (error) {
                console.error(error);
                clearInterval(this.vendorInterval);
            }
        }, 180000)),
            (error) => {
                console.error(error);
                clearInterval(this.vendorInterval);
            };
    }
    login(login: Login): Observable<User> {
        return this.http
            .post<User>(`${this.env['apiUrl']}/auth/login`, login, {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            })
            .pipe(
                map((user) => {
                    //Make sure local storage is cleared again.
                    sessionStorage.clear();

                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    const token = user.token;
                    const refreshToken = user.refreshToken;
                    this.authService.login(
                        user.role,
                        JSON.stringify(user),
                        user.token,
                        user.refreshToken,
                        user.staffId,
                        user.staffNumber
                    );

                    clearInterval(this.vendorInterval);
                    this.setVendorInterval();

                    this.userSubject.next(user);
                    return user;
                })
            );
    }

    logout() {
        this.dialogRef.closeAll();
        // remove user from local storage and set current user to null
        sessionStorage.clear();
        clearInterval(this.vendorInterval);
        return this.authService
            .logout()
            .pipe(take(1))
            .subscribe((res) => {
                //this.userSubject.next(null);
                this.router.navigate(['/account/login']);
                return of({ success: res.success, role: res.role });
            });
    }
    forgotpass(forgotpass: Forgotpass): Observable<User> {
        return this.http
            .post<User>(`${this.env['apiUrl']}/auth/forgotPass`, forgotpass, {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            })
            .pipe(
                map((user) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes

                    return user;
                })
            );
    }
    resetpass(resetpass: Resetpass): Observable<StringResponse> {
        return this.http
            .post<StringResponse>(`${this.env['apiUrl']}/auth/resetPass`, resetpass, {
                headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
            })
            .pipe(
                map((StringResponse) => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes

                    return StringResponse;
                })
            );
    }

    register(user: User) {
        return this.http.post(`${environment.apiUrl}/users/register`, user);
    }

    getAll() {
        return this.http.get<User[]>(`${environment.apiUrl}/users`);
    }

    getById(id: string) {
        return this.http.get<User>(`${environment.apiUrl}/users/${id}`);
    }

    update(id: string, params: any) {
        return this.http.put(`${environment.apiUrl}/users/${id}`, params).pipe(
            map((x) => {
                // update stored user if the logged in user updated their own record
                if (id == this.userValue?.id) {
                    // update local storage
                    const user = { ...this.userValue, ...params };
                    sessionStorage.setItem('user', JSON.stringify(user));

                    // publish updated user to subscribers
                    this.userSubject.next(user);
                }
                return x;
            })
        );
    }

    delete(id: string) {
        return this.http.delete(`${environment.apiUrl}/users/${id}`).pipe(
            map((x) => {
                // auto logout if the logged in user deleted their own record
                if (id == this.userValue?.id) {
                    this.logout();
                }
                return x;
            })
        );
    }

    public setLoading(value) {
        this.loadingScreenObserver.next(value);
    }
}
