import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    getDate18Years(): Date {
        let today = new Date();
        let month = today.getMonth(); //in three months
        let year = today.getUTCFullYear() - 18;
        let day = today.getDay();
        return new Date(year, month, day);
    }

    getReportStatusValues(): any {
        return ['Active', 'Inactive', 'Pending', 'Closed'];
    }

    getBoolean(value: any): boolean {
        switch (value?.toLowerCase()) {
            case 'true':
            case '1':
            case 'on':
            case 'yes':
            case 'y':
            case '':
                return true;
            default:
                return false;
        }
    }

    getStaffFilter(cloned: any, staffId): any {
        return cloned.filter((x) => x.staffId == staffId);
    }

    getOpenFilter(cloned: any): any {
        return cloned.map((i) => {
            i.clientCases = i.clientCases.filter((c) => c.caseStatus != 'Closed');
            return i;
        });
    }

    getFinalOpenFilter(openFilter: any): any {
        return openFilter.filter((el) => el.clientCases && el.clientCases.length > 0);
    }

    //Will send back true/false if task is overdue. This will turn task red if true
    isTaskOverDue(taskDueDate) {
        let taskDate = moment(taskDueDate, 'MM/DD/YYYY');
        var now = moment(new Date(), 'MM/DD/YYYY');

        if (now > taskDate) {
            // date is past
            return true;
        } else {
            // date is future
            return false;
        }
    }

    //Will send back true/false if task is 7 days coming. This will turn task yellow if true
    isTaskComingDue(taskDueDate) {
        var compareDate = moment(taskDueDate, 'MM/DD/YYYY hh:mm:ss');
        var startDate = moment(new Date(), 'MM/DD/YYYY hh:mm:ss');
        var endDate = moment(new Date(), 'MM/DD/YYYY hh:mm:ss').add(7, 'days');
        return compareDate.isBetween(startDate, endDate, 'days', '[]');
    }

    //Will send back the number of days remaining for each task
    CalculateTaskDays(taskDueDate) {
        let taskDate = moment(taskDueDate, 'MM/DD/YYYY');
        var now = moment(new Date(), 'MM/DD/YYYY');
        return now.to(taskDate, true);
    }

    ToTitleCase(word) {
        return word.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
    }

    getAddressObject(address_components) {
        var ShouldBeComponent = {
            home: ['street_number'],
            postal_code: ['postal_code'],
            street: ['street_address', 'route'],
            region: [
                'administrative_area_level_1',
                'administrative_area_level_2',
                'administrative_area_level_3',
                'administrative_area_level_4',
                'administrative_area_level_5',
            ],
            county: ['administrative_area_level_2'],
            city: [
                'locality',
                'sublocality',
                'sublocality_level_1',
                'sublocality_level_2',
                'sublocality_level_3',
                'sublocality_level_4',
            ],
            country: ['country'],
        };

        var address = {
            home: '',
            postal_code: '',
            street: '',
            region: '',
            city: '',
            county: '',
            country: '',
        };
        address_components.forEach((component) => {
            for (var shouldBe in ShouldBeComponent) {
                if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
                    if (shouldBe === 'country' || shouldBe === 'region') {
                        address[shouldBe] = component.short_name;
                    } else {
                        address[shouldBe] = component.long_name;
                    }
                }
            }
        });
        return address;
    }
    getStateList() {}

    getLanguageList() {}

    getIdentityList() {}
}
