// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep input.ngx-file-drop__file-input.ng-star-inserted {
  display: none !important;
}

:host ::ng-deep .ant-form input[type="file"] {
  display: none !important;
}

:host ::ng-deep.anticon.anticon-delete {
  color: red !important;
}
:host ::ng-deep.anticon.anticon-cloud-download {
  color: blue !important;
}
:host ::ng-deep.anticon:hover {
  cursor: pointer !important;
}

button {
  margin-left: 8px;
}
.table {
  border-collapse: collapse;
  border: 1px solid #ddd;
  background-color: transparent;
}
`, "",{"version":3,"sources":["webpack://./src/app/reportsScreen/modal.forms/assessmentreport.modal.component.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;AAC1B;;AAEA;EACE,qBAAqB;AACvB;AACA;EACE,sBAAsB;AACxB;AACA;EACE,0BAA0B;AAC5B;;AAEA;EACE,gBAAgB;AAClB;AACA;EACE,yBAAyB;EACzB,sBAAsB;EACtB,6BAA6B;AAC/B","sourcesContent":[":host ::ng-deep input.ngx-file-drop__file-input.ng-star-inserted {\n  display: none !important;\n}\n\n:host ::ng-deep .ant-form input[type=\"file\"] {\n  display: none !important;\n}\n\n:host ::ng-deep.anticon.anticon-delete {\n  color: red !important;\n}\n:host ::ng-deep.anticon.anticon-cloud-download {\n  color: blue !important;\n}\n:host ::ng-deep.anticon:hover {\n  cursor: pointer !important;\n}\n\nbutton {\n  margin-left: 8px;\n}\n.table {\n  border-collapse: collapse;\n  border: 1px solid #ddd;\n  background-color: transparent;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
