import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CalendarComponent } from './calendar/calendar.component';
import { MailComponent } from './mail/mail.component';
import { JobpostComponent } from './jobpost/jobpost.component';
import { ClientReferralComponent } from './clientreferral/clientreferral.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationComponent } from './notification/notification.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './login/service/authguard';
import { JwtModule } from '@auth0/angular-jwt';
import { AngularMaterialModule } from './material.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogComponent } from './common/dialog.component';
import { ClientReferralModalComponent } from './clientreferral/modal/clientreferral.component.modal';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { AddCustomerComponent } from './apptemp/apptemp.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgZorroAntdModule } from './zorro.module';
import { en_US, NZ_DATE_LOCALE, NZ_I18N } from 'ng-zorro-antd/i18n';
import { NZ_ICONS } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import * as AllIcons from '@ant-design/icons-angular/icons';
import { AssessmentModalComponent } from './assessment/modal/assessmentmodal.component';
import { AssessmentCompanyFormComponent } from './assessment/modal/modalforms/assessment.companyinfoform.component';
import { PortalModule } from '@angular/cdk/portal';
import { AssessmentJobsFormComponent } from './assessment/modal/modalforms/assessment.jobsform.component';
import { AssessmentLocationFormComponent } from './assessment/modal/modalforms/assessment.locationform.component';
import { AssessmentReviewSubmitComponent } from './assessment/modal/modalforms/assessment.reviewsubmitform.component';
import { AssessmentSubmitComponent } from './assessment/modal/modalforms/assessment.submit.component';
import { AlertService } from './alerts/alert.service';
import { AccountService } from './login/service/account.service';
import { VendorComponent } from './vendorList/vendorlist.component';
import { AgGridModule } from 'ag-grid-angular';
import { NgxFileDropModule } from 'ngx-file-drop';
import { VendorService } from './vendorList/services/vendor.service';
import { ReportsComponent } from './reportsScreen/reports.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { AssessmentReportModalComponent } from './reportsScreen/modal.forms/assessmentreport.modal.component';
import { ReportModalComponent } from './reportsScreen/modal.base/reportmodal.base.component';
import { FileSizePipe } from './common/pipes/filesize.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { BtnCellArchiveRenderer } from './common/buttoncell.archive.renderer';
import { BtnCellRenderer } from './common/buttoncell.renderer';
import { FileAttachmentService } from './common/fileattachment.service';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './users/modal/user.component';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { provideUserIdleConfig } from 'angular-user-idle';
import { enUS } from 'date-fns/locale';
import { NzI18nService } from 'ng-zorro-antd/i18n';
import { RouterModule } from '@angular/router';
import { RouterLinkRendererComponent } from './common/routerlink.renderer.component';
import { ClientreferralService } from './clientreferral/services/clientreferral.service';
import { ClientCasesComponent } from './cases/components/client.cases.component';
import { ClientCaseService } from './cases/services/client.cases.service';
import { ClientService } from './client/services/client.services';
import { ClientGridComponent } from './client/components/clientgrid/client.grid.component';
import { ClientComponent } from './client/components/client/client.component';
import { MomentModule } from 'ngx-moment';
import { StaffGridComponent } from './staff/components/staff.admin.grid/staff.admin.grid.component';
import { StaffService } from './staff/services/staff.service';
import { UserService } from './users/services/user.service';
import { StaffComponent } from './staff/components/staff/staff.component';
import {
    DetailRowService,
    ExcelExportService,
    FilterService,
    GridAllModule,
    GroupService,
    PageService,
    PdfExportService,
    SortService,
    ToolbarService,
} from '@syncfusion/ej2-angular-grids';
import {
    DatePickerAllModule,
    DatePickerModule,
    DateTimePickerAllModule,
    MaskedDateTimeService,
    TimePickerAllModule,
} from '@syncfusion/ej2-angular-calendars';
import {
    ButtonModule,
    CheckBoxAllModule,
    RadioButtonModule,
    SpeedDialModule,
} from '@syncfusion/ej2-angular-buttons';
import {
    MaskedTextBoxModule,
    NumericTextBoxAllModule,
    TextBoxAllModule,
    TextBoxModule,
} from '@syncfusion/ej2-angular-inputs';
import {
    DropDownListAllModule,
    DropDownListModule,
    MultiSelectAllModule,
} from '@syncfusion/ej2-angular-dropdowns';
import { ListViewAllModule } from '@syncfusion/ej2-angular-lists';
import { ReportRoutingModule } from './reports/module/report.routing';
import { ReportModule } from './reports/module/report.module';
import { TaskRoutingModule } from './tasks/module/tasks.routing';
import { UploadImageComponent } from './core/upload-image/upload-image.component';
import { UploadFileComponent } from './core/upload-file/upload-file.component';
import { UtilityService } from './common/utility';
import { DateMaskInputDirective } from './common/directives/dateMaskInput';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { CustomDateAdapter } from './common/directives/custom.date.adapter';
import { ReportService } from './reports/services/report.service';
import { MaskitoModule } from '@maskito/angular';
import {
    MenuAllModule,
    SidebarModule,
    ToolbarAllModule,
    TreeViewAllModule,
} from '@syncfusion/ej2-angular-navigations';
import { DashboardLayoutModule } from '@syncfusion/ej2-angular-layouts';
import { SidebarHeaderComponent } from './header-sidenav/components/header.sidenav.component';
import { DashboardComponent } from './dashboard/components/dashboard.component';
import { TaskModule } from './tasks/module/tasks.module';
import { ClientReportModalComponent } from './client/modal/client.report.modal.component';
import { ToastModule } from '@syncfusion/ej2-angular-notifications';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';
import {
    ScheduleAllModule,
    RecurrenceEditorAllModule,
    WeekService,
    AgendaService,
    DayService,
    MonthService,
    WorkWeekService,
} from '@syncfusion/ej2-angular-schedule';

export function tokenGetter() {
    return localStorage.getItem('JWT');
}
const antDesignIcons = AllIcons as {
    [key: string]: IconDefinition;
};
const icons: IconDefinition[] = Object.keys(antDesignIcons).map((key) => antDesignIcons[key]);

@NgModule({
    declarations: [
        AppComponent,
        StaffComponent,
        StaffGridComponent,
        ClientComponent,
        ClientGridComponent,
        HeaderComponent,
        FooterComponent,
        CalendarComponent,
        DashboardComponent,
        MailComponent,
        ClientCasesComponent,
        JobpostComponent,
        ClientReferralComponent,
        SettingsComponent,
        NotificationComponent,
        AssessmentComponent,
        DialogComponent,
        ClientReferralModalComponent,
        AddCustomerComponent,
        UserComponent,
        AssessmentComponent,
        AssessmentModalComponent,
        AssessmentCompanyFormComponent,
        AssessmentLocationFormComponent,
        AssessmentJobsFormComponent,
        AssessmentReviewSubmitComponent,
        AssessmentSubmitComponent,
        VendorComponent,
        AssessmentReportModalComponent,
        ReportsComponent,
        ReportModalComponent,
        FileSizePipe,
        BtnCellArchiveRenderer,
        BtnCellRenderer,
        UsersComponent,
        RouterLinkRendererComponent,
        UploadImageComponent,
        UploadFileComponent,
        SidebarHeaderComponent,
        ClientReportModalComponent,
    ],
    imports: [
        ScheduleAllModule,
        DatePickerAllModule,
        TimePickerAllModule,
        DateTimePickerAllModule,
        RecurrenceEditorAllModule,
        ToolbarAllModule,
        TextBoxAllModule,
        RadioButtonModule,
        MenuAllModule,
        DropDownListModule,
        ButtonModule,
        TreeViewAllModule,
        ListViewAllModule,
        MaskitoModule,
        DashboardLayoutModule,
        ButtonModule,
        RadioButtonModule,
        SidebarModule,
        CheckBoxAllModule,
        MaskedTextBoxModule,
        DatePickerModule,
        DropDownListAllModule,
        NumericTextBoxAllModule,
        TextBoxAllModule,
        MultiSelectAllModule,
        TextBoxModule,
        SpeedDialModule,
        GridAllModule,
        MomentModule,
        RouterModule,
        NgxMaskDirective,
        NgxMaskPipe,
        CommonModule,
        FontAwesomeModule,
        BrowserModule,
        PortalModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        FormsModule,
        AppRoutingModule,
        ReportModule,
        ReportRoutingModule,
        TaskModule,
        TaskRoutingModule,
        ToastModule,
        AgGridModule,
        AngularMaterialModule,
        NgZorroAntdModule,
        FlexLayoutModule,
        PdfViewerModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        HttpClientModule,
        NgxFileDropModule,
        MatTabsModule,
        MatExpansionModule,
        NzDropDownModule,
        NgbDropdownModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
                allowedDomains: ['localhost:7047', 'myworkatcornerstone.com'],
                disallowedRoutes: [],
            },
        }),
        NgxGpAutocompleteModule.forRoot({
            loaderOptions: {
                apiKey: 'AIzaSyD5WvVJfIZPvco_nEIU2VM3cSNyjWuzX5A',
                libraries: ['places'],
            },
        }),
    ],
    providers: [
        AuthGuard,
        UtilityService,
        AlertService,
        AccountService,
        VendorService,
        FileAttachmentService,
        ClientreferralService,
        ClientCaseService,
        ClientService,
        StaffService,
        UserService,
        ReportService,
        PageService,
        SortService,
        FilterService,
        GroupService,
        DetailRowService,
        PdfExportService,
        ExcelExportService,
        ToolbarService,
        MaskedDateTimeService,
        DayService,
        WeekService,
        WorkWeekService,
        MonthService,
        AgendaService,
        provideNgxMask(),
        provideUserIdleConfig({ idle: 600, timeout: 300, ping: 120 }),
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: NZ_I18N, useValue: en_US },
        { provide: NZ_ICONS, useValue: icons },
        { provide: NZ_DATE_LOCALE, useValue: enUS },
        { provide: MAT_DATE_LOCALE, useValue: 'en-US' },
        { provide: DateAdapter, useClass: CustomDateAdapter },
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [AppComponent],
    exports: [NgZorroAntdModule, AngularMaterialModule],
})
export class AppModule {
    constructor(private i18n: NzI18nService) {
        library.add(fab, far, fas);
        this.i18n.setDateLocale(enUS);
    }
}
