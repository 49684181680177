// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
            :host ::ng-deep.anticon:hover {
                cursor: pointer !important;
            }
            :host ::ng-deep.anticon.anticon-delete {
                color: red !important;
            }
        `, "",{"version":3,"sources":["webpack://./src/app/common/buttoncell.archive.renderer.ts"],"names":[],"mappings":";YACY;gBACI,0BAA0B;YAC9B;YACA;gBACI,qBAAqB;YACzB","sourcesContent":["\n            :host ::ng-deep.anticon:hover {\n                cursor: pointer !important;\n            }\n            :host ::ng-deep.anticon.anticon-delete {\n                color: red !important;\n            }\n        "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
