import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { MessageService } from 'src/app/common/message.service';
import { environment } from 'src/environments/environment';
import { CaseLoadModel } from '../model/caseload.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class ClientCaseService {
    http: HttpClient;
    clientUrl: string = environment.apiUrl + '/ClientCases';

    constructor(httpClient: HttpClient, private messageService: MessageService) {
        this.http = httpClient;
    }

    getAllClientCases(): Observable<HttpResponse<CaseLoadModel[]>> {
        return this.http
            .get<CaseLoadModel[]>(this.clientUrl + '/GetAll', {
                observe: 'response',
            })
            .pipe(
                catchError((err) => {
                    this.handleError<CaseLoadModel[]>('Updloading Form', err);
                    throw 'Error in getClientReferral. Details: ' + err;
                })
            );
    }

    public uploadReferralAttachment(formData: any, clientId: any): Observable<any> {
        let options = {
            headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
        };
        return this.http.post<any>(this.clientUrl + `/Upload/?id=${clientId}`, formData).pipe(
            catchError((err) => {
                this.handleError<CaseLoadModel[]>('Updloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public getReferralAttachment(clientId: any): Observable<any> {
        return this.http.post<FormData>(this.clientUrl + `/GetReferralAttachment?id=${clientId}`, {}).pipe(
            catchError((err) => {
                this.handleError<CaseLoadModel[]>('Downloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public AddClientCase(CaseLoadModel: CaseLoadModel): Observable<CaseLoadModel[]> {
        return this.http.post<CaseLoadModel[]>(this.clientUrl, CaseLoadModel, httpOptions).pipe(
            catchError((err) => {
                this.handleError<CaseLoadModel[]>('Add', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public updateCase(CaseLoadModel: CaseLoadModel): Observable<CaseLoadModel[]> {
        return this.http
            .post<CaseLoadModel[]>(
                this.clientUrl + '/UpdateClientCases?id=' + CaseLoadModel.caseId,
                CaseLoadModel,
                httpOptions
            )
            .pipe(
                catchError((err) => {
                    this.handleError<CaseLoadModel[]>('Update', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    public deactivateClientCases(CaseLoadModel: CaseLoadModel): Observable<CaseLoadModel[]> {
        return this.http
            .delete<CaseLoadModel[]>(
                `${this.clientUrl} /DeactivateClientCases?id=${CaseLoadModel.caseId}`,
                httpOptions
            )
            .pipe(
                catchError((err) => {
                    this.handleError<CaseLoadModel[]>('Delete', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    getClientCasesById(id: number): Observable<CaseLoadModel> {
        const url = `${this.clientUrl}/GetClientCasesById?id=${id}`;
        return this.http.get<CaseLoadModel>(url).pipe(
            tap((_) => this.log(`fetched hero id=${id}`)),
            catchError(this.handleError<CaseLoadModel>(`getClient id=${id}`))
        );
    }

    getClientCasesByStaffId(id: string): Observable<CaseLoadModel> {
        const url = `${this.clientUrl}/GetClientCasesByStaffId?id=${id}`;
        return this.http.get<CaseLoadModel>(url).pipe(
            tap((_) => this.log(`fetched hero id=${id}`)),
            catchError(this.handleError<CaseLoadModel>(`getClient id=${id}`))
        );
    }

    private log(message: string) {
        this.messageService.add(`ClientReferralService: ${message}`);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
