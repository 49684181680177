// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      [nz-form] {
        max-width: 600px;
      }

      button {
        margin-left: 8px;
      }
      @media only screen and (max-width: 400px) and (min-width: 200px) {
        .title-modal {
          font-size: 9px;
        }
      }

      @media only screen and (max-width: 700px) and (min-width: 401px) {
        .title-modal {
          font-size: 16px;
        }
      }
    `, "",{"version":3,"sources":["webpack://./src/app/assessment/modal/modalforms/assessment.jobsform.component.ts"],"names":[],"mappings":";MACM;QACE,gBAAgB;MAClB;;MAEA;QACE,gBAAgB;MAClB;MACA;QACE;UACE,cAAc;QAChB;MACF;;MAEA;QACE;UACE,eAAe;QACjB;MACF","sourcesContent":["\n      [nz-form] {\n        max-width: 600px;\n      }\n\n      button {\n        margin-left: 8px;\n      }\n      @media only screen and (max-width: 400px) and (min-width: 200px) {\n        .title-modal {\n          font-size: 9px;\n        }\n      }\n\n      @media only screen and (max-width: 700px) and (min-width: 401px) {\n        .title-modal {\n          font-size: 16px;\n        }\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
