import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
    ColDef,
    GridApi,
    GridReadyEvent,
    ICellRendererParams,
    PaginationNumberFormatterParams,
} from 'ag-grid-community';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, Observable, pipe, tap } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ComponentPortal } from '@angular/cdk/portal';
import { Router } from '@angular/router';
import { AddCustomerComponent } from 'src/app/apptemp/apptemp.component';
import { ClientReferralModalComponent } from 'src/app/clientreferral/modal/clientreferral.component.modal';
import { ClientreferralModel } from 'src/app/clientreferral/models/clientreferral.model';
import { ImageForm } from 'src/app/clientreferral/models/imageform.model';
import { RouterLinkRendererComponent } from 'src/app/common/routerlink.renderer.component';
import { AlertService } from 'src/app/alerts/alert.service';
import { ClientService } from '../../services/client.services';
import { ClientsModel } from '../../models/client.model';
import { Query, DataManager, WebApiAdaptor } from '@syncfusion/ej2-data';
import { StaffService } from 'src/app/staff/services/staff.service';
import { StaffModel } from 'src/app/staff/model/staff.model';
import moment from 'moment';

const rowDataGetter = function (params) {
    return params.data;
};
@Component({
    selector: 'app-clientgrid',
    templateUrl: './client.grid.component.html',
    styleUrls: ['./client.grid.component.css'],
})
export class ClientGridComponent implements OnInit {
    private gridApi!: GridApi<ClientsModel>;
    public frmClientreferral: FormGroup;
    formData = new FormData();
    loading = false;
    submitted = false;
    startDate = new Date(1990, 0, 1);
    public clientGridData: ClientsModel[];
    public clientGridModel: any;
    public gridColumnApi;
    public columnDefs: ColDef[];
    public defaultColDef: ColDef;
    public autoGroupColumnDef: ColDef;
    public groupDefaultExpanded;
    public rowSelection;
    public staffData: StaffModel[];
    portal: ComponentPortal<any>;
    public paginationNumberFormatter: (params: PaginationNumberFormatterParams) => string = (
        params: PaginationNumberFormatterParams
    ) => {
        return '[' + params.value.toLocaleString() + ']';
    };
    constructor(
        public clientService: ClientService,
        private fb: FormBuilder,
        private alertService: AlertService,
        public dialog: MatDialog,
        public router: Router,
        private staffService: StaffService
    ) {
        this.columnDefs = [
            {
                field: 'clientName',
                flex: 1,
                sortable: true,
                cellRenderer: RouterLinkRendererComponent,
                cellRendererParams: {
                    inRouterLink: '/client',
                },
            },
            { field: 'clientNumber', sortable: true, flex: 1 },
            {
                headerName: 'Number of Active Reports',
                field: 'clientReports',
                sortable: true,
                flex: 1,
            },
            { field: 'isActive', sortable: true, flex: 1 },
            {
                headerName: 'Assigned Staff',
                field: 'staff',
                sortable: true,
                flex: 1,
            },

            {
                headerName: 'Modified Date',
                field: 'modifiedDate',
                sortable: true,
                flex: 1,
                cellRenderer: (data) => {
                    return data ? moment(data.value).format('MM/DD/YYYY hh:mm:ss a') : '';
                },
            },
        ];
        this.defaultColDef = {
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
        };
        this.autoGroupColumnDef = {
            minWidth: 200,
        };
        this.groupDefaultExpanded = 1;
        this.rowSelection = 'single';
    }

    ngOnInit(): void {
        this.loading = true;
    }

    onPageSizeChanged() {
        var value = (document.getElementById('page-size') as HTMLInputElement).value;
        this.gridApi.paginationSetPageSize(Number(value));
    }

    onSelectionChanged() {
        var selectedRows = this.gridApi.getSelectedRows();
        document.querySelector('#selectedRows').innerHTML =
            selectedRows.length === 1 ? selectedRows[0].clientFirstName : '';
    }

    onFilterTextBoxChanged() {
        this.gridApi.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
    }

    onPrintQuickFilterTexts() {
        this.gridApi.forEachNode(function (rowNode, index) {
            console.log('Row ' + index + ' quick filter text is ' + rowNode.quickFilterAggregateText);
        });
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.staffService.getAllStaff().subscribe((x) => {
            this.staffData = x['body'];

            this.clientService.getAllClients().subscribe((data) => {
                this.clientGridData = data['body'];
                console.log('staff', this.clientGridData);
                this.clientGridModel = data['body'].map((item) => ({
                    clientName: `${item.clientLastName}, ${item.clientFirstName} `,
                    clientNumber: item.clientNumber,
                    clientId: item.clientId,
                    clientReports: item?.clientReports?.length,
                    isActive: item.isActive == 'false' ? 'Inactive' : 'Active',
                    modifiedDate: item.modifiedDate
                        ? new Date(item.modifiedDate)
                        : new Date(item.createdDate),
                    staff: this.getStaffMapping(item),
                }));

                this.sortGrid(params, 'clientName', 'asc');
                this.gridApi.setRowData(this.clientGridModel.slice(0, 10));
                params.api!.setRowData(this.clientGridModel.slice(0, 10));
                this.loading = false;
            });
        });
    }

    sortGrid(event, field, sortDir) {
        const columnState = {
            // https://www.ag-grid.com/javascript-grid-column-state/#column-state-interface
            state: [{ colId: 'modifiedDate', sort: 'desc', sortIndex: 0 }],
        };
        event.columnApi.applyColumnState(columnState);
    }

    getStaffMapping(item: ClientsModel): any {
        let fName = '';
        let lName = '';
        if (this.staffData.length > 0) {
            fName = this.staffData.find((x) => x.staffId == item.staffId)?.firstName || '';
            lName = this.staffData.find((x) => x.staffId == item.staffId)?.lastName || '';
        }
        return `${fName} ${lName}`;
    }
}
