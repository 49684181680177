import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subscription, fromEvent } from 'rxjs';
import { StaffModel } from '../../model/staff.model';
import { StaffService } from '../../services/staff.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/alerts/alert.service';
import { queryParams } from '@syncfusion/ej2-base';
import moment from 'moment';
import { formatDate } from '@angular/common';
import { stateList } from '../../../common/json/stateList';
import { countries } from '../../../common/json/countryList';
import { languages } from '../../../common/json/languageList';
import type { MaskitoElementPredicate, MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import { maskitoDateOptionsGenerator } from '@maskito/kit';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxGpAutocompleteDirective, NgxGpAutocompleteOptions } from '@angular-magic/ngx-gp-autocomplete';
import { UtilityService } from 'src/app/common/utility';

@Component({
    selector: 'app-staff',
    templateUrl: './staff.component.html',
    styleUrls: ['./staff.component.css'],
})
export class StaffComponent implements OnInit, AfterViewInit {
    @ViewChild('myForm') form: any;
    @ViewChild('deactivateBox') dbox: ElementRef;
    @ViewChild('ngxPlaces') placesRef: NgxGpAutocompleteDirective;
    options: NgxGpAutocompleteOptions = {
        componentRestrictions: { country: ['US'] },
        types: ['geocode'],
    };
    isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isLoading: boolean;
    validateForm: FormGroup;
    stateList: any = stateList;
    countryList: any = countries;
    languageList: any = languages;
    private unsubscribe: Subscription[] = [];
    startDate = new Date(1990, 0, 1);
    public staffModel = new StaffModel();
    staffId: any;
    staffMember: string = '';
    memberName: string = '';
    checked = false;
    Add: boolean;
    dateofbirth18: Date;
    dateFormat = 'MM/dd/yyyy';
    clientStatus: string = 'Deactivate Staff Member';
    date;
    public disabledDate = (current: Date): boolean => current > new Date();
    min = new Date();
    public enableMaskSupport: boolean = true;
    today = new Date();
    maskOptions: MaskitoOptions = maskitoDateOptionsGenerator({
        mode: 'mm/dd/yyyy',
        separator: '/',
        max: new Date(),
    });
    readonly elementPredicate: MaskitoElementPredicateAsync = async (host) =>
        new Promise((resolve) =>
            // next macro task
            setTimeout(() => resolve(host.querySelector<HTMLInputElement>('input')!))
        );
    constructor(
        public dialog: MatDialog,
        public staffService: StaffService,
        private cdr: ChangeDetectorRef,
        private route: ActivatedRoute,
        private alertService: AlertService,
        private router: Router,
        private utility: UtilityService
    ) {
        this.staffId = this.route.snapshot.queryParams['staff_id'];

        this.dateofbirth18 = new Date(
            this.today.getFullYear() - 18,
            this.today.getMonth(),
            this.today.getDate()
        );
    }

    ngOnInit(): void {
        if (!this.staffId) {
            this.Add = true;
            this.staffMember = 'Add New Staff Member';
            this.staffModel = new StaffModel();
            console.log(this.staffModel);
        } else {
            this.Add = false;
            this.staffService.getStaffById(this.staffId).subscribe((data) => {
                console.log(data);
                this.staffModel = data;
                this.setDateFields();
                this.staffMember = 'Profile Details for ' + `${data.firstName} ${data.lastName}`;
                this.memberName = `${data.firstName} ${data.lastName}`;
                this.checked = this.staffModel.isActive == 'N' ? true : false;
                if (this.checked) {
                    this.clientStatus = this.memberName + ' is Deactivated';
                } else {
                    this.clientStatus = 'Deactivate ' + this.memberName;
                }
                this.form?.disable();
            });
        }
    }

    ngAfterViewInit(): void {}
    onLoad(args: any) {
        /*Date need to be disabled*/
        if (args.date.getDay() > this.today) {
            args.isDisabled = true;
        }
    }
    setActive(event) {
        if (this.dbox.nativeElement.checked) {
            this.clientStatus = this.memberName + ' is Deactivated';
        } else {
            this.clientStatus = 'Deactivate ' + this.memberName;
        }
        this.checked = !this.checked;
        this.dbox.nativeElement.checked = this.checked;
        this.checked == true ? (this.staffModel.isActive = 'N') : (this.staffModel.isActive = 'Y');
        this.checked == true ? this.form?.disable() : this.form?.enable();
    }
    saveChanges() {
        this.setDateFields();
        console.log('Outgoing', this.staffModel);
        this.checked == true ? (this.staffModel.isActive = 'N') : 'Y';
        if (this.Add == true) {
            this.addStaff();
        } else {
            this.updateStaff();
        }
    }

    addStaff() {
        this.staffService.AddStaff(this.staffModel).subscribe((data) => {
            this.alertService.success('Changes were saved successfully');
            this.router.navigate(['/staff'], { queryParams: { staff_id: this.staffModel.staffId } });
        }),
            (error) => {
                console.error(error);
                this.alertService.error('There was an error while saving changes');
            };
        this.isLoading$.next(true);
    }

    updateStaff() {
        this.staffModel.modifiedDate = new Date();
        this.staffService.updateStaff(this.staffModel).subscribe((data) => {
            this.alertService.success('Changes were saved successfully');
            location.reload();
        }),
            (error) => {
                console.error(error);
                this.alertService.error('There was an error while saving changes');
            };
        this.isLoading$.next(true);
        setTimeout(() => {
            this.isLoading$.next(false);
        }, 1500);
    }

    close() {
        this.router.navigate(['/staffgrid']);
    }

    deactivateStaff() {
        this.staffModel.isActive = 'N';
    }
    ngOnDestroy() {
        this.unsubscribe.forEach((sb) => sb.unsubscribe());
    }

    generateStaffId() {
        let newNumber = Math.floor(Math.random() * 899999 + 100000);
        this.staffModel.staffNumber = newNumber.toString();
    }

    isLeapYear(year) {
        return !((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0);
    }

    format(val) {
        const len = val.length;
        if (!val) return '';

        if (len === 1 && !['0', '1'].includes(val[0])) {
            return '';
        } else if (
            (len === 2 && val[0] !== '0' && !['0', '1', '2'].includes(val[1])) ||
            val.substr(0, 2) === '00'
        ) {
            return val.substr(0, 1);
        } else if (len === 3) {
            if (val[2] === '/') {
                return val.substr(0, 2);
            } else if (
                (val.substr(0, 2) === '02' && ['0', '1', '2'].includes(val[2])) ||
                (val.substr(0, 2) !== '02' && ['0', '1', '2', '3'].includes(val[2]))
            ) {
                return val.substr(0, 2) + '/' + val.substr(2);
            } else {
                return val.substr(0, 2);
            }
        } else if (
            len === 5 &&
            (val.substr(3, 5) === '00' ||
                (val[3] === '3' && !['0', '1'].includes(val[4])) ||
                (val[3] === '3' &&
                    val[4] === '1' &&
                    !['01', '03', '05', '07', '08', '10', '12'].includes(val.substr(0, 2))))
        ) {
            return val.substr(0, 4);
        } else if (len === 6) {
            if (val[5] === '/') {
                return val.substr(0, 5);
            } else {
                return val.substr(0, 5) + '/' + val.substr(5);
            }
        } else if (len === 8 && val.substr(0, 6) === '02/29/') {
            let year = +val.substr(6);
            year = year > 1970 ? 1900 + year : 2000 + year;
            if (this.isLeapYear(year)) {
                return val.substr(0, 7);
            }
        } else if (len === 10 && val.substr(0, 6) === '02/29/') {
            if (this.isLeapYear(+val.substr(6))) {
                return val.substr(0, 9);
            }
        } else if (len > 10) {
            return val.substr(0, 10);
        }
        return val;
    }

    onDateInput(e) {
        console.log(e.value);
        return (this.staffModel.terminationDate = e.value);
    }

    getDate = (date) => {
        const { month, day, year } = date.match(/(?<month>\d{2})(?<day>\d{2})(?<year>\d{4})/, 'ig').groups;
        return new Date(`${month}/${day}/${year}`);
    };
    convertDate = (date) => {
        if (!date) {
            return;
        }

        return new Date(date).toLocaleDateString();
    };
    setDateFields() {
        !this.staffModel.terminationDate
            ? (this.staffModel.terminationDate = null)
            : (this.staffModel.terminationDate = new Date(this.staffModel.terminationDate));
        !this.staffModel.hireDate
            ? (this.staffModel.hireDate = null)
            : (this.staffModel.hireDate = new Date(this.staffModel.hireDate));
        !this.staffModel.dateofBirth
            ? (this.staffModel.dateofBirth = null)
            : (this.staffModel.dateofBirth = new Date(this.staffModel.dateofBirth));
    }

    setDateFieldsToString() {
        //     !this.staffModel.terminationDate
        //     ? (this.staffModel.terminationDate = null)
        //     : (this.staffModel.terminationDate = formatDate(this.staffModel.terminationDate,'yyyy-MM-dd',"en-US"));
        // !this.staffModel.hireDate
        //     ? (this.staffModel.hireDate = null)
        //     : (this.staffModel.hireDate = this.getDate(this.staffModel.hireDate).toLocaleDateString());
        // !this.staffModel.dateofBirth
        //     ? (this.staffModel.dateofBirth = null)
        //     : (this.staffModel.dateofBirth = this.getDate(this.staffModel.dateofBirth).toLocaleDateString());
    }

    public handleAddressChange(place: google.maps.places.PlaceResult) {
        var addressModel = this.utility.getAddressObject(place.address_components);
        this.staffModel.street = addressModel['home'] + ' ' + addressModel['street'];
        this.staffModel.city = addressModel['city'];
        this.staffModel.state = addressModel['region'];
        this.staffModel.zip = addressModel['postal_code'];
    }
}
