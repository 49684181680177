// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.steps-content {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 60vh;
  text-align: center;
}
.dialog-title {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.steps-action-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bottom {
  bottom: 0px;
  position: relative;
}
.steps-action {
  margin-top: 24px;
  margin-bottom: 15px;
}

button {
  margin-right: 8px;
}

[nz-button] {
  margin-right: 8px;
  margin-bottom: 12px;
}
[nz-form] {
  max-width: 60vh;
}
/* nz-steps {
    margin-bottom: 8px;
    box-shadow: rgb(232, 232, 232) 0px -1px 0px 0 inset;
  } */
`, "",{"version":3,"sources":["webpack://./src/app/assessment/modal/assessmentmodal.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,0BAA0B;EAC1B,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;EAChB,kBAAkB;AACpB;AACA;EACE,aAAa;EACb,yBAAyB;EACzB,qBAAqB;AACvB;AACA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;AACA;EACE,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,eAAe;AACjB;AACA;;;KAGK","sourcesContent":[".steps-content {\n  margin-top: 16px;\n  border: 1px dashed #e9e9e9;\n  border-radius: 6px;\n  background-color: #fafafa;\n  min-height: 60vh;\n  text-align: center;\n}\n.dialog-title {\n  display: flex;\n  justify-content: flex-end;\n  align-items: flex-end;\n}\n.steps-action-buttons {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n.bottom {\n  bottom: 0px;\n  position: relative;\n}\n.steps-action {\n  margin-top: 24px;\n  margin-bottom: 15px;\n}\n\nbutton {\n  margin-right: 8px;\n}\n\n[nz-button] {\n  margin-right: 8px;\n  margin-bottom: 12px;\n}\n[nz-form] {\n  max-width: 60vh;\n}\n/* nz-steps {\n    margin-bottom: 8px;\n    box-shadow: rgb(232, 232, 232) 0px -1px 0px 0 inset;\n  } */\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
