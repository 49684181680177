// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.e-grid td.e-active {
    background: #e5e7eb;
    color: #6b7280;
    -webkit-background-clip: initial;
    -webkit-text-fill-color: darkblue;
}
.e-hover {
    background: #f3f4f6 !important;
    color: #f3f4f6 !important;
    -webkit-background-clip: initial !important;
    -webkit-text-fill-color: darkblue !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/dashboard/components/dashboard.component.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,cAAc;IACd,gCAAgC;IAChC,iCAAiC;AACrC;AACA;IACI,8BAA8B;IAC9B,yBAAyB;IACzB,2CAA2C;IAC3C,4CAA4C;AAChD","sourcesContent":[".e-grid td.e-active {\n    background: #e5e7eb;\n    color: #6b7280;\n    -webkit-background-clip: initial;\n    -webkit-text-fill-color: darkblue;\n}\n.e-hover {\n    background: #f3f4f6 !important;\n    color: #f3f4f6 !important;\n    -webkit-background-clip: initial !important;\n    -webkit-text-fill-color: darkblue !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
