// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .view {
    padding-left: 0px !important;
  }
  .jobWidth {
    width: 100% !important;
  }
  .maxView {
    width: 100% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .view {
    padding-left: 0px !important;
  }
  .jobWidth {
    width: 100% !important;
  }
  .maxView {
    width: 100% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .view {
    padding-left: 15px !important;
  }
  .jobWidth {
    width: 96% !important;
  }
  .maxView {
    width: 100% !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .view {
    padding-left: 15px !important;
  }
  .jobWidth {
    width: 96% !important;
  }
  .maxView {
    width: 100% !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .view {
    padding-left: 15px !important;
  }
  .jobWidth {
    width: 96% !important;
  }
  .maxView {
    width: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/app/jobpost/jobpost.component.css"],"names":[],"mappings":"AAAA,iDAAiD;AACjD;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;AACF;;AAEA,oEAAoE;AACpE;EACE;IACE,4BAA4B;EAC9B;EACA;IACE,sBAAsB;EACxB;EACA;IACE,sBAAsB;EACxB;AACF;;AAEA,qDAAqD;AACrD;EACE;IACE,6BAA6B;EAC/B;EACA;IACE,qBAAqB;EACvB;EACA;IACE,sBAAsB;EACxB;AACF;;AAEA,mDAAmD;AACnD;EACE;IACE,6BAA6B;EAC/B;EACA;IACE,qBAAqB;EACvB;EACA;IACE,sBAAsB;EACxB;AACF;;AAEA,oEAAoE;AACpE;EACE;IACE,6BAA6B;EAC/B;EACA;IACE,qBAAqB;EACvB;EACA;IACE,sBAAsB;EACxB;AACF","sourcesContent":["/* Extra small devices (phones, 600px and down) */\n@media only screen and (max-width: 600px) {\n  .view {\n    padding-left: 0px !important;\n  }\n  .jobWidth {\n    width: 100% !important;\n  }\n  .maxView {\n    width: 100% !important;\n  }\n}\n\n/* Small devices (portrait tablets and large phones, 600px and up) */\n@media only screen and (min-width: 600px) {\n  .view {\n    padding-left: 0px !important;\n  }\n  .jobWidth {\n    width: 100% !important;\n  }\n  .maxView {\n    width: 100% !important;\n  }\n}\n\n/* Medium devices (landscape tablets, 768px and up) */\n@media only screen and (min-width: 768px) {\n  .view {\n    padding-left: 15px !important;\n  }\n  .jobWidth {\n    width: 96% !important;\n  }\n  .maxView {\n    width: 100% !important;\n  }\n}\n\n/* Large devices (laptops/desktops, 992px and up) */\n@media only screen and (min-width: 992px) {\n  .view {\n    padding-left: 15px !important;\n  }\n  .jobWidth {\n    width: 96% !important;\n  }\n  .maxView {\n    width: 100% !important;\n  }\n}\n\n/* Extra large devices (large laptops and desktops, 1200px and up) */\n@media only screen and (min-width: 1200px) {\n  .view {\n    padding-left: 15px !important;\n  }\n  .jobWidth {\n    width: 96% !important;\n  }\n  .maxView {\n    width: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
