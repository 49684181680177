import { ChangeDetectorRef, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { SpeedDialComponent } from '@syncfusion/ej2-angular-buttons';
import { ListViewComponent, SelectEventArgs } from '@syncfusion/ej2-angular-lists';
import { ClickEventArgs, SidebarComponent } from '@syncfusion/ej2-angular-navigations';
import { Menu, MenuItemModel, NodeSelectEventArgs, Sidebar } from '@syncfusion/ej2-navigations';
import { UserIdleService } from 'angular-user-idle';
import { Subscription } from 'rxjs';
import { AccountService } from 'src/app/login/service/account.service';
import { AlertService } from 'src/app/alerts/alert.service';
import { AuthService } from 'src/app/login/service/auth.service';
import { VendorService } from 'src/app/vendorList/services/vendor.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './header.sidenav.component.html',
    styleUrls: ['./header.sidenav.component.css'],
})
export class SidebarHeaderComponent implements OnInit {
    public speeddialObj: SpeedDialComponent;
    @ViewChild('sideTree') sidebarTreeviewInstance: SidebarComponent;
    private staff: string = 'Staff Member';
    newVendorCount: number;
    isOpen: boolean = true;
    lastLogin = new Date();
    isCollapsed: boolean = false;
    isLoading: boolean = false;
    linkText: boolean = this.isOpen;
    userLoggedIn: boolean = false;
    public role: string = '';
    private _loadingSubscription: Subscription;
    private _vendorCountSubscription: Subscription;
    private _accountSubscription: Subscription;
    idelSessiondialogOpened: boolean = false;
    public data: { [key: string]: Object }[] = [
        {
            nodeId: '01',
            nodeText: 'Home',
            iconCss: 'fa fa-home',
            style: 'padding-bottom:15px',
        },
        {
            nodeId: '02',
            nodeText: 'Client List',
            iconCss: 'fa fa-user-group',
            style: 'padding-bottom:15px',
        },
        // {
        //     nodeId: '03',
        //     nodeText: 'Quick Start',
        //     iconCss: 'icon-docs icon',
        // },
        {
            nodeId: '04',
            nodeText: 'Reports',
            iconCss: 'fa fa-clipboard-user',
            nodeChild: [
                {
                    nodeId: '04-01',
                    nodeText: 'Report Templates',
                    iconCss: 'fa fa-file-waveform',
                    tooltip: 'Create Report Templates (Only visible to Admin)',
                },
            ],
            style: 'padding-bottom:15px',
        },
        {
            nodeId: '05',
            nodeText: 'Vendor',
            iconCss: 'fa fa-users-gear ',
            nodeChild: [{ nodeId: '05-01', nodeText: 'Vendor List', iconCss: 'fa fa-users-gear' }],
            style: 'padding-bottom:15px',
        },
        {
            nodeId: '06',
            nodeText: 'Task Board',
            iconCss: 'fa fa-file-waveform',
            style: 'padding-bottom:15px',
        },
        {
            nodeId: '07',
            nodeText: 'Staff',
            iconCss: 'fa fa-user-tie',
            style: 'padding-bottom:15px',
            tooltip: 'Edit All Staff (Only visible to Admin)',
        },
        {
            nodeId: '08',
            nodeText: 'Settings',
            iconCss: 'fa fa-cog',
            style: 'padding-bottom:15px',
        },
        {
            nodeId: '09',
            nodeText: 'LogOut',
            iconCss: 'fa fa-sign-out',
        },
        // {
        //     nodeId: '10',
        //     nodeText: 'License',
        //     iconCss: 'icon-doc-text icon',
        // },
    ];
    public width: string = '220px';
    public target: string = '.main-sidebar-content';
    public mediaQuery: string = '(min-width: 600px)';
    public fields: object = {
        dataSource: this.data,
        id: 'nodeId',
        text: 'nodeText',
        child: 'nodeChild',
        iconCss: 'iconCss',
        style: 'style',
        tooltip: 'tooltip',
    };
    public enableDock: boolean = true;
    public dockSize: string = '72px';
    get vendorValue() {
        return this.staff;
    }

    set vendorValue(staff) {
        this.staff = staff;
    }
    // only for sample browser use
    constructor(
        public authService: AuthService,
        private cdr: ChangeDetectorRef,
        private router: Router,
        private alertService: AlertService,
        private userIdle: UserIdleService,
        private vendorService: VendorService,
        private accountService: AccountService
    ) {
        this._accountSubscription = this.accountService.user.subscribe((count) => {
            this.role = count?.role?.toUpperCase();
            this.vendorValue = count?.vendorName || `${count?.firstName} ${count?.lastName}`;
            if (typeof count?.token !== 'undefined') {
                this.userLoggedIn = count?.token !== '';
            }
        });

        this._vendorCountSubscription = this.accountService.vendorCountObserver.subscribe((count) => {
            this.newVendorCount = count;
        });

        this._loadingSubscription = this.accountService.loadingScreenObserver.subscribe((event) => {
            this.isLoading = event;
            this.cdr.detectChanges();
        });
        let defaultSidebar: Sidebar = new Sidebar({ created: this.onCreated });
        defaultSidebar.appendTo('#sideTree');
    }

    ngOnInit(): void {
        //Start watching for user inactivity.
        this.userIdle.startWatching();

        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe();

        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(async () => {
            this.userIdle.resetTimer();
            this.alertService.clear();
            this.alertService.info('For your security you have been logged out due to inactivity.');
            await this.LogOut();
        });
        //  this.userIdle.ping$.subscribe(() => {
        //   let token = this.authService.getRefreshToken();
        //   if(token){
        //     this.authService.setRefreshToken()
        //   }
        //  })
    }

    ngOnDestroy(): void {
        this._loadingSubscription.unsubscribe();
        this._vendorCountSubscription.unsubscribe();
        this._accountSubscription.unsubscribe();
    }

    onCreated() {
        console.log('Sidebar', this.sidebarTreeviewInstance);
        this.sidebarTreeviewInstance.element.classList.add('disable');
        this.sidebarTreeviewInstance.element.setAttribute('tabindex', '-1');
    }
    //open / close the sidebar
    toolbarClicked() {
        this.sidebarTreeviewInstance.toggle();
        this.isCollapsed = !this.sidebarTreeviewInstance.isOpen;
    }

    goToRoute(route) {
        this.isLoading = true;
        setTimeout(() => {
            this.router.navigate([route.RouterLink]);
        });
    }
    rolebasedOptions(item): boolean {
        if (item && item.title == 'Settings') {
            let v = this?.authService?.getRole() == 'ADMIN';
            return !v;
        } else {
            return true;
        }
    }
    public closeClick() {
        this.speeddialObj.hide();
    }

    public submitClick() {
        this.alertService.success('Thank you! Your feedback has been submitted.');
        this.speeddialObj.hide();
    }
    async LogOut() {
        await this.accountService.logout();
    }
}
