import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { catchError, Observable, of, Subject, tap } from 'rxjs';
import { ImageForm } from 'src/app/clientreferral/models/imageform.model';
import { MessageService } from 'src/app/common/message.service';
import { environment } from 'src/environments/environment';
import { Guid } from 'guid-typescript';
import { ReportsModel } from 'src/app/reportsScreen/models/reports.model';
import { ReportsTemplateModel } from '../model/report.template.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    @Output() IsModelComplete: EventEmitter<any> = new EventEmitter();
    private modelComplete = new Subject<any>();

    private subject = new Subject<any>();
    private changeIndexSubject = new Subject<any>();
    http: HttpClient;
    clientUrl: string = environment.apiUrl + '/SituationalAssessment';

    constructor(httpClient: HttpClient, private messageService: MessageService) {
        this.http = httpClient;
    }

    setModelComplete(value) {
        this.IsModelComplete.emit(value);
    }

    goToPage(index: string) {
        this.changeIndexSubject.next(index);
    }

    getIndexSubjectEvent(): Observable<any> {
        return this.changeIndexSubject.asObservable();
    }
    sendNextEvent(event: any) {
        this.subject.next(event);
    }

    getNextEvent(): Observable<any> {
        return this.subject.asObservable();
    }
    public getAllReports(): Observable<HttpResponse<ReportsModel[]>> {
        return this.http
            .get<ReportsModel[]>(this.clientUrl + '/GetAll', {
                observe: 'response',
            })
            .pipe(
                catchError((err) => {
                    this.handleError<ReportsModel[]>('Get All Report Data', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    public addReport(reportModel: ReportsModel) {
        return this.http.post<any>(this.clientUrl + '/AddAssessment', reportModel, httpOptions).pipe(
            catchError((err) => {
                this.handleError<any>('Add', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }
    public updateReport(reportModel: ReportsModel): Observable<ReportsModel[]> {
        return this.http
            .post<ReportsModel[]>(
                this.clientUrl + '/UpdateAssessment?id=' + reportModel.reportId,
                reportModel,
                httpOptions
            )
            .pipe
            // catchError(err => {
            //   this.handleError<ReportsModel[]>('Update', err)
            // })
            ();
    }

    public deactivateClientReport(model: ReportsModel): Observable<any> {
        return this.http
            .post<ReportsModel[]>(this.clientUrl + '/DeactivateClientReport', model, httpOptions)
            .pipe(
                catchError((err) => {
                    this.handleError<ReportsModel[]>('Delete', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    getReportbyId(id: string): Observable<ReportsModel> {
        return this.http.get<ReportsModel>(this.clientUrl).pipe(
            tap((_) => this.log(`fetched report id=${id}`)),
            catchError(this.handleError<ReportsModel>(`getReport id=${id}`))
        );
    }

    public getAllReportsbyClientId(id: string): Observable<any> {
        return this.http.get<ReportsModel[]>(this.clientUrl + '/GetAllReportsbyClientId?id=' + id).pipe(
            tap((_) => this.log(`fetched report id=${id}`)),
            catchError(this.handleError<ReportsModel>(`GetAllReportsbyClientId id=${id}`))
        );
    }

    public getClientReportById(reportTemplateId: string): Observable<ReportsTemplateModel> {
        return this.http
            .get<ReportsTemplateModel>(this.clientUrl + '/GetReportTemplateById?id=' + reportTemplateId)
            .pipe(catchError(this.handleError<ReportsTemplateModel>(`template id=${reportTemplateId}`)));
    }

    public getAllTemplates(): Observable<HttpResponse<ReportsTemplateModel[]>> {
        return this.http
            .get<ReportsTemplateModel[]>(this.clientUrl + '/GetAllTemplates', {
                observe: 'response',
            })
            .pipe(
                catchError((err) => {
                    ReportsTemplateModel;
                    this.handleError<ReportsModel[]>('Get All Report Data', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    addTemplate(model: ReportsTemplateModel): Observable<ReportsTemplateModel> {
        return this.http
            .post<ReportsTemplateModel>(this.clientUrl + '/AddReportTemplate', model, httpOptions)
            .pipe
            // catchError(err => {
            //   this.handleError<ReportsModel[]>('Update', err)
            // })
            ();
    }

    saveTemplate(model: ReportsTemplateModel): Observable<any> {
        return this.http
            .post<ReportsModel[]>(this.clientUrl + '/SaveReportTemplate', model, httpOptions)
            .pipe
            // catchError(err => {
            //   this.handleError<ReportsModel[]>('Update', err)
            // })
            ();
    }

    public saveTemplateDocument(formData: any, templateId: any): Observable<any> {
        let options = {
            headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
        };
        return this.http.post<any>(this.clientUrl + `/SaveTemplateDocument/?id=${templateId}`, formData).pipe(
            catchError((err) => {
                this.handleError<ReportsTemplateModel[]>('Updloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public getTemplateDocument(templateId: any): Observable<ReportsTemplateModel> {
        return this.http
            .post<ReportsTemplateModel>(this.clientUrl + `/GetTemplateDocument?id=${templateId}`, {})
            .pipe(
                catchError((err) => {
                    this.handleError<ReportsTemplateModel[]>('Downloading Form', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    public deleteTemplateDocumentById(templateId: any): Observable<ReportsTemplateModel[]> {
        return this.http
            .post<ReportsTemplateModel[]>(this.clientUrl + `/DeleteReportTemplate?id=${templateId}`, {})
            .pipe(
                catchError((err) => {
                    this.handleError<ReportsTemplateModel[]>('Downloading Form', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    public updateReportStatusStaffAsync(reportsModel: any): Observable<any> {
        return this.http
            .post<ReportsModel[]>(this.clientUrl + `/UpdateReportStatusStaffAsync`, reportsModel, httpOptions)
            .pipe(
                catchError((err) => {
                    this.handleError<ReportsTemplateModel[]>('Downloading Form', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    downloadForm(templatForm: ImageForm): Observable<any> {
        return this.http
            .post(this.clientUrl + '/DownloadFile', templatForm, {
                responseType: 'blob',
            })
            .pipe(
                catchError((err) => {
                    this.handleError<ReportsModel[]>('DownloadFile', err);
                    throw 'error in Downloading File. Details: ' + err;
                })
            );
    }

    getCaseTypes(): Observable<any> {
        return this.http.get(this.clientUrl + '/GetCaseTypes', { observe: 'response' }).pipe(
            catchError((err) => {
                this.handleError<ReportsModel[]>('Get All CaseTypes', err);
                throw 'error in casetypes. Details: ' + err;
            })
        );
    }

    private log(message: string) {
        this.messageService.add(`ReportService: ${message}`);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
