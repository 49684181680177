import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { MessageService } from 'src/app/common/message.service';
import { environment } from 'src/environments/environment';
import { JobpostModel } from '../models/jobpost.model';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable()
export class JobpostService {
  jobPostUrl: string = environment.apiUrl + '/JobPosts/';

  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService
  ) {}

  lstJobPost = [];

  public getJobPost(): Observable<HttpResponse<JobpostModel[]>> {
    return this.httpClient
      .get<JobpostModel[]>(this.jobPostUrl + 'GetAll', { observe: 'response' })
      .pipe(
        catchError((err) => {
          this.handleError<JobpostModel[]>('Updloading Form', err);
          throw 'Error in getJobPost. Details: ' + err;
        })
      );
  }

  public addJobPost(jobPost: JobpostModel): Observable<JobpostModel[]> {
    return this.httpClient.post<JobpostModel[]>(
      this.jobPostUrl,
      jobPost,
      httpOptions
    );
  }

  public updateJobPost(jobPost: JobpostModel): Observable<JobpostModel[]> {
    return this.httpClient.put<JobpostModel[]>(
      `${this.jobPostUrl} /${jobPost.jobPostId}`,
      jobPost,
      httpOptions
    );
  }

  public deleteJobPost(jobPost: JobpostModel): Observable<JobpostModel[]> {
    return this.httpClient.delete<JobpostModel[]>(
      `${this.jobPostUrl} /${jobPost.jobPostId}`,
      httpOptions
    );
  }

  getJobPostbyId(id: number): Observable<JobpostModel> {
    const url = `${this.jobPostUrl}${id}`;
    return this.httpClient.get<JobpostModel>(url).pipe(
      tap((_) => this.log(`fetched hero id=${id}`)),
      catchError(this.handleError<JobpostModel>(`getClient id=${id}`))
    );
  }

  private log(message: string) {
    this.messageService.add(`JobService: ${message}`);
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
