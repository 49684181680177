import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { CalendarComponent } from './calendar/calendar.component';
import { MailComponent } from './mail/mail.component';
import { JobpostComponent } from './jobpost/jobpost.component';
import { ClientReferralComponent } from './clientreferral/clientreferral.component';
import { SettingsComponent } from './settings/settings.component';
import { NotificationComponent } from './notification/notification.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { AuthGuard } from './login/service/authguard';
import { VendorComponent } from './vendorList/vendorlist.component';
import { ReportsComponent } from './reportsScreen/reports.component';
import { UsersComponent } from './users/users.component';
import { ClientComponent } from './client/components/client/client.component';
import { ClientCasesComponent } from './cases/components/client.cases.component';
import { StaffGridComponent } from './staff/components/staff.admin.grid/staff.admin.grid.component';
import { StaffComponent } from './staff/components/staff/staff.component';
import { ClientGridComponent } from './client/components/clientgrid/client.grid.component';
import { AddReportComponent } from './reports/components/add-report/add-report.component';
import { DashboardComponent } from './dashboard/components/dashboard.component';

const accountModule: any = () => import('./login/account.module').then((x) => x.AccountModule);
const reportModule: any = () => import('./reports/module/report.module').then((x) => x.ReportModule);
const taskModule: any = () => import('./tasks/module/tasks.module').then((x) => x.TaskModule);
// const clientModule: any = () => import('./client/module/client.module').then(x -> x.ClientModule)
// const staffModule: any = () => import('./staff/module/staff.module').then(x -> x.StaffModule)

// const staffChat: any = () => import('./staff/staffchat/module/staffchat.module').then(x -> x.StaffChatModule)
// const staffEmail: any = () => import('./staff/staffemail/module/staffemail.module').then(x -> x.StaffEmailModule)
// const calendarModule: any = () => import('./staff/staffcalendar/module/staffcalendar.module').then(x -> x.StaffCalendarModule)

const routes: Routes = [
    { path: '', redirectTo: '/account/login', pathMatch: 'full' },
    { path: 'account', loadChildren: accountModule },
    {
        path: 'task',
        loadChildren: taskModule,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
        },
    },
    {
        path: 'reporting',
        loadChildren: reportModule,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
            preload: true,
        },
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'USER', 'STAFF'],
        },
    },
    {
        path: 'calendar',
        component: CalendarComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'mail',
        component: MailComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
        },
    },
    {
        path: 'clientReferrals',
        component: ClientReferralComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
        },
    },
    {
        path: 'client',
        component: ClientComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
            preload: true,
        },
    },
    {
        path: 'clientGrid',
        component: ClientGridComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
        },
    },
    {
        path: 'clientCases',
        component: ClientCasesComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
        },
    },
    {
        path: 'addReport',
        component: AddReportComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'forms',
        component: NotificationComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
        },
    },
    {
        path: 'staffChat',
        component: AssessmentComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
        },
    },
    {
        path: 'staffgrid',
        component: StaffGridComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'staff',
        component: StaffComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN'],
        },
    },
    {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['STAFF', 'ADMIN'],
        },
    },
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
        },
    },
    {
        path: 'vendorList',
        component: VendorComponent,
        canActivate: [AuthGuard],
        data: {
            role: ['ADMIN', 'STAFF'],
        },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
