import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
//import { ColDef } from 'ag-grid-community';
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { first } from 'rxjs';
import { CustomValidators } from '../helpers/customvalidators';
import { AlertService } from '../alerts/alert.service';
import { JobpostModel } from './models/jobpost.model';
import { JobpostService } from './services/jobpost.service';

@Component({
    selector: 'app-jobpost',
    templateUrl: './jobpost.component.html',
    styleUrls: ['./jobpost.component.css'],
})
export class JobpostComponent implements OnInit {
    private gridApi!: GridApi;
    public frmJobPost: FormGroup;
    loading = false;
    submitted = false;
    public Jobposts: JobpostModel[];
    startDate = new Date();
    columnDefs: ColDef[] = [
        { field: 'jobTitle', sortable: true, flex: 1 },
        { field: 'jobDescription', sortable: true, flex: 1 },
        { field: 'jobStatus', sortable: true, flex: 1 },
        {
            field: 'compensation',
            sortable: true,
            flex: 1,
            cellRenderer: (data) => {
                return data ? '$' + data.value.toFixed(2) : '0.00';
            },
        },
        { field: 'salaryType', sortable: true, flex: 1 },
        {
            field: 'jobPostDate',
            sortable: true,
            cellRenderer: (data) => {
                return data ? new Date(data.value).toLocaleDateString() : '';
            },
            flex: 1,
        },
        { field: 'hireDate', sortable: true, flex: 1 },
        { field: 'accomodations', sortable: true, flex: 1 },
    ];

    constructor(
        public jobpostService: JobpostService,
        private fb: FormBuilder,
        private alertService: AlertService
    ) {
        this.frmJobPost = this.createJobForm();
    }

    ngOnInit(): void {}
    public defaultColDef: ColDef = {
        flex: 1,
        minWidth: 100,
        sortable: true,
        filter: true,
    };
    public autoGroupColumnDef: ColDef = {
        minWidth: 200,
    };
    public groupDefaultExpanded = 1;

    onAddJobPost() {
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        // stop here if form is invalid
        if (this.frmJobPost.invalid) {
            return;
        }

        this.loading = true;
        this.jobpostService
            .addJobPost(this.frmJobPost.value)
            .pipe()
            .subscribe((data) => {
                this.Jobposts = data;
                this.gridApi.setRowData(this.Jobposts);
                this.loading = false;
            }),
            (error) => {
                this.alertService.error(error);
                this.loading = false;
            };
    }

    onFilterTextBoxChanged() {
        this.gridApi.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
    }

    onPrintQuickFilterTexts() {
        this.gridApi.forEachNode(function (rowNode, index) {
            console.log('Row ' + index + ' quick filter text is ' + rowNode.quickFilterAggregateText);
        });
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.jobpostService.getJobPost().subscribe((data) => {
            this.Jobposts = data['body'];
            this.gridApi.setRowData(this.Jobposts.slice(0, 10));
            params.api!.setRowData(this.Jobposts.slice(0, 10));
            this.loading = false;
            //console.log(this.Jobposts);
        });
        this.gridApi.sizeColumnsToFit();
    }

    get f() {
        return this.frmJobPost.controls;
    }

    createJobForm(): FormGroup<any> {
        return this.fb.group({
            jobTitle: ['', Validators.required],
            jobDescription: [''],
            salaryType: ['', Validators.required],
            jobPostDate: [new Date(), Validators.required],
            compensation: ['', Validators.required],
            accomodations: [''],
            jobStatus: ['', Validators.required],
            hireDate: [''],
        });
    }
}
