// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      [nz-form] {
        max-width: 600px;
      }

      button {
        margin-left: 8px;
      }
    `, "",{"version":3,"sources":["webpack://./src/app/apptemp/apptemp.component.ts"],"names":[],"mappings":";MACM;QACE,gBAAgB;MAClB;;MAEA;QACE,gBAAgB;MAClB","sourcesContent":["\n      [nz-form] {\n        max-width: 600px;\n      }\n\n      button {\n        margin-left: 8px;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
