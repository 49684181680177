import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
    override useUtcForDisplay = true;

    override format(date: Date): string {
        return moment(date).format('MM/DD/YYYY');
    }

    override parse(value: any): Date | null {
        if (value && typeof value === 'string') {
            value = value.replace('/', '');
        }

        if (!moment(value, 'MM/DD/YYYY', true).isValid()) {
            return this.invalid();
        }

        return moment(value, 'MM/DD/YYYY', true).toDate();
    }
}
