import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { User } from '../login/models/user';
import { AccountService } from '../login/service/account.service';
import { AuthService } from '../login/service/auth.service';
import { BehaviorSubject, Subject, Subscription, take } from 'rxjs';
import { VendorService } from '../vendorList/services/vendor.service';
import { MatSidenav } from '@angular/material/sidenav';
import { UserIdleService } from 'angular-user-idle';
import { AlertService } from '../alerts/alert.service';
import { SpeedDialComponent, SpeedDialItemModel } from '@syncfusion/ej2-angular-buttons';
import { FormGroup, FormControl, FormGroupDirective } from '@angular/forms';
import { FormValidators } from '@syncfusion/ej2-angular-inputs';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
    get vendorValue() {
        return this.staff;
    }

    set vendorValue(staff) {
        this.staff = staff;
    }

    @ViewChild('popupTempSpeeddial')
    public speeddialObj: SpeedDialComponent;
    private staff: string = 'Staff Member';
    newVendorCount: number;
    private _subscription: Subscription;
    isEnable$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isEnable: boolean;
    isOpen: boolean = true;
    lastLogin = new Date();
    isLoading: boolean = false;
    linkText: boolean = this.isOpen;
    userLoggedIn: boolean = false;
    speedDialClicked: boolean = false;
    public role: string = '';
    private _loadingSubscription: Subscription;
    private _vendorCountSubscription: Subscription;
    private _accountSubscription: Subscription;
    private _enableSubscription: Subscription;
    idelSessiondialogOpened: boolean = false;
    public miniNavDisplay: 'none' | 'flex';
    @ViewChild('formId', { static: true }) myGroup: FormGroup;
    @ViewChild('sidenav', { static: false }) sideNav: MatSidenav;
    constructor(
        public authService: AuthService,
        private router: Router,
        private alertService: AlertService,
        private userIdle: UserIdleService,
        private vendorService: VendorService,
        private accountService: AccountService
    ) {
        this._accountSubscription = this.accountService.user.subscribe((count) => {
            this.role = count?.role?.toUpperCase();
            this.vendorValue = count?.vendorName || `${count?.firstName} ${count?.lastName}`;
            if (typeof count?.token !== 'undefined') {
                this.userLoggedIn = count?.token !== '';
            }
        });

        this._vendorCountSubscription = this.accountService.vendorCountObserver.subscribe((count) => {
            this.newVendorCount = count;
        });

        this._loadingSubscription = this.accountService.loadingScreenObserver.subscribe((event) => {
            this.isLoading = event;
        });

        this._enableSubscription = this.isEnable$.asObservable().subscribe((res) => (this.isEnable = res));
    }

    ngOnInit(): void {
        //Start watching for user inactivity.
        this.userIdle.startWatching();

        // Start watching when user idle is starting.
        this.userIdle.onTimerStart().subscribe();

        // Start watch when time is up.
        this.userIdle.onTimeout().subscribe(async () => {
            this.userIdle.resetTimer();
            this.alertService.clear();
            this.alertService.info('For your security you have been logged out due to inactivity.');
            await this.LogOut();
        });

        //  this.userIdle.ping$.subscribe(() => {
        //   let token = this.authService.getRefreshToken();
        //   if(token){
        //     this.authService.setRefreshToken()
        //   }
        //  })
    }

    ngOnDestroy(): void {
        this._loadingSubscription.unsubscribe();
        this._vendorCountSubscription.unsubscribe();
        this._accountSubscription.unsubscribe();
        this._enableSubscription.unsubscribe();
        this._subscription.unsubscribe();
    }

    ngAfterViewInit(): void {}

    toggleNav() {
        const btnToggle = document.querySelector('.click-nav-btn .fa');
        const navbar = document.querySelector('.navbar-bl');
        const wrapper = document.querySelector('.main-wrapper');

        btnToggle?.classList.toggle('fa-chevron-right');
        navbar?.classList.toggle('hide-navbar');
        wrapper?.classList.toggle('pad-min');
    }
    goToRoute(route) {
        this.isLoading = true;
        setTimeout(() => {
            this.router.navigate([route.RouterLink]);
        });
    }
    toggle() {
        this.isOpen = !this.isOpen;

        setTimeout(() => {
            this.linkText = this.isOpen;
        }, 200);
    }

    closeSideNav() {
        this.sideNav.close();
    }

    miniNavStart() {
        this.miniNavDisplay = 'flex';
    }
    miniNavDone() {
        this.miniNavDisplay = !this.linkText ? 'flex' : 'none';
        console.log('mininav', this.miniNavDisplay);
    }
    backdropStart() {
        //   this.backdropDisplay = 'block';
    }

    backdropDone() {
        //this.backdropDisplay = (this.linkText ? 'block' : 'none')
    }

    rolebasedOptions(item): boolean {
        if (item && item.title == 'Settings') {
            let v = this?.authService?.getRole() == 'ADMIN';
            return !v;
        } else {
            return true;
        }
    }

    get name() {
        return this.myGroup?.get('check');
    }
    get email() {
        return this.myGroup?.get('email');
    }
    get feedback() {
        return this.myGroup?.get('feedback');
    }

    public openSpeedDial() {
        this.speedDialClicked = true;
        this.speeddialObj.show();
        this.myGroup = new FormGroup({
            name: new FormControl('', [FormValidators.required]),
            email: new FormControl('', [FormValidators.email]),
            feedback: new FormControl('', [FormValidators.required]),
        });
        this._subscription = this.myGroup.statusChanges?.subscribe((result: any) => {
            let val = result === 'VALID' ? true : false;
            this.onFormValidation(val);
        });
    }

    public closeClick() {
        this.myGroup?.reset();
        this.myGroup = null;
        this._subscription.unsubscribe();
        this.speedDialClicked = false;
        this.speeddialObj.hide();
    }
    onFormValidation(validity: boolean) {
        switch (validity) {
            case true:
                this.isEnable$.next(true);
                break;
            case false:
                this.isEnable$.next(false);
                break;
        }
    }
    public submitClick() {
        if (!this.isEnable) {
            Object.values(this.myGroup.controls).forEach((control) => {
                console.log('Contril Invalid', control);
                if (control.invalid) {
                    control.markAsDirty();
                    control.updateValueAndValidity({ onlySelf: true });
                }
            });
            let invalidFields = [].slice.call(document.getElementsByClassName('ng-invalid'));
            invalidFields[1]?.focus();
        } else {
            this.myGroup?.reset();
            this.myGroup = null;
            this.alertService.success('Thank you! Your feedback has been submitted.');
            this.speeddialObj.hide();
        }
    }
    async LogOut() {
        await this.accountService.logout();
    }
}
