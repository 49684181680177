import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  VERSION,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, Observer, Subject, take } from 'rxjs';
import { DialogComponent } from 'src/app/common/dialog.component';
import {
  VendorModel,
  CompanyInformationModel,
  JobInformationModel,
} from '../../models/assessment.model';
import { AssessmentService } from '../../services/assessment.service';

@Component({
  selector: 'app-assessment-jobs-info',
  templateUrl: './assessment.jobsform.component.html',
  styles: [
    `
      [nz-form] {
        max-width: 600px;
      }

      button {
        margin-left: 8px;
      }
      @media only screen and (max-width: 400px) and (min-width: 200px) {
        .title-modal {
          font-size: 9px;
        }
      }

      @media only screen and (max-width: 700px) and (min-width: 401px) {
        .title-modal {
          font-size: 16px;
        }
      }
    `,
  ],
})
export class AssessmentJobsFormComponent implements OnInit {
  @ViewChild(FormGroupDirective, { static: true }) validateForm: FormGroup;
  @Input() title: string = '';
  @Input() model: VendorModel;
  @Input() files: any;
  @Output() assessmentOutput = new EventEmitter<any>();
  mode: string = '';
  formatterDollar = (value: number): string => `$ ${value}`;
  parserDollar = (value: string): string => value.replace('$ ', '');
  formData = new FormData();
  private modalClose: Subject<any> = new Subject();
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<DialogComponent>,
    public dialog: MatDialog,
    private service: AssessmentService
  ) {
    this.service
      .getNextEvent()
      .pipe(take(1))
      .subscribe(() => {
        this.next();
      });
  }
  ngOnInit(): void {
    this.title = this.data.title;
    this.model = this.data.model;
    this.mode = this.data.mode;
    this.validateForm = new FormGroup({
      jobTitle: new FormControl('', Validators.required),
      jobDescription: new FormControl('', Validators.required),
    });

    this.patchFormValues();
  }

  patchFormValues() {
    this.validateForm
      .get('jobTitle')
      .setValue(this.model.jobInformationModel.jobTitle);
    this.validateForm
      .get('jobDescription')
      .setValue(this.model.jobInformationModel.jobDescription);
    this.validateForm.updateValueAndValidity();
  }
  submitForm(): void {
    console.log('submit', this.validateForm.value);
    this.onAddClientReferral();
  }

  resetForm(e: MouseEvent): void {
    e.preventDefault();
    this.validateForm.reset();
    for (const key in this.validateForm.controls) {
      if (this.validateForm.controls.hasOwnProperty(key)) {
        this.validateForm.controls[key].markAsPristine();
        this.validateForm.controls[key].updateValueAndValidity();
      }
    }
  }

  validateConfirmPassword(): void {
    setTimeout(() =>
      this.validateForm.controls.confirm.updateValueAndValidity()
    );
  }

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  userNameAsyncValidator = (control: FormControl) =>
    new Observable((observer: Observer<ValidationErrors | null>) => {
      setTimeout(() => {
        if (control.value === 'JasonWood') {
          // you have to return `{error: true}` to mark it as an error event
          observer.next({ error: true, duplicated: true });
        } else {
          observer.next(null);
        }
        observer.complete();
      }, 1000);
    });

  confirmValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { error: true, required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  };

  onAddClientReferral() {
    this.model = this.validateForm.value;
    this.assessmentOutput.emit({ form: this.model });
  }
  next() {
    this.model.jobInformationModel = this.validateForm.value;
  }
}
