import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { ColDef, GridApi, GridReadyEvent, ICellRendererParams } from 'ag-grid-community';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first, Observable, pipe, tap } from 'rxjs';
import { AlertService } from '../alerts/alert.service';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { MatDialog } from '@angular/material/dialog';
import { BtnCellRenderer } from '../common/buttoncell.renderer';
import { DialogComponent } from '../common/dialog.component';
import { ComponentPortal } from '@angular/cdk/portal';
import { AddCustomerComponent } from '../apptemp/apptemp.component';
import {
    ApplicationStatus,
    ApplicationStatusLabel,
    VendorModel,
    CompanyInformationModel,
    JobInformationModel,
    LocationInformationModel,
} from './models/assessment.model';
import { AssessmentService } from './services/assessment.service';
import { AssessmentModalComponent } from './modal/assessmentmodal.component';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-assessment',
    templateUrl: './assessment.component.html',
    styleUrls: ['./assessment.component.css'],
})
export class AssessmentComponent implements OnInit {
    private gridApi!: GridApi<VendorModel>;
    public frmClientreferral: FormGroup;
    formData = new FormData();
    loading = false;
    submitted = false;
    startDate = new Date(1990, 0, 1);
    public files: NgxFileDropEntry[] = [];
    public assessmentData: VendorModel[];
    public gridColumnApi;
    public columnDefs: ColDef[];
    public defaultColDef: ColDef;
    public autoGroupColumnDef: ColDef;
    public groupDefaultExpanded;
    public rowSelection;
    public assessment: any;
    constructor(
        public assessmentService: AssessmentService,
        private fb: FormBuilder,
        private alertService: AlertService,
        public dialog: MatDialog
    ) {
        this.columnDefs = [
            {
                headerName: 'Action',
                field: 'action',
                width: 150,
                cellRenderer: BtnCellRenderer,
                cellRendererParams: {
                    clicked: (data: VendorModel) => {
                        let model = this.assessmentData.find(
                            (x) => x.applicationNumber == data.applicationNumber
                        );
                        this.openDialog(model, 'Update Assessment', 'Update');
                    },
                },
            },
            { field: 'applicationNumber', sortable: true },
            { field: 'applicationStatus', sortable: true },
            { field: 'companyName', sortable: true },
            { field: 'contactName', sortable: true },
            { field: 'jobTitle', sortable: true },
            {
                field: 'dateCreated',
                cellRenderer: (data) => {
                    return data ? new Date(data.value).toLocaleDateString() : '';
                },
                sortable: true,
            },
        ];
        this.defaultColDef = {
            flex: 1,
            minWidth: 100,
            sortable: true,
            filter: true,
        };
        this.autoGroupColumnDef = {
            minWidth: 200,
        };
        this.groupDefaultExpanded = 1;
        this.rowSelection = 'single';
    }

    ngOnInit(): void {
        this.loading = true;
    }

    onSelectionChanged() {
        var selectedRows = this.gridApi.getSelectedRows();
        document.querySelector('#selectedRows').innerHTML =
            selectedRows.length === 1 ? selectedRows[0].companyInformationModel?.companyName : '';
    }

    addAssessmentModal() {
        let model = new VendorModel();
        model.companyInformationModel = new CompanyInformationModel();
        model.jobInformationModel = new JobInformationModel();
        model.locationInformationModels = [];
        model.locationInformationModels[0] = new LocationInformationModel();
        this.openDialog(model, 'New Situational Site Application', 'Add');
    }

    openDialog(model: VendorModel, title: string, mode: string): void {
        this.files = [];
        let dialogRef = this.dialog.open(AssessmentModalComponent, {
            width: '1140px',
            height: '700px',
            maxHeight: '80vh',
            hasBackdrop: true,
            backdropClass: 'bdrop',
            disableClose: true,
            panelClass: 'overflow-dialog',
            data: { title: title, model: model, files: this.files, mode: mode },
        });
        dialogRef?.componentInstance?.onModalClose().subscribe((result) => {
            if (result.mode == 'Add') {
                //this.onAddAssessment(result);
            } else if (result.mode == 'Update') {
                //  this.onUpdateAssessment(result);
            }
        });
    }

    createReferralForm(): FormGroup<any> {
        return this.fb.group({
            clientName: ['', Validators.required],
            clientNumber: ['', Validators.required],
            clientDOB: ['', Validators.required],
            caseType: ['', Validators.required],
            dateSubmitted: [''],
            reportStatus: [''],
            referralNotes: [''],
        });
    }

    onUpdateAssessment(result) {
        /* form code */
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        this.loading = true;
        this.assessmentService
            .updateAssessment(result.form)
            .pipe()
            .subscribe((data) => {
                this.assessmentData = data['VendorModels'];
                this.gridApi.setRowData(this.assessmentData);
                let clientId = data['assessmentId'];
                this.loading = false;
            }),
            (error) => {
                this.alertService.error(error);
                this.loading = false;
            };
    }

    onAddAssessment(result) {
        /* form code */
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();

        this.loading = true;
        this.assessmentService
            .addNewAssessment(result.form)
            .pipe()
            .subscribe((data) => {
                this.assessmentData = data['VendorModels'];
                this.gridApi.setRowData(this.assessmentData);
                let clientId = data['assessmentId'];
                this.loading = false;
            }),
            (error) => {
                this.alertService.error(error);
                this.loading = false;
            };
    }

    onFilterTextBoxChanged() {
        this.gridApi.setQuickFilter((document.getElementById('filter-text-box') as HTMLInputElement).value);
    }

    onPrintQuickFilterTexts() {
        this.gridApi.forEachNode(function (rowNode, index) {
            console.log('Row ' + index + ' quick filter text is ' + rowNode.quickFilterAggregateText);
        });
    }

    onGridReady(params: GridReadyEvent) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.assessmentService.getAssessment().subscribe((data) => {
            this.assessment = data['body'].map((item) => ({
                applicationNumber: item.applicationNumber,
                applicationStatus: this.getApplicationStatus(item.applicationStatus),
                companyName: item.companyInformationModel?.companyName,
                contactName: item.companyInformationModel?.contactName,
                Address: item.locationInformationModels?.[0]?.address1,
                City: item.locationInformationModels?.[0]?.city,
                jobTitle: item.jobInformationModel?.jobTitle,
                dateCreated: item.dateCreated,
            }));
            this.assessmentData = data['body'];
            const sortModel = [{ colId: 'dateCreated', sort: 'desc' }];
            this.sortGrid(params, 'dateCreated', 'desc');
            this.gridApi.setRowData(this.assessment.slice(0, 10));
            params.api!.setRowData(this.assessment.slice(0, 10));
            this.loading = false;
        });
    }
    getApplicationStatus(applicationStatus: ApplicationStatus): any {
        let retval = '';
        ApplicationStatusLabel.forEach((label, value) => {
            if (value == applicationStatus) {
                retval = label;
            }
        });
        return retval;
    }

    sortGrid(event, field, sortDir) {
        const columnState = {
            // https://www.ag-grid.com/javascript-grid-column-state/#column-state-interface
            state: [
                {
                    colId: field,
                    sort: sortDir,
                },
            ],
        };
        event.columnApi.applyColumnState(columnState);
    }
}
