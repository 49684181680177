import { ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxFileDropEntry } from 'ngx-file-drop';
import { Observable, Subject, Subscribable, Subscription, take } from 'rxjs';
import { DialogComponent } from 'src/app/common/dialog.component';
import { CaseTypes, ReportsModel } from '../models/reports.model';
import { saveAs } from 'file-saver';
import { DataUrl, NgxImageCompressService } from 'ngx-image-compress';
import { FileAttachmentService } from 'src/app/common/fileattachment.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { ClientsModel } from 'src/app/client/models/client.model';
import { Guid } from 'guid-typescript';
import { ReportsTemplateModel } from 'src/app/reports/model/report.template.model';

@Component({
    selector: 'app-assessmentreportmodal',
    templateUrl: `./assessmentreport.modal.component.html`,
    styleUrls: ['./assessmentreport.modal.component.css'],
})
export class AssessmentReportModalComponent implements OnInit {
    validateForm: FormGroup;
    @Input() title: string = '';
    @Input() model: ReportsModel;
    @Input() files: any;
    @Input() caseTypes: CaseTypes[];
    @Output() assessmentOutput = new EventEmitter<any>();
    public reportsModels: ReportsTemplateModel[];
    reportTemplates: any;
    dateFormat = 'MM/dd/yyyy';
    mode: string = '';
    formData = new FormData();
    fileDescription = 'Choose File';
    fileData: any = null;
    fileHasChanged: any;
    private modalClose: Subject<any> = new Subject();
    modeLanguage: string = '';
    private _subscription: Subscription;
    subscription: Subscription;
    showTemplateDropDown = false;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private imageCompress: NgxImageCompressService,
        private dialogRef: MatDialogRef<DialogComponent>,
        public dialog: MatDialog,
        private service: ReportService,
        private cdRef: ChangeDetectorRef,
        private attachmentService: FileAttachmentService
    ) {
        this.subscription = this.service
            .getNextEvent()
            .pipe(take(1))
            .subscribe(() => {
                this.next();
            });
    }
    ngOnInit(): void {
        this.title = this.data.title;
        this.model = this.data.model;
        this.files = this.data?.files;
        this.reportsModels = this.data?.reportsModels;

        this.mode = this.data.mode;

        this.service.getCaseTypes().subscribe((res) => {
            console.log('reporttypes', res['body']);
            this.reportTemplates = res['body'];
            if (this.reportTemplates?.length > 0) {
                this.showTemplateDropDown = true;
            }
        });
        this.modeLanguage = this.mode == 'Add' ? 'creating' : 'editing';
        this.validateForm = new FormGroup({
            reportId: new FormControl(Guid.create().toString()),
            reportTemplate: new FormControl(''),
            templateTitle: new FormControl('', Validators.required),
        });
        // if (this.files[0]?.fileName || this.model.clientInfoModel.profileImage) {
        //     this.setFileData();
        //     this.fileDescription = 'Selected files listed below';
        // }

        this._subscription = this.validateForm.statusChanges?.subscribe((result: any) => {
            let val = result === 'VALID' ? true : false;
            this.onFormValidation(val);
        });
    }

    ngOnDestroy() {
        this._subscription.unsubscribe();
        this.subscription.unsubscribe();
    }
    public disabledDate = (current: Date): boolean => current > new Date();
    onFormValidation(validity: boolean) {
        switch (validity) {
            case true:
                this.service.setModelComplete(true);
                break;
            case false:
                // do 'form invalid' action
                this.service.setModelComplete(false);
                break;
        }
    }
    checkName() {
        let name = this.validateForm.get('templateTitle').value;
        if (
            this.reportTemplates?.find(
                (x) => x.templateName?.toLowerCase()?.trim() == name?.toLowerCase()?.trim()
            )
        ) {
            this.validateForm.get('templateTitle').setErrors({ duplicate: true });
            this.validateForm.updateValueAndValidity();
            this.service.setModelComplete(false);
        } else {
            this.validateForm.get('templateTitle').setErrors(null);
            this.service.setModelComplete(true);
        }
    }

    submitForm(): void {
        this.onAddNewReport();
    }

    onAddNewReport() {
        this.model = this.validateForm.value;
        this.assessmentOutput.emit({ form: this.model, files: this.formData });
        this.closeModal();
    }

    onModalClose(): Observable<any> {
        return this.modalClose.asObservable();
    }

    closeModal() {
        if (this.fileHasChanged) {
            this.modalClose.next({
                form: this.model,
                files: this.formData,
                mode: this.mode,
            });
        } else {
            this.modalClose.next({ form: this.model, mode: this.mode });
        }
        this.modalClose.complete();
        this.dialogRef.close();
    }
    public fileOver(event) {
        console.log(event);
    }

    public fileLeave(event) {
        console.log(event);
    }

    public isValidFile(file: File) {
        // enforce a "." being contained in the file name
        if (!file?.name.includes('.')) {
            return false;
        }

        // There should be a total of 2 strings, with a period in between in my case
        const names = file?.name.split('.');
        if (names.length !== 2) {
            return false;
        }

        const ext = names[1];
        if (['png', 'jpeg', 'jpg', 'svg', 'tiff'].includes(ext)) {
            return true;
        }

        return false;
    }
    public dropped(files: NgxFileDropEntry[]) {
        this.fileDescription = 'Choose File';
        if (files.length > 1) {
            alert('Only one file can be attached to this referral');
            return;
        }
        // if(CheckMaxFileSize()){
        //   alert('Only one file can be attached to this referral');
        // return;
        // }
        this.validateForm.markAsDirty();
        this.fileHasChanged = true;
        this.files = files;
        this.formData = new FormData();
        for (const droppedFile of files) {
            // Is it a file?
            if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    try {
                        // File Check
                        // Here you can have some custom function to check whatever parameters you want, return true if valid...
                        if (!this.isValidFile(file)) {
                            alert('Invalid file format');
                            throw new Error('Invalid file format');
                        }
                        // Process your file now
                        this.fileData = file;
                        this.formData.append('referrals', file, droppedFile.relativePath);
                        this.fileDescription = 'Selected files listed below';
                    } catch (err) {
                        this.files = null;
                        this.fileData = null;
                    }
                });
            } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                console.log(droppedFile.relativePath, fileEntry);
            }
        }
    }
    next() {
        this.model.reportTemplateName = this.validateForm.controls.reportTemplate.value;
        this.model.reportName = this.validateForm.controls.templateTitle.value;
        if (this.fileData !== null) {
            const reader = new FileReader();
            reader.readAsDataURL(this.fileData);
            reader.onload = () => {
                // this.model.clientInfoModel.profileImageString = reader.result as string;
                // this.model.clientInfoModel.profileImage = this.fileData;
                // this.imageCompress
                // .compressFile(reader.result as string, -2, 100, 100 )
                // .then((result: DataUrl) => {
                //   console.log(
                //     "The compression algorithm size we can do is",
                //     this.imageCompress.byteCount(result),
                //     'bytes'
                // );
                //   this.model.clientInfoModel.profileImageString = result as string;
                // });
            };
        }
    }

    deleteFile() {
        this.files = [];
        this.fileData = null;
        this.formData.delete('referrals');
    }
    handleKeyupEnter(event) {
        console.log(event);
        if (event.code == 'Enter') event.preventDefault();
    }
    downloadFile() {
        if (this.files.length > 0) {
            for (const droppedFile of this.files) {
                if (droppedFile?.fileEntry?.isFile) {
                    const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                    fileEntry.file((file: File) => {
                        var blob = new Blob([file], { type: file.type });
                        saveAs(blob, file.name);
                    });
                } else {
                    //TODO FIX THIS
                    // this.attachmentService.downloadForm(this.model?.profileImage).subscribe((res) => {
                    //     var blob = new Blob([res], { type: droppedFile.contentType });
                    //     saveAs(blob, droppedFile.name);
                    // });
                }
            }
        }
    }

    setFileData() {
        // if (this.files[0]?.length > 0) {
        //     for (const droppedFile of this.files) {
        //         this.fileData = droppedFile;
        //     }
        // } else if (this.model?.profileImage) {
        //     this.files = [];
        //     this.fileData = this.model?.profileImage;
        //     this.files.push(this.fileData);
        // }
    }
}
