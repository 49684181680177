import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, tap } from 'rxjs';
import { MessageService } from 'src/app/common/message.service';
import { environment } from 'src/environments/environment';
import { ClientsModel } from '../models/client.model';

const httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({
    providedIn: 'root',
})
export class ClientService {
    http: HttpClient;
    clientUrl: string = environment.apiUrl + '/Client';

    constructor(httpClient: HttpClient, private messageService: MessageService) {
        this.http = httpClient;
    }

    getAllClients(): Observable<HttpResponse<ClientsModel[]>> {
        return this.http
            .get<ClientsModel[]>(this.clientUrl + '/GetAll', {
                observe: 'response',
            })
            .pipe(
                catchError((err) => {
                    this.handleError<ClientsModel[]>('Updloading Form', err);
                    throw 'Error in getClientReferral. Details: ' + err;
                })
            );
    }

    public uploadAvatarAttachment(formData: any, clientId: any): Observable<any> {
        let options = {
            headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' }),
        };
        return this.http.post<any>(this.clientUrl + `/Upload/?id=${clientId}`, formData).pipe(
            catchError((err) => {
                this.handleError<ClientsModel[]>('Updloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public getAvatar(clientId: any): Observable<any> {
        return this.http.post<FormData>(this.clientUrl + `/GetReferralAttachment?id=${clientId}`, {}).pipe(
            catchError((err) => {
                this.handleError<ClientsModel[]>('Downloading Form', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    public AddClient(ClientsModel: ClientsModel): Observable<ClientsModel[]> {
        return this.http.post<ClientsModel[]>(this.clientUrl + '/AddClient', ClientsModel, httpOptions).pipe(
            catchError((err) => {
                this.handleError<ClientsModel[]>('Add', err);
                throw 'error in source. Details: ' + err;
            })
        );
    }

    assignStaffToClient(clientsModel: ClientsModel): Observable<any> {
        return this.http
            .post<ClientsModel[]>(this.clientUrl + '/AssignStaffToClientsAsync', clientsModel, httpOptions)
            .pipe(
                catchError((err) => {
                    this.handleError<ClientsModel[]>('Update', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    public updateClient(ClientsModel: ClientsModel): Observable<ClientsModel[]> {
        return this.http
            .post<ClientsModel[]>(
                this.clientUrl + '/UpdateClient?id=' + ClientsModel.clientId,
                ClientsModel,
                httpOptions
            )
            .pipe(
                catchError((err) => {
                    this.handleError<ClientsModel[]>('Update', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    public deactivateClient(ClientsModel: ClientsModel): Observable<ClientsModel[]> {
        return this.http
            .delete<ClientsModel[]>(
                `${this.clientUrl} /DeactivateClient?id=${ClientsModel.clientId}`,
                httpOptions
            )
            .pipe(
                catchError((err) => {
                    this.handleError<ClientsModel[]>('Delete', err);
                    throw 'error in source. Details: ' + err;
                })
            );
    }

    getClientById(id: number): Observable<ClientsModel> {
        const url = `${this.clientUrl}/GetClient?id=${id}`;
        return this.http.get<ClientsModel>(url).pipe(
            tap((_) => this.log(`fetched hero id=${id}`)),
            catchError(this.handleError<ClientsModel>(`getClient id=${id}`))
        );
    }

    resetStaffClients(id: string): Observable<ClientsModel> {
        const url = `${this.clientUrl}/ResetStaffClients?id=${id}`;
        return this.http.post<ClientsModel>(url, httpOptions).pipe(
            tap((_) => this.log(`fetched hero id=${id}`)),
            catchError(this.handleError<ClientsModel>(`getClient id=${id}`))
        );
    }

    private log(message: string) {
        this.messageService.add(`ClientReferralService: ${message}`);
    }

    private handleError<T>(operation = 'operation', result?: T) {
        return (error: any): Observable<T> => {
            // TODO: send the error to remote logging infrastructure
            console.error(error); // log to console instead

            // TODO: better job of transforming error for user consumption
            this.log(`${operation} failed: ${error.message}`);

            // Let the app keep running by returning an empty result.
            return of(result as T);
        };
    }
}
