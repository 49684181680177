import { CdkPortalOutletAttachedRef, ComponentPortal } from '@angular/cdk/portal';
import {
    ChangeDetectorRef,
    Component,
    ComponentRef,
    ContentChild,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormGroup, FormGroupDirective, NgForm } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
    debounceTime,
    delay,
    finalize,
    map,
    merge,
    Observable,
    scan,
    startWith,
    Subject,
    Subscription,
    take,
    timer,
} from 'rxjs';
import { DialogComponent } from 'src/app/common/dialog.component';
import { AlertService } from 'src/app/alerts/alert.service';
import { AssessmentReportModalComponent } from '../modal.forms/assessmentreport.modal.component';
import { ReportsModel } from '../models/reports.model';
import { ReportsComponent } from '../reports.component';
import { AssessmentJobsFormComponent } from 'src/app/assessment/modal/modalforms/assessment.jobsform.component';
import { AssessmentReviewSubmitComponent } from 'src/app/assessment/modal/modalforms/assessment.reviewsubmitform.component';
import { AssessmentSubmitComponent } from 'src/app/assessment/modal/modalforms/assessment.submit.component';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { FileAttachmentService } from 'src/app/common/fileattachment.service';
import { ReportService } from 'src/app/reports/services/report.service';
import { AddReportComponent } from 'src/app/reports/components/add-report/add-report.component';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

interface SyncStep {
    id: number;
    title: string;
    description: string;
    icon: string;
    async: false;
    percentage: null;
    disabled: boolean;
}

interface AsyncStep {
    id: number;
    title: string;
    description: string;
    icon: string;
    async: true;
    percentage: number;
    disabled: boolean;
}

type Step = SyncStep | AsyncStep;

function mockAsyncStep(): Observable<number> {
    const subStep1 = timer(600).pipe(map(() => 25));
    const subStep2 = subStep1.pipe(delay(600));
    const subStep3 = subStep2.pipe(delay(600));
    const subStep4 = subStep3.pipe(delay(600));
    return merge(subStep1, subStep2, subStep3, subStep4).pipe(scan((a, b) => a + b));
}

@Component({
    selector: 'app-report-base-modal',
    templateUrl: './reportmodal.base.component.html',
    styleUrls: ['./reportmodal.base.component.css'],
})
export class ReportModalComponent implements OnInit, OnDestroy {
    validateForm: FormGroup;
    @Input() title: string = '';
    @Input() model: ReportsModel;
    @Input() files: any;
    @Input() mode: string = '';
    @Output() output = new EventEmitter<any>();
    form: FormGroup = new FormGroup({});
    @ViewChild(FormGroupDirective, { static: true }) _form: FormGroup;
    ref: ComponentRef<any>;
    isModelComplete: boolean = false;
    componentPortal: ComponentPortal<any>;
    formData = new FormData();
    showMessage = false;
    current = 0;
    public index = 'First-content';
    processing = false;
    private modalClose: Subject<any> = new Subject();
    indexChangeSubscription: Subscription;
    modeLanguage = '';
    private _modelCompleteSubscription: any;
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<DialogComponent>,
        public dialog: MatDialog,
        private cdr: ChangeDetectorRef,
        private service: ReportService,
        private alertService: AlertService,
        private fileAttachmentService: FileAttachmentService
    ) {
        this._modelCompleteSubscription = this.service.IsModelComplete.pipe().subscribe({
            next: (event: any) => {
                this.isModelComplete = event;
                this.cdr.detectChanges();
            },
        });
        this.cdr?.markForCheck();
    }

    ngOnInit(): void {
        this.title = this.data?.title;
        this.model = this.data?.model;
        this.files = this.data?.files;
        this.mode = this.data?.mode;
        this.model.mode = this.mode;
        this.modeLanguage = this.mode == 'Update' ? 'updating' : 'creating';

        if (this.data?.reportsModels?.length > 0) {
            this.showMessage = true;
        }
        this.service.getAllTemplates().subscribe((res) => {
            if (res['body']?.length > 0) {
                this.showMessage = true;
            }
        });
        this.componentPortal = new ComponentPortal(AssessmentReportModalComponent);
        this.cdr.detectChanges();
    }

    ngOnDestroy(): void {
        this._modelCompleteSubscription.unsubscribe();
    }
    steps: Step[] = [
        {
            id: 1,
            title: `Client Information`,
            description: `This step is synchronous.`,
            icon: 'user',
            async: false,
            percentage: null,
            disabled: false,
        },
        {
            id: 2,
            title: `Evaluation`,
            description: `This step is asynchronous.`,
            icon: 'team',
            async: false,
            percentage: null,
            disabled: false,
        },
        {
            id: 3,
            title: `Review`,
            description: `This step is asynchronous.`,
            icon: 'file-search',
            async: true,
            percentage: 0,
            disabled: false,
        },
        {
            id: 4,
            title: `Submit`,
            description: `This step is asynchronous.`,
            icon: 'safety-certificate',
            async: false,
            percentage: null,
            disabled: true,
        },
    ];

    setModelComplete(event: any) {
        this.isModelComplete = event;
    }

    pre(): void {
        this.current -= 1;
        this.changeContent();
    }

    next(): void {
        if (this.current == 0 && this.ref?.instance?.files?.length > 0) {
            this.files = this.ref?.instance?.formData || this.ref?.instance?.files;
        }
        this.current += 1;
        this.service.sendNextEvent(this.ref.instance.model);
        this.model = this.ref.instance.model;
        this.changeContent();
    }

    goToPage(index): void {
        this.current = index;
        this.changeContent();
    }
    submit(): void {
        this.model = this.ref.instance?.model;
        this.submitForm();
    }

    close() {
        this.closeModal();
    }

    onIndexChange(event: number): void {
        this.current = event;
        this.changeContent();
    }

    trackById(_: number, item: Step): number {
        return item.id;
    }

    changeContent(): void {
        switch (this.current) {
            case 0: {
                this.index = 'First-content';
                this.componentPortal = new ComponentPortal(AssessmentReportModalComponent);
                this.cdr.detectChanges();
                break;
            }
            case 1: {
                this.index = 'Second-content';
                this.componentPortal = new ComponentPortal(AddReportComponent);
                this.cdr.detectChanges();
                break;
            }
            case 2: {
                this.index = 'third-content';
                this.componentPortal = new ComponentPortal(AssessmentReviewSubmitComponent);
                this.cdr.detectChanges();
                break;
            }
            case 3: {
                this.index = 'fourth-content';
                this.componentPortal = new ComponentPortal(AssessmentSubmitComponent);
                this.cdr.detectChanges();
                break;
            }
            // case 4:{
            //     this.index = 'fifth-content';
            //     this.componentPortal = new ComponentPortal(AssessmentSubmitComponent);
            //     this.cdr.detectChanges();
            //     break;
            // }
            default: {
                this.index = 'error';
            }
        }
    }
    loadingAndStep(): void {
        if (this.current < this.steps.length) {
            const step = this.steps[this.current];
            if (step.async) {
                this.processing = true;
                mockAsyncStep()
                    .pipe(
                        finalize(() => {
                            step.percentage = 0;
                            this.processing = false;
                            this.current += 1;
                            this.changeContent();
                        })
                    )
                    .subscribe((p) => {
                        step.percentage = p;
                    });
            } else {
                this.current += 1;
                this.changeContent();
            }
        }
    }
    portalAttached(_ref: CdkPortalOutletAttachedRef) {
        this.ref = _ref as ComponentRef<any>;
        this.ref.instance.model = this.model;
        if (this.current == 3) {
            this.service.sendNextEvent(this.ref.instance.model);
        }
        this.ref.instance.assessmentOutput.subscribe((d) => {
            this.goToPage(d);
        });
        setTimeout(() => {});
    }

    save() {
        this.alertService.success('Report has been saved');
    }

    submitForm(): void {
        this.onAddClientReferral();
        // if (this.model.mode == 'Add') {
        //     const pdfDefinition = JSON.parse(sessionStorage.getItem('pdfDefinition'));
        //     pdfDefinition &&
        //         pdfDefinition?.contentLength > 0 &&
        //         pdfMake.createPdf(pdfDefinition.pdf).download('pdfreport.pdf');
        //     this.service
        //         .addReport(this.model)
        //         .pipe(take(1))
        //         .subscribe((data) => {
        //             this.model.reportNumber = data.reportNumber;
        //             sessionStorage.removeItem('editorContent');
        //             sessionStorage.removeItem('pdfDefinition');
        //             if (this.files.length > 0) {
        //                 if (this.files?.get('referrals')) {
        //                     let reportId = data['reportId'];
        //                     this.fileAttachmentService
        //                         .uploadAttachment(this.files, reportId)
        //                         .pipe(take(1))
        //                         .subscribe((data) => {
        //                             console.log(`Has Image Upload Completed: ${data}`);
        //                         }),
        //                         (error) => {
        //                             this.alertService.error(error);
        //                         };
        //                 }
        //             }
        //             this.service.sendNextEvent(data);
        //             this.loadingAndStep();
        //         }),
        //         (error) => {
        //             this.service.sendNextEvent(error);
        //         };
        // } else {
        //     this.service
        //         .updateReport(this.model)
        //         .pipe(take(1))
        //         .subscribe((data) => {
        //             let reportId = data['reportId'];
        //             if (this.files.get('referrals')) {
        //                 this.fileAttachmentService
        //                     .uploadAttachment(this.files, reportId)
        //                     .pipe(take(1))
        //                     .subscribe((data) => {
        //                         console.log(`Has Image Upload Completed: ${data}`);
        //                     }),
        //                     (error) => {
        //                         this.alertService.error(error);
        //                     };
        //             }
        //             this.service.sendNextEvent(data);
        //             this.loadingAndStep();
        //         }),
        //         (error) => {
        //             this.service.sendNextEvent(error);
        //         };
        // }
    }

    onAddClientReferral() {
        this.model = this.ref.instance?.validateForm?.value;
        this.output.emit({ form: this.model, files: this.formData });
        this.closeModal();
    }

    onModalClose(): Observable<any> {
        return this.modalClose.asObservable();
    }
    cancelModal() {
        sessionStorage.removeItem('editorContent');
        sessionStorage.removeItem('pdfDefinition');
        this.modalClose.complete();
        this.dialogRef.close();
    }
    closeModal() {
        //if(this.tempMode == 'Cancel'){return;}
        this.modalClose.next({
            form: this.model,
            files: this.formData,
            mode: this.mode,
        });
        this.modalClose.complete();
        this.dialogRef.close();
    }
}
