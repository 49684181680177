import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { extend, addClass } from '@syncfusion/ej2-base';

import {
    KanbanComponent,
    ColumnsModel,
    CardSettingsModel,
    SwimlaneSettingsModel,
    DialogSettingsModel,
    CardRenderedEventArgs,
} from '@syncfusion/ej2-angular-kanban';
import { cardData, kanbanData } from '../../model/data';
import { MaskitoOptions, MaskitoElementPredicateAsync } from '@maskito/core';
import { maskitoDateOptionsGenerator } from '@maskito/kit';
import { differenceInCalendarDays } from 'date-fns';
import * as moment from 'moment';
import { rippleMouseHandler } from '@syncfusion/ej2-buttons';
import { SwitchComponent } from '@syncfusion/ej2-angular-buttons';
import { AuthService } from '../../../login/service/auth.service';
import { User } from '../../../login/models/user';

@Component({
    selector: 'app-tasks',
    templateUrl: './tasks.component.html',
    styleUrls: ['./tasks.component.css'],
})
export class TasksComponent implements OnInit {
    @ViewChild('kanbanObj') kanbanObj: KanbanComponent;
    @ViewChild('switch')
    public switch: SwitchComponent;
    dateFormat = 'MM/dd/yyyy';
    today = new Date();
    minDate = new Date();
    staffName: string;
    public kanbanData: Object[] = extend([], kanbanData, true) as Object[];
    public cardSettings: CardSettingsModel = {
        contentField: 'Summary',
        headerField: 'Id',
    };
    public statusData: string[] = ['Open', 'InProgress', 'Testing', 'Close'];
    public priorityData: string[] = ['Low', 'Normal', 'Critical', 'Release Breaker', 'High'];
    public swimlaneSettings: SwimlaneSettingsModel = { keyField: 'Assignee' };
    public assigneeData: string[] = [
        'Nancy Davloio',
        'Andrew Fuller',
        'Janet Leverling',
        'Steven walker',
        'Robert King',
        'Margaret hamilt',
        'Michael Suyama',
    ];
    maskOptions: MaskitoOptions = maskitoDateOptionsGenerator({
        mode: 'mm/dd/yyyy',
        separator: '/',
        max: new Date(),
    });
    readonly elementPredicate: MaskitoElementPredicateAsync = async (host) =>
        new Promise((resolve) =>
            // next macro task
            setTimeout(() => resolve(host.querySelector<HTMLInputElement>('input')!))
        );

    disabledDate = (current: Date): boolean =>
        // Can not select days before today
        differenceInCalendarDays(current, this.today) < 0;

    constructor(private authservice: AuthService) {
        var user = <User>JSON.parse(authservice.getUser());
        this.staffName = user?.vendorName || `${user?.firstName} ${user?.lastName}`;
    }

    ngOnInit(): void {
        let elemArray: NodeListOf<Element> = document.querySelectorAll('.switch-control label');
        for (let i: number = 0, len: number = elemArray.length; i < len; i++) {
            elemArray[i].addEventListener('mouseup', rippleHandler);
            elemArray[i].addEventListener('mousedown', rippleHandler);
        }
    }
    addNewTask(): void {
        const cardIds = this.kanbanObj.kanbanData.map((obj: { [key: string]: string }) =>
            parseInt(obj.Id.replace('Task ', ''), 10)
        );
        const cardCount: number = Math.max.apply(Math, cardIds) + 1;
        const cardDetails = {
            Id: 'Task ' + cardCount,
            Status: 'Open',
            Priority: 'Normal',
            Assignee: '',
            Tags: '',
            DueDate: '',
            Summary: '',
        };
        this.kanbanObj.openDialog('Add', cardDetails);
    }
}

function rippleHandler(e: MouseEvent): void {
    let rippleSpan: Element = this.nextElementSibling.querySelector('.e-ripple-container');
    if (rippleSpan) {
        rippleMouseHandler(e, rippleSpan);
    }
}
